import React from 'react';
import PropTypes from 'prop-types';
import { addDays, formatDistanceStrict } from 'date-fns';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import range from 'lodash/range';
import { IconArrowHead, NamedLink } from '../../components';
import { stringify } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { isStripeCreatePaymentIntentTransition, isPaymentExpired } from '../../util/transaction';

import css from './PaymentExpiryDate.module.css';

const PAYMENT_EXPIRY_DAYS = 1;

const getLatestStripeCreatePITransition = transitions => {
  return transitions
    ? transitions.findLast(({ transition }) => isStripeCreatePaymentIntentTransition(transition))
    : null;
};

const PaymentExpiryDateComponent = ({ transaction }) => {
  const lastPITransition = getLatestStripeCreatePITransition(transaction.attributes.transitions);
  const isTransactionExpired = isPaymentExpired(transaction.attributes.lastTransition);
  if (!lastPITransition) {
    return null;
  }

  const now = new Date();
  const expiryDate = addDays(new Date(lastPITransition.createdAt), PAYMENT_EXPIRY_DAYS);
  const result = formatDistanceStrict(expiryDate, now);

  if (isTransactionExpired) {
    return (
      <div className={css.txDueDate}>Due date has passed, you can no longer make this payment.</div>
    );
  }

  return (
    <div className={css.txDueDate}>
      Time remaining: <span>{result}</span>
    </div>
  );
};

export default injectIntl(PaymentExpiryDateComponent);
