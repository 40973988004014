import React from 'react';
import { string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import arrayMutators from 'final-form-arrays';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  IconEnquiry,
  FieldDateRangeInput,
  FieldCheckboxGroup,
  CustomSpinner,
} from '../../components';
import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';
import { MIN_DAYS_FOR_DEPOSIT } from '../../util/misc';
import css from './EnquiryForm.module.css';

const EnquiryFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        submitButtonWrapperClassName,
        formId,
        handleSubmit,
        intl,
        listingTitle,
        authorDisplayName,
        sendEnquiryError,
        values,
        listings,
        sendEnquiryInProgress,
      } = fieldRenderProps;

      const messageLabel = intl.formatMessage(
        {
          id: 'EnquiryForm.messageLabel',
        },
        { authorDisplayName }
      );
      const messagePlaceholder = intl.formatMessage(
        {
          id: 'EnquiryForm.messagePlaceholder',
        },
        { authorDisplayName }
      );
      const messageRequiredMessage = intl.formatMessage({
        id: 'EnquiryForm.messageRequired',
      });
      const messageRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);

      const listingsToEnquireMessage = intl.formatMessage({
        id: 'EnquiryForm.listingsToEnquireMessage',
      });

      const classes = classNames(rootClassName || css.root, className);
      const submitDisabled = !values.message || !values.wtRange;
      const submitDisabledAll =
        !values.listingsToEnquire ||
        values.listingsToEnquire.length < 1 ||
        !values.message ||
        !values.wtRange;

      const bookingStartDate =
        values.wtRange && values.wtRange.startDate ? values.wtRange.startDate : new Date();
      const today = new Date();

      const DateNote = () => {
        if (today > bookingStartDate) {
          return <div className={css.datesInThePast}>The booking start date is in the past!</div>;
          // } else if (bookingStartDate - today > (MIN_DAYS_FOR_DEPOSIT * 24 * 60 * 60 * 1000)) {
          //   return <div className={css.twoPaysWarn}>
          //     You might require to pay a <strong>
          //       deposit
          //     </strong> first because the dates selected are <strong>
          //       {MIN_DAYS_FOR_DEPOSIT} days prior
          //     </strong> to booking start date.
          //   </div>
        } else {
          return <div></div>;
        }
      };

      return (
        <Form className={classes} onSubmit={handleSubmit} enforcePagePreloadFor="OrderDetailsPage">
          {sendEnquiryInProgress && <CustomSpinner />}
          <IconEnquiry className={css.icon} />
          <h2 className={css.heading}>
            {listingTitle ? (
              <FormattedMessage
                id="EnquiryForm.heading"
                values={{ listingTitle: listings?.length > 0 ? '' : listingTitle }}
              />
            ) : (
              'Enquire'
            )}
          </h2>

          {listings && (
            <FieldCheckboxGroup
              className={css.field}
              id="listingsToEnquire"
              name="listingsToEnquire"
              label={listings?.length > 0 ? listingsToEnquireMessage : null}
              options={listings.map(l => {
                return { key: l.id.uuid, label: l.attributes.title };
              })}
            />
          )}

          <FieldTextInput
            className={css.field}
            type="textarea"
            name="message"
            id={formId ? `${formId}.message` : 'message'}
            label={authorDisplayName ? messageLabel : 'Message to providers'}
            placeholder={messagePlaceholder}
            validate={messageRequired}
          />

          <FieldDateRangeInput
            customLabel={'Booking Dates'}
            className={classNames(css.field, css.zIndex)}
            name="wtRange"
            id="wtRange"
            minDays={0}
          />

          <DateNote />

          <FieldCheckboxGroup
            className={classNames(css.field)}
            id="infoToShare"
            name="infoToShare"
            label="Select information to share"
            options={[
              { key: 'companyName', label: 'Company name' },
              { key: 'workTrippDescription', label: 'WorkTripp description' },
              { key: 'noOfParticipants', label: 'No. of participants' },
              { key: 'totalBudget', label: 'Budget per person' },
            ]}
          />

          <div className={submitButtonWrapperClassName}>
            {sendEnquiryError ? (
              <p className={css.error}>
                <FormattedMessage id="EnquiryForm.sendEnquiryError" />
              </p>
            ) : null}
            <PrimaryButton
              type="submit"
              disabled={listings && listings.length ? submitDisabledAll : submitDisabled}
            >
              <FormattedMessage id="EnquiryForm.submitButtonText" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

EnquiryFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  sendEnquiryError: null,
};

EnquiryFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  listingTitle: string,
  authorDisplayName: string,
  sendEnquiryError: propTypes.error,
  intl: intlShape.isRequired,
};

const EnquiryForm = compose(injectIntl)(EnquiryFormComponent);

EnquiryForm.displayName = 'EnquiryForm';

export default EnquiryForm;
