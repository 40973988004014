import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput } from '../../components';
import Down from '../../assets/icons/down.png';
import Up from '../../assets/icons/up.png';

import css from './SignupForm.module.css';
import FieldPasswordInput from '../../components/FieldPasswordInput/FieldPasswordInput';

const KEY_CODE_ENTER = 13;

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        selected,
        setSelected,
      } = fieldRenderProps;

      // email
      const emailLabel = (
        <>
          <FormattedMessage id="SignupForm.emailLabel" />
          <span className={css.redStar}>*</span>
        </>
      );

      const emailPlaceholder = intl.formatMessage({
        id: 'SignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'SignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'SignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      //organisation name

      const organisationLabel = (
        <>
          <FormattedMessage id="SignupForm.organisationNameLabel" />
          <span className={css.redStar}>*</span>
        </>
      );

      const organisationPlaceholder = intl.formatMessage({
        id: 'SignupForm.organisationNamePlaceHolder',
      });

      const organisationNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.organisationNameRequired',
      });

      //job title

      const jobLabel = (
        <>
          <FormattedMessage id="SignupForm.jobTitleLabel" />
          <span className={css.redStar}>*</span>
        </>
      );
      const jobPlaceholder = intl.formatMessage({
        id: 'SignupForm.jobTitlePlaceHolder',
      });
      const jobTitleRequiredMessage = intl.formatMessage({
        id: 'SignupForm.jobTitleRequired',
      });

      // password
      const passwordLabel = (
        <>
          <FormattedMessage id="SignupForm.passwordLabel" />
          <span className={css.redStar}>*</span>
        </>
      );

      const passwordPlaceholder = intl.formatMessage({
        id: 'SignupForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      const options = ['Booking a WorkTripp', 'Listing my services', 'Both'];
      const [isActive, setIsActive] = useState(false);

      // firstName
      const firstNameLabel = (
        <>
          <FormattedMessage id="SignupForm.firstNameLabel" />
          <span className={css.redStar}>*</span>
        </>
      );
      const firstNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.firstNameRequired',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);

      // lastName
      const lastNameLabel = (
        <>
          <FormattedMessage id="SignupForm.lastNameLabel" />
          <span className={css.redStar}>*</span>
        </>
      );
      const lastNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.lastNamePlaceholder',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.lastNameRequired',
      });
      const lastNameRequired = validators.required(lastNameRequiredMessage);
      const organisationNameRequired = validators.required(organisationNameRequiredMessage);
      const jobTitleRequired = validators.required(jobTitleRequiredMessage);
      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress || !selected;

      //I'm interested in
      const interestedIn = (
        <>
          <FormattedMessage id="ConfirmSignupForm.interestedInLabel" />
          <span className={css.redStar}>*</span>
        </>
      );

      const onOpenTermsOfService = () => {
        window.open(
          'https://drive.google.com/file/d/11_CAeMmdftAlrIPmtZomcH-cAs3ZwX9N/view',
          '_blank'
        );
      };

      const handleTermsKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          onOpenTermsOfService();
        }
      };
      const termsLink = (
        <span
          className={css.termsLink}
          onClick={onOpenTermsOfService}
          role="button"
          tabIndex="0"
          onKeyUp={handleTermsKeyUp}
        >
          <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
        </span>
      );

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <FieldTextInput
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={emailLabel}
              placeholder={emailPlaceholder}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            <div className={css.name}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.fname` : 'fname'}
                name="fname"
                autoComplete="given-name"
                label={firstNameLabel}
                placeholder={firstNamePlaceholder}
                validate={firstNameRequired}
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lname` : 'lname'}
                name="lname"
                autoComplete="family-name"
                label={lastNameLabel}
                placeholder={lastNamePlaceholder}
                validate={lastNameRequired}
              />
            </div>

            <FieldTextInput
              className={css.password}
              type="text"
              id={formId ? `${formId}.organisationName` : 'organisationName'}
              name="organisationName"
              autoComplete="organisation-name"
              label={organisationLabel}
              placeholder={organisationPlaceholder}
              validate={organisationNameRequired}
            />
            <FieldTextInput
              className={css.password}
              type="text"
              id={formId ? `${formId}.jobtitle` : 'jobtitle'}
              name="jobtitle"
              autoComplete="job-title"
              label={jobLabel}
              placeholder={jobPlaceholder}
              validate={jobTitleRequired}
            />
            <div className={css.dropDownHeader}>{interestedIn}</div>
            <div className={css.dropdown}>
              <div
                className={selected ? css.dropdownBtnSelected : css.dropdownBtn}
                onClick={e => setIsActive(!isActive)}
              >
                {selected ? selected : 'Please select...'}
                <img src={Down} />
              </div>
              {isActive && (
                <div className={css.dropdownContent}>
                  {options.map(option => (
                    <div
                      onClick={e => {
                        setSelected(option);
                        setIsActive(false);
                      }}
                      className={css.dropdownItem}
                    >
                      {option}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <FieldPasswordInput
              className={css.password}
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="new-password"
              label={passwordLabel}
              placeholder={passwordPlaceholder}
              validate={passwordValidators}
            />
          </div>

          <div className={css.bottomWrapper}>
            <p className={css.bottomWrapperText}>
              <span className={css.termsText}>
                <FormattedMessage
                  id="SignupForm.termsAndConditionsAcceptText"
                  values={{ termsLink }}
                />
              </span>
            </p>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="SignupForm.signUp" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
