import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { TRANSITIONS } from '../../util/transaction';
import { parse } from '../../util/urlHelpers';
import config from '../../config';
import axios from 'axios';

const RESULT_PAGE_SIZE = 24;

const axiosConfig = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
};

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';
export const LOAD_TRANSATIONS_SUCCESS = 'app/SearchPage/LOAD_TRANSATIONS_SUCCESS';
export const LOAD_WORKTRIPPS_SUCCESS = 'app/WorktrippPage/LOAD_WORKTRIPPS_SUCCESS';
export const WORKTRIPP_DELETED = 'app/WorktrippPage/WORKTRIPP_DELETED';
export const LOAD_PARTICIPANTS_SUCCESS = 'app/WorktrippPage/LOAD_PARTICIPANTS_SUCCESS';

export const LOAD_WORKTRIPP_REQUEST = 'app/WorktrippPage/LOAD_WORKTRIPP_REQUEST';
export const LOAD_WORKTRIPP_SUCCESS = 'app/WorktrippPage/LOAD_WORKTRIPP_SUCCESS';
export const LOAD_WORKTRIPP_ERROR = 'app/WorktrippPage/LOAD_WORKTRIPP_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentListings: [],
  transactionsStatus: [],
  worktripps: [],
  worktripp: {},
  selectedWorktripp: {}, // TODO: temp - can be worktripp
  worktrippListings: {},
  worktrippLoadInProgress: false,
  refreshWorktripps: 0,
  participants: [],
};

const WorktrippReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentListings: payload.data,
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };
    case LOAD_TRANSATIONS_SUCCESS:
      return { ...state, transactionsStatus: payload.data };
    case LOAD_WORKTRIPPS_SUCCESS: {
      return {
        ...state,
        worktripps: payload.worktripps,
        worktripp: payload.worktripp,
        selectedWorktripp: payload.worktripp,
        worktrippListings: payload.listings,
        worktrippLoadInProgress: false,
      };
    }
    case WORKTRIPP_DELETED:
      return { ...state, refreshWorktripps: (state.refreshWorktripps += 1) };

    case LOAD_WORKTRIPP_REQUEST:
      return {
        ...state,
        worktrippLoadInProgress: true,
        worktrippLoadError: null,
      };

    case LOAD_WORKTRIPP_SUCCESS:
      return {
        ...state,
        refreshWorktripps: (state.refreshWorktripps += 1),
        worktripp: payload.worktripp,
        selectedWorktripp: payload.worktripp,
        worktrippListings: payload.listings,
        worktrippLoadInProgress: false,
      };
    case LOAD_WORKTRIPP_ERROR:
      return { ...state, worktrippLoadInProgress: false, worktrippLoadError: payload };

    case LOAD_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        participants: payload,
      };
    default:
      return state;
  }
};

export default WorktrippReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const loadTransactionsSuccess = response => ({
  type: LOAD_TRANSATIONS_SUCCESS,
  payload: { data: response },
});

export const loadWorktrippsSuccess = response => ({
  type: LOAD_WORKTRIPPS_SUCCESS,
  payload: response,
});

export const worktrippDeleted = () => ({
  type: WORKTRIPP_DELETED,
  payload: null,
});

export const loadWorktrippRequest = () => ({
  type: LOAD_WORKTRIPP_REQUEST,
  payload: null,
});

export const loadWorktrippSuccess = response => ({
  type: LOAD_WORKTRIPP_SUCCESS,
  payload: response,
});

export const loadWorktrippError = response => ({
  type: LOAD_WORKTRIPP_ERROR,
  payload: response,
});

export const loadParticipantsSuccess = response => ({
  type: LOAD_PARTICIPANTS_SUCCESS,
  payload: response,
});

// FUNCTIONS

export const getWorktrippsList = ({
  user_id,
  subUserId,
  permission,
  workTripListOpened,
  ignoreUpdateProfile,
}) => (dispatch, getState, sdk) => {
  const params =
    permission == 'User'
      ? {
          user_id,
          subUserId,
        }
      : {
          user_id,
        };

  dispatch(loadWorktrippRequest());
  axios
    .post(`/api/worktripp/list`, params, axiosConfig)
    .then(async result => {
      const selectedWorktripp = result.data.find(worktripp => worktripp.selected);
      const worktrippListings = await loadWorktrippListings(selectedWorktripp, sdk);
      dispatch(
        loadWorktrippsSuccess({
          worktripps: result.data,
          worktripp: selectedWorktripp,
          listings: worktrippListings,
        })
      );

      if (!ignoreUpdateProfile) {
        let publicData = {
          worktrippCount: result?.data ? result.data.length : 0,
        };
        if (workTripListOpened) {
          publicData = {
            ...publicData,
            workTripListOpened,
          };
        }
        sdk.currentUser.updateProfile({
          publicData,
        });
      }

      return result.data;
    })
    .catch(e => {
      dispatch(loadWorktrippError(storableError(e)));
      console.log(e);
      throw e;
    });
};

// TODO: check it makes sense to not throw and set to null instead
export const getWorktripp = id => (dispatch, getState, sdk) => {
  dispatch(loadWorktrippRequest());
  axios
    .post(
      `/api/worktripp/get`,
      {
        id,
      },
      axiosConfig
    )
    .then(async result => {
      const selectedWorktripp = result.data;
      let worktrippListings = await loadWorktrippListings(selectedWorktripp, sdk);
      dispatch(loadWorktrippSuccess({ worktripp: selectedWorktripp, listings: worktrippListings }));

      return selectedWorktripp;
    })
    .catch(e => {
      dispatch(loadWorktrippSuccess({ worktripp: null, listings: [] }));
    });
};

const loadWorktrippListings = async (worktripp, sdk) => {
  try {
    let listingIds = worktripp.listings.map(listing => listing.listing_id);

    const response = await sdk.listings.query({
      ids: listingIds,
      include: ['images'],
      'fields.image': ['variants.square-small'],
      'limit.images': 1,
    });

    let fullListings = {};

    worktripp.listings.forEach(({ listing_id, tx_id }) => {
      let listing = response.data?.data?.find(listing => listing.id.uuid === listing_id);
      let images = listing.relationships.images.data.map(imageData => {
        return response.data.included.find(
          item => item.type === 'image' && item.id.uuid === imageData.id.uuid
        );
      });

      if (listing) {
        fullListings[listing_id] = { ...listing, images };
      }
    });

    return fullListings;
  } catch (error) {
    // Handle errors
    console.error('Error querying listings:', error);
  }
};

export const createWorktripp = params => async (dispatch, getState, sdk) => {
  dispatch(loadWorktrippRequest());
  try {
    const result = await axios.post('/api/worktripp-onboarding', params, axiosConfig);

    const newWorktrippId = result.data._id;
    const allIds = getState()
      .Worktripp.worktripps.map(w => w._id)
      .filter(id => id !== newWorktrippId);
    dispatch(selectWorktripp({ selectedId: newWorktrippId, allIds: allIds }));
    return result.data;
  } catch (e) {
    dispatch(loadWorktrippError(storableError(e)));
    throw e;
  }
};

export const editWorktripp = params => (dispatch, getState, sdk) => {
  dispatch(loadWorktrippRequest());
  axios
    .post(`/api/worktripp/edit`, params, axiosConfig)
    .then(async result => {
      const updatedWorktripp = result.data;
      const wortrkippListings = await loadWorktrippListings(updatedWorktripp, sdk);

      dispatch(loadWorktrippSuccess({ worktripp: updatedWorktripp, listings: wortrkippListings }));
      return result.data;
    })
    .catch(e => {
      dispatch(loadWorktrippError(storableError(e)));
      throw e;
    });
};

export const deleteWorktripp = params => async (dispatch, getState) => {
  dispatch(loadWorktrippRequest());
  try {
    await axios.post(
      `/api/worktripp/delete`,
      {
        id: params.id,
      },
      axiosConfig
    );

    // const result = await axios.post(
    //   `/api/worktripp/getLastWorktripp`,
    //   {
    //     user_id: params.userId,
    //   },
    //   axiosConfig
    // );

    // console.log('last wortrkipp', result.data);

    // const lastWorktrippId = result.data._id;
    const allIds = getState()
      .Worktripp.worktripps.map(w => w._id)
      .filter(id => id !== params.id);

    console.log('allIds', allIds);

    dispatch(selectWorktripp({ selectedId: allIds?.[0], allIds: allIds }));
    return {};
  } catch (e) {
    dispatch(loadWorktrippError(storableError(e)));
    throw e;
  }
};

export const selectWorktripp = params => (dispatch, getState, sdk) => {
  dispatch(loadWorktrippRequest());
  axios
    .post(`/api/worktripp/select`, params, axiosConfig)
    .then(async result => {
      const selectedWorktripp = result.data.find(worktripp => worktripp.selected);
      const wortrkippListings = await loadWorktrippListings(selectedWorktripp, sdk);
      dispatch(
        loadWorktrippsSuccess({
          worktripps: result.data,
          worktripp: selectedWorktripp,
          listings: wortrkippListings,
        })
      );
      return result.data;
    })
    .catch(e => {
      dispatch(loadWorktrippError(storableError(e)));
      throw e;
    });
};

export const addListingToWorktripp = params => dispatch => {
  const { worktripp, listingId, txId } = params;
  if (!worktripp?._id) {
    return;
  }

  const currentListings = worktripp.listings ?? [];
  const listingIndex = currentListings.findIndex(listing => listing.listing_id === listingId);
  const listingEntry = {
    listing_id: listingId,
    tx_id: txId ?? 'NOT CONTACTED',
  };

  let updatedListings = [...currentListings];
  if (listingIndex > -1) {
    // TODO: this is not correct as txId cannot be changed if already set
    updatedListings[listingIndex] = listingEntry;
  } else {
    updatedListings.push(listingEntry);
  }

  dispatch(
    editWorktripp({
      _id: worktripp._id,
      listings: updatedListings,
    })
  );
};

export const removeListingFromWorktripp = params => dispatch => {
  const { worktripp, listingId } = params;
  if (!worktripp?._id) {
    return;
  }

  const currentListings = worktripp.listings ?? [];
  const updatedListings = currentListings.filter(listing => listing.listing_id !== listingId);

  dispatch(
    editWorktripp({
      _id: worktripp._id,
      listings: updatedListings,
    })
  );
};

export const searchListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;

  const params = {
    ...rest,
    per_page: perPage,
    include: ['author', 'images'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(response));
      dispatch(loadTransactions());
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

export const loadTransactions = () => (dispatch, getState, sdk) => {
  // this is used to load all the transactions attached to the worktripp listings
  const page = 1;

  const apiQueryParams = {
    only: 'order',
    lastTransitions: TRANSITIONS,
    page,
    per_page: 100,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      const transactionsStatus = response?.data.data.map(tx => {
        return {
          id: tx.id.uuid,
          status: tx.attributes.lastTransition,
          metadata: tx.attributes.metadata,
          txVersion: tx.attributes.processVersion,
        };
      });
      dispatch(loadTransactionsSuccess(transactionsStatus));
      return transactionsStatus;
    })
    .catch(e => {
      throw e;
    });
};

export const getParticipants = worktripp_id => (dispatch, getState, sdk) => {
  axios
    .post(
      `/api/worktripp/participants/get`,
      {
        worktripp_id,
      },
      axiosConfig
    )
    .then(result => {
      dispatch(loadParticipantsSuccess(result.data));
      return result.data;
    })
    .catch(err => console.log(err));
};

export const addParticipant = params => (dispatch, getState, sdk) => {
  axios
    .post(`/api/worktripp/participants/add`, params, axiosConfig)
    .then(result => {
      dispatch(loadParticipantsSuccess(result.data));
      return result.data;
    })
    .catch(err => console.log(err));
};

export const deleteParticipant = params => (dispatch, getState, sdk) => {
  axios
    .post(`/api/worktripp/participants/delete`, params, axiosConfig)
    .then(result => {
      dispatch(loadParticipantsSuccess(result.data));
      return result.data;
    })
    .catch(err => console.log(err));
};

export const changeParticipant = params => (dispatch, getState, sdk) => {
  axios
    .post(`/api/worktripp/participants/change`, params, axiosConfig)
    .then(result => {
      dispatch(loadParticipantsSuccess(result.data));
      return result.data;
    })
    .catch(err => console.log(err));
};

export const loadData = (params, search) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  return searchListings({
    ...rest,
    ...originMaybe,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['author', 'images'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
  });
};
