import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Button, SecondaryButton } from '../../components';
import { FormattedMessage, injectIntl } from 'react-intl';
import Modal from '../Modal/Modal';
import ScheduleMeetingForm from './ScheduleMeetingForm/ScheduleMeetingForm';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { uploadFile, createMeeting } from '../../containers/TransactionPage/TransactionPage.duck';
import callIconMain from '../../assets/icons/callIconMain.png';
import paperclipIcon from '../../assets/icons/paperclipIcon.png';
import callIcon from '../../assets/icons/callIcon.png';
import videoIcon from '../../assets/icons/videoIcon.png';
import css from './ChatActionsSection.module.css';
import { getClientTimeZone } from '../../util/dates';

const ChatActionsSectionComponent = props => {
  const {
    transactionId,
    currentUserId,
    isCustomer,
    providerPhoneNumber,
    onManageDisableScrolling,
    onUploadFile,
    sendFileInProgress,
    sendFileError,
    onCreateMeeting,
    sendMeetingInProgress,
    sendMeetingError,
  } = props;

  const [meetingModalOpen, setMeetingModalOpen] = useState(false);
  const [uploadFileModalOpen, setUploadFileModalOpen] = useState(false);
  const [tempFile, setTempFile] = useState();
  const [fileDesc, setFileDesc] = useState('');

  const intialTimeZone = getClientTimeZone();
  const shouldShowCallButton = isCustomer && providerPhoneNumber;

  return (
    <div className={css.root}>
      <div className={css.titleHeader}>
        <img src={callIconMain} />
        <FormattedMessage id="ChatActionsSection.additionalOptionsPrompt" />
      </div>

      {sendFileError ? <div className={css.error}>{sendFileError}</div> : null}

      {sendMeetingError ? <div className={css.error}>{sendMeetingError}</div> : null}

      <div className={css.actionWrapper}>
        <SecondaryButton
          className={css.actionButton}
          inProgress={sendMeetingInProgress}
          disabled={sendMeetingInProgress}
          onClick={() => setMeetingModalOpen(true)}
        >
          <img src={videoIcon} />
          <FormattedMessage id="ChatActionsSection.scheduleMeeting" />
        </SecondaryButton>

        {shouldShowCallButton ? (
          <SecondaryButton
            className={css.actionButton}
            onClick={() => window.open(`callto:${providerPhoneNumber}`)}
          >
            <img src={callIcon} />
            <FormattedMessage id="ChatActionsSection.callProvider" />
          </SecondaryButton>
        ) : null}

        <SecondaryButton
          className={css.actionButton}
          inProgress={sendFileInProgress}
          disabled={sendFileInProgress}
          onClick={() => {
            setUploadFileModalOpen(true);
          }}
        >
          <img src={paperclipIcon} />
          {isCustomer ? (
            <FormattedMessage id="ChatActionsSection.uploadFile" />
          ) : (
            <FormattedMessage id="ChatActionsSection.shareProposal" />
          )}
        </SecondaryButton>
      </div>

      <Modal
        id="ChatActionsSection.ScheduleMeeting"
        isOpen={meetingModalOpen}
        onClose={() => setMeetingModalOpen(false)}
        onManageDisableScrolling={() => {}}
      >
        <ScheduleMeetingForm
          onSubmit={values => {
            const { date, time, meetingLength, url, timeZone } = values;

            onCreateMeeting({
              start_date: date.date,
              start_time: time,
              timeZone,
              minutes_needed: Number(meetingLength),
              meeting_URL: url,
              tx_id: transactionId,
              user_id: currentUserId,
            });
            setMeetingModalOpen(false);
          }}
          initialValues={{ timeZone: intialTimeZone, date: new Date() }}
        />
      </Modal>

      <Modal
        id="ChatActionsSection.UploadFile"
        isOpen={uploadFileModalOpen}
        onClose={() => {
          setUploadFileModalOpen(false);
        }}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <label htmlFor="file">
          <FormattedMessage id="ChatActionsSection.selectFile" />
        </label>
        <input
          type="file"
          id="file"
          name="file"
          onChange={e => {
            setTempFile(e.target.files[0]);
          }}
        />
        <br />
        <label htmlFor="desc">
          <FormattedMessage id="ChatActionsSection.desc" />
        </label>
        <input
          type="text"
          id="desc"
          name="desc"
          value={fileDesc}
          onChange={e => setFileDesc(e.target.value)}
        />
        <br />
        <p>
          <FormattedMessage id="ChatActionsSection.infoText" />
        </p>
        <Button
          className={css.actionButton}
          disabled={!tempFile || !tempFile.name || !fileDesc}
          onClick={() => {
            const formData = new FormData();
            formData.append('file', tempFile);
            formData.append('name', tempFile.name);
            formData.append('desc', fileDesc);
            formData.append('tx_id', transactionId);
            formData.append('user_id', currentUserId);
            onUploadFile(formData);
            setUploadFileModalOpen(false);
          }}
        >
          {isCustomer ? (
            <FormattedMessage id="ChatActionsSection.uploadFile" />
          ) : (
            <FormattedMessage id="ChatActionsSection.shareProposal" />
          )}
        </Button>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => {
  const {
    sendFileInProgress,
    sendFileError,
    sendMeetingInProgress,
    sendMeetingError,
  } = state.TransactionPage;

  return {
    sendFileInProgress,
    sendFileError,
    sendMeetingInProgress,
    sendMeetingError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUploadFile: params => dispatch(uploadFile(params)),
    onCreateMeeting: params => dispatch(createMeeting(params)),
    onManageDisableScrolling: (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
  };
};

const ChatActionsSection = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ChatActionsSectionComponent);

export default ChatActionsSection;
