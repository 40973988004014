import { clearCurrentUser, fetchCurrentUser } from './user.duck';
import { createUserWithIdp } from '../util/api';
import { storableError } from '../util/errors';
import axios from 'axios';
import * as log from '../util/log';
import CryptoJS from 'crypto-js';

const encryptData = text => {
  const data = CryptoJS.AES.encrypt(
    JSON.stringify(text),
    process.env.REACT_APP_ENCRYPTION_KEY
  ).toString();
  return data;
};

const setLocalStorage = (permission, accId, childAccId) => {
  // set user data to localStorage
  if (typeof window !== 'undefined') {
    // current user permission between superAdmin, admin or user.
    window.localStorage.setItem('permissionLevel', encryptData(permission));
    // main account id
    window.localStorage.setItem('accId', encryptData(accId));
    // sub account id, stored on mongoDB and not the flex
    window.localStorage.setItem('childAccId', encryptData(childAccId));
    // time when session has started, used to ask user to login again after sometime
    window.localStorage.setItem('sessionStarted', new Date().toISOString());
  }
};

const authenticated = authInfo => authInfo && authInfo.isAnonymous === false;

// ================ Action types ================ //

export const AUTH_INFO_REQUEST = 'app/Auth/AUTH_INFO_REQUEST';
export const AUTH_INFO_SUCCESS = 'app/Auth/AUTH_INFO_SUCCESS';

export const LOGIN_REQUEST = 'app/Auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'app/Auth/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'app/Auth/LOGIN_ERROR';
export const LOGIN_ERROR_MSG = 'app/Auth/LOGIN_ERROR_MSG';

export const LOGOUT_REQUEST = 'app/Auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'app/Auth/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'app/Auth/LOGOUT_ERROR';
export const ACTION_AFTER_LOGIN = 'app/Auth/ACTION_AFTER_LOGIN';
export const RESET_ACTION_AFTER_LOGIN = 'app/Auth/RESET_ACTION_AFTER_LOGIN';

export const SIGNUP_REQUEST = 'app/Auth/SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'app/Auth/SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'app/Auth/SIGNUP_ERROR';

export const CONFIRM_REQUEST = 'app/Auth/CONFIRM_REQUEST';
export const CONFIRM_SUCCESS = 'app/Auth/CONFIRM_SUCCESS';
export const CONFIRM_ERROR = 'app/Auth/CONFIRM_ERROR';

// Generic user_logout action that can be handled elsewhere
// E.g. src/reducers.js clears store as a consequence
export const USER_LOGOUT = 'app/USER_LOGOUT';

// ================ Reducer ================ //

const initialState = {
  isAuthenticated: false,

  // scopes associated with current token
  authScopes: [],

  // auth info
  authInfoLoaded: false,

  // login
  loginError: null,
  loginErrorMsg: null,
  loginInProgress: false,
  actionAfterLogin: null,

  // logout
  logoutError: null,
  logoutInProgress: false,

  // signup
  signupError: null,
  signupInProgress: false,

  // confirm (create use with idp)
  confirmError: null,
  confirmInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case AUTH_INFO_REQUEST:
      return state;
    case AUTH_INFO_SUCCESS:
      return {
        ...state,
        authInfoLoaded: true,
        isAuthenticated: authenticated(payload),
        authScopes: payload.scopes,
      };

    case LOGIN_REQUEST:
      return {
        ...state,
        loginInProgress: true,
        loginError: null,
        loginErrorMsg: null,
        logoutError: null,
        signupError: null,
      };
    case LOGIN_SUCCESS:
      return { ...state, loginInProgress: false, isAuthenticated: true };
    case ACTION_AFTER_LOGIN:
      return { ...state, actionAfterLogin: payload };
    case RESET_ACTION_AFTER_LOGIN:
      return { ...state, actionAfterLogin: null };
    case LOGIN_ERROR:
      return { ...state, loginInProgress: false, loginError: payload };
    case LOGIN_ERROR_MSG:
      return { ...state, loginInProgress: false, loginErrorMsg: payload };

    case LOGOUT_REQUEST:
      return {
        ...state,
        logoutInProgress: true,
        loginError: null,
        loginErrorMsg: null,
        logoutError: null,
      };
    case LOGOUT_SUCCESS:
      return { ...state, logoutInProgress: false, isAuthenticated: false, authScopes: [] };
    case LOGOUT_ERROR:
      return { ...state, logoutInProgress: false, logoutError: payload };

    case SIGNUP_REQUEST:
      return {
        ...state,
        signupInProgress: true,
        loginError: null,
        loginErrorMsg: null,
        signupError: null,
      };
    case SIGNUP_SUCCESS:
      return { ...state, signupInProgress: false };
    case SIGNUP_ERROR:
      return { ...state, signupInProgress: false, signupError: payload };

    case CONFIRM_REQUEST:
      return {
        ...state,
        confirmInProgress: true,
        loginError: null,
        loginErrorMsg: null,
        confirmError: null,
      };
    case CONFIRM_SUCCESS:
      return { ...state, confirmInProgress: false, isAuthenticated: true };
    case CONFIRM_ERROR:
      return { ...state, confirmInProgress: false, confirmError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const authenticationInProgress = state => {
  const { loginInProgress, logoutInProgress, signupInProgress } = state.Auth;
  return loginInProgress || logoutInProgress || signupInProgress;
};

// ================ Action creators ================ //

export const authInfoRequest = () => ({ type: AUTH_INFO_REQUEST });
export const authInfoSuccess = info => ({ type: AUTH_INFO_SUCCESS, payload: info });

export const loginRequest = () => ({ type: LOGIN_REQUEST });
export const loginSuccess = () => ({ type: LOGIN_SUCCESS });
export const loginError = error => ({ type: LOGIN_ERROR, payload: error, error: true });
export const loginErrorMsg = error => ({ type: LOGIN_ERROR_MSG, payload: error });

export const logoutRequest = () => ({ type: LOGOUT_REQUEST });
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });
export const logoutError = error => ({ type: LOGOUT_ERROR, payload: error, error: true });

export const signupRequest = () => ({ type: SIGNUP_REQUEST });
export const signupSuccess = () => ({ type: SIGNUP_SUCCESS });
export const signupError = error => ({ type: SIGNUP_ERROR, payload: error, error: true });

export const confirmRequest = () => ({ type: CONFIRM_REQUEST });
export const confirmSuccess = () => ({ type: CONFIRM_SUCCESS });
export const confirmError = error => ({ type: CONFIRM_ERROR, payload: error, error: true });

export const userLogout = () => ({ type: USER_LOGOUT });

// ================ Thunks ================ //

export const authInfo = () => (dispatch, getState, sdk) => {
  dispatch(authInfoRequest());
  return sdk
    .authInfo()
    .then(info => dispatch(authInfoSuccess(info)))
    .catch(e => {
      // Requesting auth info just reads the token from the token
      // store (i.e. cookies), and should not fail in normal
      // circumstances. If it fails, it's due to a programming
      // error. In that case we mark the operation done and dispatch
      // `null` success action that marks the user as unauthenticated.
      log.error(e, 'auth-info-failed');
      dispatch(authInfoSuccess(null));
    });
};

export const login = (username, password, accountId, actionAfterLogin) => (
  dispatch,
  getState,
  sdk
) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(loginRequest());

  // call the backend first to get the sub user info
  return axios
    .post(
      `/api/auth/loginAsSubUser`,
      {
        email: username,
        password,
        accountId,
      },
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      }
    )
    .then(result => {
      // if user found and password is correct we login with the flex sdk with the info coming from the backend for the MAIN USER instead.
      return sdk
        .login({
          username: result.data.decodedEmail ? result.data.decodedEmail : username,
          password: result.data.decodedPw ? result.data.decodedPw : password,
        })
        .then(() => dispatch(loginSuccess()))
        .then(() => {
          const permission = result.data.permissionLevel;
          const accId = result.data.parentAccId;
          const childAccId = result.data.childAccId;

          if (accId) {
            // if main user and subusers are found just save the details to localStorage
            setLocalStorage(permission, accId, childAccId);
          } else {
            // if not it means it's a first time login so we send to the backend to create the subuser
            // hint: subusers aren't created on sign up, we had to make this on login cause we implemented the feature quite late and many accounts were created before.
            axios
              .post(
                `/api/auth/createSubUser`,
                {
                  // email and pw from sharetribe, they are needed for later to login as main account
                  email: username,
                  password,
                  // super admin and isOwner true because the user is the main account user, (cause it's first time login)
                  pl: 'SuperAdmin',
                  parentAccEmail: username,
                  parentAccPw: password,
                  isOwner: true,
                },
                {
                  headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                  },
                }
              )
              .then(res => {
                const permission2 = res.data.permissionLevel;
                const accId2 = res.data.parentAccId;
                const childAccId2 = result.data.childAccId2;
                // since we didn't save the info earlier so we save them to localStorage here!
                setLocalStorage(permission2, accId2, childAccId2);
                if (actionAfterLogin) {
                  dispatch({
                    type: ACTION_AFTER_LOGIN,
                    payload: actionAfterLogin,
                  });
                } else {
                  window.location.reload();
                }
              })
              .catch(err => dispatch(loginErrorMsg(err.response.data)));
          }
          return true;
        })
        .then(() => dispatch(fetchCurrentUser()))
        .catch(e => dispatch(loginError(storableError(e))));
    })
    .catch(err => {
      dispatch(loginErrorMsg(err.response.data));
    });
};

export const logout = () => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(logoutRequest());

  // Note that the thunk does not reject when the logout fails, it
  // just dispatches the logout error action.
  return sdk
    .logout()
    .then(() => {
      // we clear storage on logout so no subuser info stays there!
      typeof window !== 'undefined' && localStorage.clear();
      dispatch(logoutSuccess());
      dispatch(clearCurrentUser());
      log.clearUserId();
      dispatch(userLogout());
    })
    .catch(e => dispatch(logoutError(storableError(e))));
};

export const signup = (params, worktrippId, actionAfterLogin) => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(signupRequest());
  const {
    password,
    firstName,
    lastName,
    organisationName,
    jobtitle,
    interestedIn,
    ...rest
  } = params;
  const email = params.email?.toLowerCase();

  // based on what's the user interest is, we save the user as a customer, provider or both.
  const customer = interestedIn == 'Booking a WorkTripp' || interestedIn == 'Both';
  const provider = interestedIn == 'Listing my services' || interestedIn == 'Both';

  // since a subuser might be exist with this email, we need to check if the email is saved to mongo before submitting the signup to flex.
  return axios
    .post(
      `/api/auth/checkEmailExists`,
      {
        email,
      },
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      }
    )
    .then(result => {
      if (result.data == 'Yes') {
        dispatch(
          loginErrorMsg(
            'An account with this email address already exists. Try logging in instead.'
          )
        );
        dispatch(signupError(''));
      } else {
        const createUserParams = {
          email,
          password,
          firstName,
          lastName,
          publicData: { organisationName, jobtitle },
          protectedData: { ...rest, customer, provider },
        };

        // We must login the user if signup succeeds since the API doesn't
        // do that automatically.
        return sdk.currentUser
          .create(createUserParams)
          .then(async user => {
            try {
              await axios.post(
                `/api/worktripp/assignTemp`,
                {
                  email: email,
                  user_id: user.data.data.id.uuid,
                  sharetribeUserId: user?.data?.data?.id.uuid,
                  worktrippId,
                },
                {}
              );
              dispatch(signupSuccess());
              dispatch(login(email, password, 'pass', actionAfterLogin));
            } catch (err) {
              console.log(err);
            }
          })
          .catch(e => {
            dispatch(signupError(storableError(e)));
            log.error(e, 'signup-failed', {
              email: params.email,
              firstName: params.firstName,
              lastName: params.lastName,
            });
          });
      }
    });
};

export const signupWithIdp = params => (dispatch, getState, sdk) => {
  dispatch(confirmRequest());
  return createUserWithIdp(params)
    .then(res => {
      return dispatch(confirmSuccess());
    })
    .then(() => dispatch(fetchCurrentUser()))
    .catch(e => {
      log.error(e, 'create-user-with-idp-failed', { params });
      return dispatch(confirmError(storableError(e)));
    });
};
