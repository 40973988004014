import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney, formatLineItemsPercentage } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_CUSTOMER_COMMISSION_VAT, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const { Money } = sdkTypes;

const withNegativePercentage = lineItems => {
  return lineItems?.some(lineItem => Number(lineItem.percentage) < 0);
};

const LineItemCustomerCommissionVatMaybe = ({ lineItems, isCustomer, calcValue }) => {
  const customerCommissionVatLineItems = lineItems?.filter(
    item => item.code === LINE_ITEM_CUSTOMER_COMMISSION_VAT && !item.reversal
  );

  if (
    !isCustomer ||
    !customerCommissionVatLineItems ||
    customerCommissionVatLineItems.length === 0
  ) {
    return null;
  }
  const percentageString = formatLineItemsPercentage(customerCommissionVatLineItems);

  const lineItemValue = withNegativePercentage(customerCommissionVatLineItems) ? (
    <strong>pending</strong>
  ) : (
    calcValue(customerCommissionVatLineItems)
  );

  return (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage
          id="BookingBreakdown.customerCommissionVat"
          values={{ percentage: percentageString }}
        />
      </span>
      <span className={css.itemValue}>{lineItemValue}</span>
    </div>
  );
};

LineItemCustomerCommissionVatMaybe.propTypes = {
  // lineItems: propTypes.lineItems.isRequired,
  // isCustomer: bool.isRequired,
};

export default LineItemCustomerCommissionVatMaybe;
