import React, { useState } from 'react';
import { array, func, string } from 'prop-types';
import { compose } from 'redux';
import { intlShape, injectIntl } from '../../../util/reactIntl';
import { required, composeValidators } from '../../../util/validators';
import { FieldTextInput, FieldCheckboxGroup } from '../../../components';
import ClientTestimonials from './ClientTestimonials';
import css from '../EditListingDescriptionForm.module.css';

const CFS_Fields = props => {
  const {
    intl,
    values,
    modeOfEngagementOptions,
    testimonials,
    addTestimonial,
    deleteTestimonial,
    form,
    onImageUpload,
    lastImageId,
    images,
  } = props;

  const [revealSecondInput, setRevealSecondInput] = useState(
    values.industryAccreditationURLTwo ? true : false
  );

  const iaURLMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.iaURL',
  });
  const iaURLPlaceholderMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.iaURLPlaceholder',
  });
  const iaURLMessageTwo = intl.formatMessage({
    id: 'EditListingDescriptionForm.iaURLTwo',
  });
  const iaURLPlaceholderMessageTwo = intl.formatMessage({
    id: 'EditListingDescriptionForm.iaURLPlaceholderTwo',
  });
  const iaURLRequiredMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.iaURLRequired',
  });

  return (
    <>
      <div>
        <h4 className={css.modeOfEngagementTitle}>What is your preferred mode of engagement?</h4>
        <p className={css.modeOfEngagementText}>Please tick any that apply.</p>
        <FieldCheckboxGroup
          className={css.modeOfEngagementCheckboxes}
          id="modeOfEngagement"
          name="modeOfEngagement"
          label={''}
          options={modeOfEngagementOptions}
        />

        <ClientTestimonials
          intl={intl}
          values={values}
          testimonials={testimonials}
          addTestimonial={addTestimonial}
          deleteTestimonial={deleteTestimonial}
          form={form}
          onImageUpload={onImageUpload}
          lastImageId={lastImageId}
          images={images}
        />

        {values.listingType &&
        (values.listingType == 'certifiedCoach' ||
          values.listingType == 'domainSpecialistCoach' ||
          values.listingType == 'expertFacilitator') ? (
          <div style={{ marginTop: '40px' }}>
            <FieldTextInput
              id="industryAccreditationURL"
              name="industryAccreditationURL"
              className={css.description}
              type="url"
              label={values.listingType == 'certifiedCoach' ? iaURLMessage : iaURLMessageTwo}
              placeholder={
                values.listingType == 'certifiedCoach'
                  ? iaURLPlaceholderMessage
                  : iaURLPlaceholderMessageTwo
              }
              // validate={composeValidators(required(iaURLRequiredMessage))}
            />
            {!revealSecondInput && (
              <div
                onClick={() => {
                  setRevealSecondInput(true);
                }}
                className={css.addMoreAcc}
              >
                + Add more accreditations
              </div>
            )}
          </div>
        ) : null}

        {revealSecondInput &&
        values.listingType &&
        (values.listingType == 'certifiedCoach' ||
          values.listingType == 'domainSpecialistCoach' ||
          values.listingType == 'expertFacilitator') ? (
          <FieldTextInput
            id="industryAccreditationURLTwo"
            name="industryAccreditationURLTwo"
            className={css.description}
            type="url"
            label={
              values.listingType == 'certifiedCoach' ? iaURLMessage + ' 2' : iaURLMessageTwo + ' 2'
            }
            placeholder={
              values.listingType == 'certifiedCoach'
                ? iaURLPlaceholderMessage
                : iaURLPlaceholderMessageTwo
            }
          />
        ) : null}
      </div>
    </>
  );
};

CFS_Fields.defaultProps = { className: null };

CFS_Fields.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  modeOfEngagementOptions: array,
  testimonials: array,
  addTestimonial: func,
  onImageUpload: func,
};

export default compose(injectIntl)(CFS_Fields);
