import React from 'react';
import { string, array } from 'prop-types';
import { compose } from 'redux';
import { intlShape, injectIntl } from '../../../util/reactIntl';
import { required, composeValidators } from '../../../util/validators';
import { FieldTextInput, FieldCheckboxGroup, Tooltip } from '../../../components';
import ClientTestimonials from './ClientTestimonials';
import vanueLogo from '../../../assets/icons/VenueLogo.svg';
import starLogo from '../../../assets/icons/star.svg';
import expLogo from '../../../assets/icons/ExpLogo.svg';
import css from '../EditListingDescriptionForm.module.css';

const VE_Fields = props => {
  const {
    intl,
    values,
    testimonials,
    addTestimonial,
    deleteTestimonial,
    form,
    onImageUpload,
    lastImageId,
    images,
    venueTypeOptions,
    listingType,
    venueVibeOptions,
  } = props;

  const capacitiesTitle = intl.formatMessage({ id: 'EditListingDescriptionForm.capacitiesTitle' });
  const maxEventCapacityMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.maxEventCapacityMessage',
  });
  const maxEventCapacityRequired = intl.formatMessage({
    id: 'EditListingDescriptionForm.maxEventCapacityRequired',
  });

  const noOfUniqueBedroomsMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.noOfUniqueBedroomsMessage',
  });
  const noOfUniqueBedroomsRequired = intl.formatMessage({
    id: 'EditListingDescriptionForm.noOfUniqueBedroomsRequired',
  });

  const maxSleepCapacityMessage = (
    <div>
      <span>
        {intl.formatMessage({ id: 'EditListingDescriptionForm.maxSleepCapacityMessage' })}
      </span>
      <Tooltip messageId={'EditListingDescriptionForm.maxSleepCapacityTooltip'} />
    </div>
  );
  const maxSleepCapacityRequired = intl.formatMessage({
    id: 'EditListingDescriptionForm.maxSleepCapacityRequired',
  });

  const noOfBathroomsMessage = intl.formatMessage({
    id: 'EditListingDescriptionForm.noOfBathroomsMessage',
  });
  const noOfBathroomsRequired = intl.formatMessage({
    id: 'EditListingDescriptionForm.noOfBathroomsRequired',
  });

  const venueVibeTitle = (
    <span>
      <img className={css.venueTypeImg} src={starLogo}></img>
      Vibes
    </span>
  );

  const venueCatTitle = (
    <span>
      <img className={css.venueTypeImg} src={vanueLogo}></img>
      Venues
    </span>
  );

  const expCatTitle = (
    <span>
      <img className={css.venueTypeImg} src={expLogo}></img>
      Experience
    </span>
  );

  const vibeModifiedOptions = venueVibeOptions
    .filter(o => o.parent == 'venue')
    .map(op => {
      if (op.description && !op.label.includes(op.description)) {
        op.label += `: ${op.description}`;
      }
      return op;
    });

  return (
    <>
      {listingType == 'venue' || listingType == 'retreat' ? (
        <>
          <br />
          <FieldCheckboxGroup
            id="venueVibe"
            name="venueVibe"
            label={venueVibeTitle}
            options={vibeModifiedOptions}
          />
        </>
      ) : null}

      {listingType == 'venue' || listingType == 'retreat' ? (
        <>
          <br />
          <FieldCheckboxGroup
            id="venueType"
            name="venueType"
            label={venueCatTitle}
            options={venueTypeOptions.filter(o => o.parent == 'venue')}
          />
        </>
      ) : null}

      {listingType !== 'venue' && (
        <>
          <br />
          <FieldCheckboxGroup
            id="venueType"
            name="venueType"
            label={expCatTitle}
            options={venueTypeOptions.filter(o => o.parent == 'experiences')}
          />
        </>
      )}

      <br />
      <h6>{capacitiesTitle}</h6>
      <FieldTextInput
        id="maxEventCapacity"
        name="maxEventCapacity"
        className={css.title}
        type="number"
        label={maxEventCapacityMessage}
        placeholder={'123'}
        validate={
          listingType !== 'retreat' && composeValidators(required(maxEventCapacityRequired))
        }
      />
      {listingType !== 'experiences' && (
        <>
          <FieldTextInput
            id="noOfUniqueBedrooms"
            name="noOfUniqueBedrooms"
            className={css.title}
            type="number"
            label={noOfUniqueBedroomsMessage}
            placeholder={'123'}
            validate={
              listingType !== 'retreat' && composeValidators(required(noOfUniqueBedroomsRequired))
            }
          />
          <FieldTextInput
            id="maxSleepCapacity"
            name="maxSleepCapacity"
            className={css.title}
            type="number"
            label={maxSleepCapacityMessage}
            placeholder={'123'}
            validate={
              listingType !== 'retreat' && composeValidators(required(maxSleepCapacityRequired))
            }
          />
          <FieldTextInput
            id="noOfBathrooms"
            name="noOfBathrooms"
            className={css.title}
            type="number"
            label={noOfBathroomsMessage}
            placeholder={'123'}
            validate={
              listingType !== 'retreat' && composeValidators(required(noOfBathroomsRequired))
            }
          />
        </>
      )}

      <ClientTestimonials
        intl={intl}
        values={values}
        testimonials={testimonials}
        addTestimonial={addTestimonial}
        deleteTestimonial={deleteTestimonial}
        form={form}
        onImageUpload={onImageUpload}
        lastImageId={lastImageId}
        images={images}
      />
    </>
  );
};

VE_Fields.defaultProps = { className: null };

VE_Fields.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  venueTypeOptions: array,
  venueVibeOptions: array,
  alsoIncludesOptions: array,
  listingType: string,
};

export default compose(injectIntl)(VE_Fields);
