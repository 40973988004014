import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { txIsCancelled, txIsBooked } from '../../util/transaction';
import { propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const getTotalMessxageId = (isProvider, transaction) => {
  let totalMessageId = 'BookingBreakdown.total';

  if (isProvider) {
    totalMessageId = 'BookingBreakdown.providerTotalDefault';
    let secondPaymentStatus = 'preauthorized';
    if (txIsBooked(transaction)) {
      totalMessageId = 'BookingBreakdown.providerTotalDelivered';
      secondPaymentStatus = 'paid';
    } else if (txIsCancelled(transaction)) {
      totalMessageId = 'BookingBreakdown.providerTotalCanceled';
    }
  }
  return totalMessageId;
};

// <div>{depositRate * 100}% deposit to secure booking (payable now)</div>

// <div>{remainingRate * 100}% final payment (authorize now)</div>
const LineItemUnitPrice = ({ transaction, lineItems, userRole, calcValue }) => {
  let isProvider = userRole === 'provider';
  let totalMessageId = getTotalMessxageId(isProvider, transaction);
  const userRoleLineItems = lineItems.filter(lineItem => lineItem.includeFor.includes(userRole));

  return (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>
          <strong>
            <FormattedMessage id={totalMessageId} />
          </strong>
        </div>
        <div className={css.totalPrice}>
          {/* {formattedTotalPrice} */}
          {calcValue(userRoleLineItems)}
        </div>
      </div>
    </>
  );
};

LineItemUnitPrice.propTypes = {
  // lineItems: propTypes.lineItems.isRequired,
  // intl: intlShape.isRequired,
};

export default LineItemUnitPrice;
