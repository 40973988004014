import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { string, arrayOf, bool, func } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import dropWhile from 'lodash/dropWhile';
import classNames from 'classnames';
import { InlineTextButton, ReviewRating, UserDisplayName } from '../../components';
import { formatDate } from '../../util/dates';
import { ensureTransaction, ensureUser, ensureListing } from '../../util/data';
import {
  transitions,
  transitionIsReviewed,
  txIsReviewReady,
  txIsInFirstReviewBy,
  txIsReviewed,
  isCustomerReview,
  isProviderReview,
  txRoleIsProvider,
  txRoleIsCustomer,
  getUserTxRole,
  isRelevantPastTransition,
} from '../../util/transaction';
import { propTypes } from '../../util/types';
import * as log from '../../util/log';

import { Message, OwnMessage, Proposal, File, OwnFile, Meeting } from './ChatComponents';

import css from './ActivityFeed.module.css';
import { updateMeeting } from '../../containers/TransactionPage/TransactionPage.duck';

const Review = props => {
  const { content, rating } = props;
  return (
    <div>
      <p className={css.reviewContent}>{content}</p>
      {rating ? (
        <ReviewRating
          reviewStarClassName={css.reviewStar}
          className={css.reviewStars}
          rating={rating}
        />
      ) : null}
    </div>
  );
};

const hasUserLeftAReviewFirst = (userRole, transaction) => {
  // Because function txIsInFirstReviewBy uses isCustomer to check in which state the reviews are
  // we should also use isCustomer insted of isProvider
  const isCustomer = txRoleIsCustomer(userRole);
  return txIsInFirstReviewBy(transaction, isCustomer);
};

const resolveTransitionMessage = (
  transaction,
  transition,
  listingTitle,
  ownRole,
  otherUsersName,
  intl,
  onOpenReviewModal
) => {
  const isOwnTransition = transition.by === ownRole;
  const currentTransition = transition.transition;
  const displayName = otherUsersName;

  switch (currentTransition) {
    // showing only transitions for the main tx, payment transitions are ignored.
    case transitions.INQUIRE:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionEnquire" values={{ listingTitle }} />
      ) : (
        <FormattedMessage
          id="ActivityFeed.transitionEnquire"
          values={{ displayName, listingTitle }}
        />
      );

    case transitions.CUSTOMER_DECLINE_QUOTE:
    case transitions.PROVIDER_DECLINE_QUOTE:
    case transitions.CUSTOMER_DECLINE_INQUIRY:
    case transitions.PROVIDER_DECLINE_INQUIRY:
      return (
        <FormattedMessage
          id="ActivityFeed.transitionDecline"
          values={{ displayName: isOwnTransition ? 'You' : displayName, bookingState: 'inquiry' }}
        />
      );
    case transitions.EXPIRE_INQUIRY:
    case transitions.EXPIRE_QUOTE:
      return (
        <FormattedMessage
          id="ActivityFeed.transitionExpire"
          values={{
            bookingState: currentTransition === transitions.EXPIRE_INQUIRY ? 'Inquiry' : 'Quote',
          }}
        />
      );

    case transitions.SEND_QUOTE:
    case transitions.UPDATE_QUOTE:
      return (
        <FormattedMessage
          id="ActivityFeed.transitionQuote"
          values={{
            state: currentTransition === transitions.SEND_QUOTE ? 'sent' : 'updated',
          }}
        />
      );

    case transitions.CREATE_BOOKING:
      return <FormattedMessage id="ActivityFeed.transitionCreateBooking" />;

    case transitions.CANCEL_DEPOSIT:
    case transitions.CANCEL:
      return <FormattedMessage id="ActivityFeed.transitionCancel" />;

    case transitions.CONFIRM_DEPOSIT:
    case transitions.CONFIRM_PAYMENT:
      let paymentName =
        currentTransition === transitions.CONFIRM_DEPOSIT ? 'Deposit paid.' : 'Full payment made.';
      return isOwnTransition ? (
        <FormattedMessage
          id="ActivityFeed.ownTransitionConfirmPayments"
          values={{ listingTitle, paymentName }}
        />
      ) : (
        <FormattedMessage
          id="ActivityFeed.transitionConfirmPayments"
          values={{ displayName, listingTitle, paymentName }}
        />
      );
    case transitions.OPERATOR_COMPLETE:
    case transitions.COMPLETE:
      return null;
    case transitions.READY_TO_REVIEW:
      // Show the leave a review link if the state is delivered and if the current user is the first to leave a review
      const reviewPeriodJustStarted = txIsReviewReady(transaction);

      const reviewAsFirstLink = reviewPeriodJustStarted ? (
        <InlineTextButton onClick={onOpenReviewModal}>
          <FormattedMessage id="ActivityFeed.leaveAReview" values={{ displayName }} />
        </InlineTextButton>
      ) : null;

      return (
        <FormattedMessage
          id="ActivityFeed.transitionComplete"
          values={{ reviewLink: reviewAsFirstLink }}
        />
      );

    case transitions.REVIEW_1_BY_PROVIDER:
    case transitions.REVIEW_1_BY_CUSTOMER:
      if (isOwnTransition) {
        return <FormattedMessage id="ActivityFeed.ownTransitionReview" values={{ displayName }} />;
      } else {
        // show the leave a review link if current user is not the first
        // one to leave a review
        const reviewPeriodIsOver = txIsReviewed(transaction);
        const userHasLeftAReview = hasUserLeftAReviewFirst(ownRole, transaction);
        const reviewAsSecondLink = !(reviewPeriodIsOver || userHasLeftAReview) ? (
          <InlineTextButton onClick={onOpenReviewModal}>
            <FormattedMessage id="ActivityFeed.leaveAReviewSecond" values={{ displayName }} />
          </InlineTextButton>
        ) : null;
        return (
          <FormattedMessage
            id="ActivityFeed.transitionReview"
            values={{ displayName, reviewLink: reviewAsSecondLink }}
          />
        );
      }
    case transitions.REVIEW_2_BY_PROVIDER:
    case transitions.REVIEW_2_BY_CUSTOMER:
      if (isOwnTransition) {
        return <FormattedMessage id="ActivityFeed.ownTransitionReview" values={{ displayName }} />;
      } else {
        return (
          <FormattedMessage
            id="ActivityFeed.transitionReview"
            values={{ displayName, reviewLink: null }}
          />
        );
      }

    default:
      log.error(new Error('Unknown transaction transition type'), 'unknown-transition-type', {
        transitionType: currentTransition,
      });
      return '';
  }
};

const reviewByAuthorId = (transaction, userId) => {
  return transaction.reviews.filter(
    r => !r.attributes.deleted && r.author.id.uuid === userId.uuid
  )[0];
};

const Transition = props => {
  const { transition, transaction, currentUser, intl, onOpenReviewModal } = props;

  const currentTransaction = ensureTransaction(transaction);
  const customer = currentTransaction.customer;
  const provider = currentTransaction.provider;

  const deletedListing = intl.formatMessage({
    id: 'ActivityFeed.deletedListing',
  });
  const listingTitle = currentTransaction.listing.attributes.deleted
    ? deletedListing
    : currentTransaction.listing.attributes.title;
  const lastTransition = currentTransaction.attributes.lastTransition;

  const ownRole = getUserTxRole(currentUser.id, currentTransaction);

  const otherUsersName = txRoleIsProvider(ownRole) ? (
    <UserDisplayName user={customer} intl={intl} />
  ) : (
    <UserDisplayName user={provider} intl={intl} />
  );

  const transitionMessage = resolveTransitionMessage(
    transaction,
    transition,
    listingTitle,
    ownRole,
    otherUsersName,
    intl,
    onOpenReviewModal
  );
  const currentTransition = transition.transition;

  const deletedReviewContent = intl.formatMessage({ id: 'ActivityFeed.deletedReviewContent' });
  let reviewComponent = null;

  if (transitionIsReviewed(lastTransition)) {
    if (isCustomerReview(currentTransition)) {
      const review = reviewByAuthorId(currentTransaction, customer.id);
      reviewComponent = review ? (
        <Review content={review.attributes.content} rating={review.attributes.rating} />
      ) : (
        <Review content={deletedReviewContent} />
      );
    } else if (isProviderReview(currentTransition)) {
      const review = reviewByAuthorId(currentTransaction, provider.id);
      reviewComponent = review ? (
        <Review content={review.attributes.content} rating={review.attributes.rating} />
      ) : (
        <Review content={deletedReviewContent} />
      );
    }
  }

  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });

  return (
    <div className={css.transition}>
      <div className={css.bullet}>
        <p className={css.transitionContent}>•</p>
      </div>
      <div>
        <p className={css.transitionContent}>{transitionMessage}</p>
        <p className={css.transitionDate}>{formatDate(intl, todayString, transition.createdAt)}</p>
        {reviewComponent}
      </div>
    </div>
  );
};

Transition.propTypes = {
  transition: propTypes.transition.isRequired,
  transaction: propTypes.transaction.isRequired,
  currentUser: propTypes.currentUser.isRequired,
  intl: intlShape.isRequired,
  onOpenReviewModal: func.isRequired,
};

const EmptyTransition = () => {
  return (
    <div className={css.transition}>
      <div className={css.bullet}>
        <p className={css.transitionContent}>•</p>
      </div>
      <div>
        <p className={css.transitionContent} />
        <p className={css.transitionDate} />
      </div>
    </div>
  );
};

const isMessage = item => item && item.type === 'message';
const isProposal = item => item && item.proposalId;
const isFile = item => item && item.file_URL;
const isMeeting = item => item && item.meeting_URL;

// Compare function for sorting an array containing messages and transitions
const compareItems = (a, b) => {
  const itemDate = item =>
    isMessage(item)
      ? item.attributes.createdAt
      : isProposal(item)
      ? new Date(item.createdAt)
      : isFile(item)
      ? new Date(item.createdAt)
      : isMeeting(item)
      ? new Date(item.createdAt)
      : item.createdAt;
  return itemDate(a) - itemDate(b);
};

const organizedItems = (messages, files, meetings, transitions, proposals, hideOldTransitions) => {
  const items = messages
    .concat(files)
    .concat(meetings)
    .concat(transitions)
    .concat(proposals)
    .sort(compareItems);
  if (hideOldTransitions) {
    // Hide transitions that happened before the oldest message. Since
    // we have older items (messages) that we are not showing, seeing
    // old transitions would be confusing.
    return dropWhile(items, i => !isMessage(i));
  } else {
    return items;
  }
};

export const ActivityFeedComponent = props => {
  const {
    rootClassName,
    className,
    messages,
    transaction,
    currentUser,
    hasOlderMessages,
    onOpenReviewModal,
    onShowOlderMessages,
    fetchMessagesInProgress,
    intl,
    isCustomer,
    fetchFilesError,
    files = [],
    fetchMeetingsError,
    meetings = [],
    onUpdateMeeting,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const currentTransaction = ensureTransaction(transaction);
  const transitions = currentTransaction.attributes.transitions
    ? currentTransaction.attributes.transitions
    : [];
  const currentCustomer = ensureUser(currentTransaction.customer);
  const currentProvider = ensureUser(currentTransaction.provider);
  const currentListing = ensureListing(currentTransaction.listing);

  const ownRole = getUserTxRole(currentUser.id, currentTransaction);

  const transitionsAvailable = !!(
    currentUser &&
    currentUser.id &&
    currentCustomer.id &&
    currentProvider.id &&
    currentListing.id
  );

  const { proposals = {}, activeProposal } = currentTransaction.attributes.metadata;

  // combine messages and transaction transitions
  const items = organizedItems(
    messages,
    files,
    meetings,
    transitions,
    Object.values(proposals),
    hasOlderMessages || fetchMessagesInProgress
  );

  const transitionComponent = transition => {
    if (transitionsAvailable) {
      return (
        <Transition
          transition={transition}
          transaction={transaction}
          currentUser={currentUser}
          intl={intl}
          onOpenReviewModal={onOpenReviewModal}
        />
      );
    } else {
      return <EmptyTransition />;
    }
  };

  const proposalComponent = (proposal, activeProposalId) => {
    return (
      <Proposal
        proposal={proposal}
        activeProposalId={activeProposalId}
        intl={intl}
        isCustomer={isCustomer}
      />
    );
  };

  const messageComponent = message => {
    const isOwnMessage =
      message.sender &&
      message.sender.id &&
      currentUser &&
      currentUser.id &&
      message.sender.id.uuid === currentUser.id.uuid;
    if (isOwnMessage) {
      return <OwnMessage message={message} intl={intl} />;
    }
    return <Message message={message} intl={intl} />;
  };

  const messageListItem = message => {
    return (
      <li id={`msg-${message.id.uuid}`} key={message.id.uuid} className={css.messageItem}>
        {messageComponent(message)}
      </li>
    );
  };

  const proposalListItem = (proposal, activeProposalId) => {
    return (
      <li
        id={`proposal-${proposal.proposalId}`}
        key={proposal.proposalId}
        className={css.messageItem}
      >
        {proposalComponent(proposal, activeProposalId)}
      </li>
    );
  };

  const fileListItem = file => {
    const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
    const formattedDate = formatDate(intl, todayString, new Date(file.createdAt));
    const isOwnFile = currentUser?.id && file?.user_id === currentUser.id?.uuid;
    const fileComponent = isOwnFile ? (
      <OwnFile file={file} formattedDate={formattedDate} />
    ) : (
      <File file={file} formattedDate={formattedDate} />
    );

    return (
      <li id={`file-${file._id}`} key={file._id} className={css.messageItem}>
        {fileComponent}
      </li>
    );
  };

  const meetingListItem = meeting => {
    const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
    const formattedDate = formatDate(intl, todayString, new Date(meeting.createdAt));
    const isOwnMeeting = currentUser?.id && meeting?.user_id === currentUser.id?.uuid;
    const meetingComponent = (
      <Meeting
        meeting={meeting}
        formattedDate={formattedDate}
        ownMeeting={isOwnMeeting}
        onUpdateMeeting={onUpdateMeeting}
        currentTransaction={currentTransaction}
        ownRole={ownRole}
      />
    );

    return (
      <li id={`meeting-${meeting._id}`} key={meeting._id} className={css.messageItem}>
        {meetingComponent}
      </li>
    );
  };

  const transitionListItem = transition => {
    if (isRelevantPastTransition(transition.transition)) {
      return (
        <li key={transition.transition} className={css.transitionItem}>
          {transitionComponent(transition)}
        </li>
      );
    } else {
      return null;
    }
  };

  return (
    <ul className={classes}>
      {hasOlderMessages ? (
        <li className={css.showOlderWrapper} key="show-older-messages">
          <InlineTextButton className={css.showOlderButton} onClick={onShowOlderMessages}>
            <FormattedMessage id="ActivityFeed.showOlderMessages" />
          </InlineTextButton>
        </li>
      ) : null}
      {fetchFilesError ? (
        <li key="error" className={css.error}>
          {fetchFilesError}
        </li>
      ) : null}

      {fetchMeetingsError ? (
        <li key="error" className={css.error}>
          {fetchMeetingsError}
        </li>
      ) : null}

      {items.map(item => {
        if (isMessage(item)) {
          return messageListItem(item);
        } else if (isProposal(item)) {
          return proposalListItem(item, activeProposal?.proposalId);
        } else if (isFile(item)) {
          return fileListItem(item);
        } else if (isMeeting(item)) {
          return meetingListItem(item);
        } else {
          return transitionListItem(item);
        }
      })}
    </ul>
  );
};

ActivityFeedComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

ActivityFeedComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction,
  messages: arrayOf(propTypes.message),
  hasOlderMessages: bool.isRequired,
  onOpenReviewModal: func.isRequired,
  onShowOlderMessages: func.isRequired,
  fetchMessagesInProgress: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { fetchFilesError, files, fetchMeetingsError, meetings } = state.TransactionPage;

  return {
    fetchFilesError,
    files,
    fetchMeetingsError,
    meetings,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateMeeting: params => dispatch(updateMeeting(params)),
  };
};

const ActivityFeed = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ActivityFeedComponent);

export default ActivityFeed;
