import React, { useEffect, useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './TutorialChatPage.module.css';
import Tutorials from '../Tutorials/Tutorials';
import TutorialImage from '../../assets/chatTutorial.png';

const TutorialsChatPage = ({ currentUser, currentUserHasListings }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const lastOpened = window.localStorage.getItem('chatPageModalLastOpened');
    if (
      (currentUser &&
        (currentUser?.attributes?.profile?.protectedData?.provider || currentUserHasListings) &&
        !lastOpened) ||
      (lastOpened && new Date(Number(lastOpened)) + 48 * 60 * 60 * 1000 < new Date())
    ) {
      window.localStorage.setItem('chatPageModalLastOpened', new Date().getTime().toString());
      setIsOpen(true);
    }
  }, [currentUser]);

  const pages = [
    {
      title: <FormattedMessage id="TutorialsChatPage.title" />,
      body: (
        <div>
          <div>
            <p>
              <FormattedMessage id="TutorialsChatPage.body1" />
            </p>
          </div>
          <div className={css.imageWrapper}>
            <img src={TutorialImage} className={css.image} />
            <p>
              <strong>
                <FormattedMessage id="TutorialsChatPage.step1" />
              </strong>
              <br />
              <FormattedMessage id="TutorialsChatPage.step1Sub" />
            </p>
          </div>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="TutorialsChatPage.title" />,
      body: (
        <div>
          <div>
            <p>
              <FormattedMessage id="TutorialsChatPage.body1" />
            </p>
          </div>
          <div className={css.imageWrapper}>
            <img src={TutorialImage} className={css.image} />
            <p>
              <strong>
                <FormattedMessage id="TutorialsChatPage.step2" />
              </strong>
              <br />
              <FormattedMessage id="TutorialsChatPage.step2Sub" />
            </p>
          </div>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="TutorialsChatPage.title" />,
      body: (
        <div>
          <div>
            <p>
              <FormattedMessage id="TutorialsChatPage.body1" />
            </p>
          </div>
          <div className={css.imageWrapper}>
            <img src={TutorialImage} className={css.image} />
            <p>
              <strong>
                <FormattedMessage id="TutorialsChatPage.step3" />
              </strong>
              <br />
              <FormattedMessage id="TutorialsChatPage.step3Sub" />
            </p>
          </div>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="TutorialsChatPage.title" />,
      body: (
        <div>
          <div>
            <p>
              <FormattedMessage id="TutorialsChatPage.body1" />
            </p>
          </div>
          <div className={css.imageWrapper}>
            <img src={TutorialImage} className={css.image} />
            <p>
              <strong>
                <FormattedMessage id="TutorialsChatPage.step4" />
              </strong>
              <br />
              <FormattedMessage id="TutorialsChatPage.step4Sub" />
            </p>
          </div>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="TutorialsChatPage.title" />,
      body: (
        <div>
          <div>
            <p>
              <FormattedMessage id="TutorialsChatPage.body1" />
            </p>
          </div>
          <div className={css.imageWrapper}>
            <img src={TutorialImage} className={css.image} />
            <p>
              <strong>
                <FormattedMessage id="TutorialsChatPage.stepDone" />
              </strong>
              <br />
              <FormattedMessage id="TutorialsChatPage.stepDoneSub" />
            </p>
          </div>
        </div>
      ),
    },
  ];

  return (
    <Tutorials
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      id="viewWorktrippPage.tutorial.welcome"
      pages={pages}
    />
  );
};

export default TutorialsChatPage;
