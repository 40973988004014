import { Button } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingBrowseMoreButton.module.css';

const ListingBrowseMoreButton = ({}) => {
  return (
    <Button className={css.browseMorebutton} onClick={() => window.location.assign(`/s`)}>
      <FormattedMessage id="ListingPage.browseMore" />
    </Button>
  );
};

export default ListingBrowseMoreButton;
