import { FormattedMessage } from '../../util/reactIntl';

import { IoChatbubblesOutline, IoCheckmarkCircleOutline, IoHeartOutline } from 'react-icons/io5';

import css from './WorktrippBookGuide.module.css';

const ICON_SIZE = 42;

const STEPS = [
  {
    key: 'book_guide_step_1',
    formattedMessage: 'Worktripp.stepOne',
    step: 1,
    icon: <IoHeartOutline size={ICON_SIZE} />,
  },
  {
    key: 'book_guide_step_2',
    formattedMessage: 'Worktripp.stepTwo',
    step: 2,
    icon: <IoCheckmarkCircleOutline size={ICON_SIZE} />,
  },
  {
    key: 'book_guide_step_3',
    formattedMessage: 'Worktripp.stepThree',
    step: 3,
    icon: <IoChatbubblesOutline size={ICON_SIZE} />,
  },
];

const WorktrippBookGuide = ({ horizontal, minimised, intl }) => {
  const contentClass = horizontal ? css.wtBookGuideContentHorizontal : css.wtBookGuideContent;
  const stepContainerClass = minimised ? css.wtBookGuideStepMinimised : css.wtBookGuideStep;

  return (
    <div className={css.wtBookGuideContainer}>
      <p className={css.wtBookGuideHeader}>3 steps to book a great offsite</p>
      <div className={contentClass}>
        {STEPS.map(step => (
          <div key={step.key} className={stepContainerClass}>
            <div style={{ width: ICON_SIZE, height: ICON_SIZE }}>{step.icon}</div>
            <div id="guide_step">
              <span>
                <strong>Step {step.step}</strong>
              </span>
              <div>
                <FormattedMessage id={step.formattedMessage} />
              </div>
            </div>
          </div>
        ))}
      </div>
      <span className={css.needHelp}>
        Need help?
        <FormattedMessage id={'Worktripp.needHelp'} />
      </span>
    </div>
  );
};

export default WorktrippBookGuide;
