import React, { useState } from 'react';
import FieldTextInput from '../FieldTextInput/FieldTextInput';
import css from './FieldPasswordInput.module.css';

import EyeFill from './eye_fill_icon.png';
import EyeSlashFill from './eye_slash_fill_icon.png';

const FieldPasswordInput = props => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className={css.paswordWrapper}>
      <FieldTextInput {...props} type={passwordVisible ? 'text' : 'password'} />
      <div className={css.passwordVisibilityIcon} onClick={togglePasswordVisibility}>
        <img src={passwordVisible ? EyeSlashFill : EyeFill} />
      </div>
    </div>
  );
};

export default FieldPasswordInput;
