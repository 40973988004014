import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { Button, Modal } from '../..';
import { FormattedMessage } from '../../../util/reactIntl';

import * as validators from '../../../util/validators';

import { useDispatch, useSelector } from 'react-redux';
import { signup } from '../../../ducks/Auth.duck';
import { createWorktripp } from '../../../containers/WorktrippPage/Worktripp.duck';

import WorktrippOverViewForm from '../components/WorktrippOverViewForm';
import WorktrippSupportForm from '../components/WorktrippSupportForm';
import { DEFUALT_WORLD_ID } from '../../../default-location-searches';

import SignUpForm from '../components/SignUpForm';
import { IconSpinner, NamedLink } from '../../../components';
import config from '../../../config';
import routeConfiguration from '../../../routeConfiguration';
import defaultLocations from '../../../default-location-searches';
import { validFilterParams } from '../../../containers/SearchPage/SearchPage.helpers';
import { createResourceLocatorString } from '../../../util/routes';
import { filters } from '../../../marketplace-custom-config';

import css from './RecommendationPopup.module.css';

const EXPERIENCE_MAP = {
  active: ['outdoor', 'sports', 'onWater'],
  foodie: ['FnD'],
  creative: ['art', 'theatre', 'problemSolving'],
  wellbeing: ['nature', 'yoga', 'spa'],
};

const getExperiences = experienceType => {
  let experiences = [];
  experienceType.forEach(experience => {
    if (EXPERIENCE_MAP[experience]) {
      experiences = [...EXPERIENCE_MAP[experience], ...experiences];
    }
  });
  return experiences;
};

const getListings = listingId => {
  return listingId
    ? [
        {
          listing_id: listingId,
          tx_id: 'NOT CONTACTED',
        },
      ]
    : [];
};

const RecommendationPopup = ({ toggleDisplay, setToggleDisplay, listingId }) => {
  const dispatch = useDispatch();
  const { Auth: authReducer } = useSelector(state => state);
  const { currentUser } = useSelector(state => state.user);

  const { signupError, loginErrorMsg } = authReducer;
  const scrollRef = useRef(null);
  const executeScroll = () => scrollRef.current.scrollIntoView();

  const [venueVibe, setVenueVibe] = useState([]);
  const [goals, setGoals] = useState([]);
  const [workshopOrActivityRequired, setWorkshopOrActivityRequired] = useState('no');
  const [listingTypes, setListingTypes] = useState([]);
  const [levelOfExperience, setLevelOfExperience] = useState([]);
  const [experienceType, setExperienceType] = useState([]);
  const [supportAreas, setSupportAreas] = useState([]);
  const [location, setLocation] = useState([]);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const isLoggedIn = currentUser?.id?.uuid;

  const requiresVenue = useMemo(() => listingTypes.includes('venue'), [listingTypes]);

  const hideChat = isOpen => {
    const getElement = document.querySelectorAll('.crisp-client');

    getElement.forEach(element => {
      if (!isOpen) element.style.display = 'none';
      else element.style.display = 'block';
    });
  };

  useEffect(() => {
    hideChat(toggleDisplay);
  }, [toggleDisplay]);

  useEffect(() => {
    if (loginErrorMsg) {
      setErrorMessage(loginErrorMsg);
      setLoading(false);
    } else if (signupError) {
      setErrorMessage(signupError);
      setLoading(false);
    }
  }, [signupError, loginErrorMsg]);

  const redirectedTab = () => {
    let tab = 'all';
    if (requiresVenue) {
      tab = 'venue';
    } else if (workshopOrActivityRequired) {
      if (listingTypes.includes('retreat')) {
        tab = 'retreat';
      } else if (listingTypes.includes('expertFacilitator')) {
        tab = 'certifiedCoach,domainSpecialistCoach,expertFacilitator,inspiredSpeaker';
      } else if (listingTypes.includes('experiences')) {
        tab = 'experiences';
      } else if (listingTypes.includes('certifiedCoach')) {
        tab = 'certifiedCoach,domainSpecialistCoach,expertFacilitator,inspiredSpeaker';
      }
    }
    return tab;
  };

  const redirectToSearchPage = (worktripp, tab) => {
    const filterConfig = config.custom.filters;
    const originMaybe = config.sortSearchByDistance ? { origin: viewportCenter } : {};
    const routes = routeConfiguration();
    const { location, venueVibe, goals, experiences, minBudget, budgetPerPerson, ...rest } =
      worktripp || {};

    let mappedGoals = [];

    const goalOptions = filters.find(filter => filter.id == 'goals')?.config.options;

    goals?.forEach(goal => {
      const filteredOptionsForSeletedGoal =
        goalOptions.filter(option => option.parent == goal) || [];
      filteredOptionsForSeletedGoal?.forEach(option => mappedGoals.push(option.key));
    });

    const pub_venueVibe = venueVibe || [];
    const pub_specialism = mappedGoals;
    const pub_listingSector = experiences || [];
    const { address, bounds } =
      defaultLocations.find(defaultLocation => defaultLocation.id == location)?.predictionPlace ||
      {};
    const priceValue = minBudget || budgetPerPerson?.amount / 100;
    const price = priceValue ? `0,${priceValue}` : '';

    const searchParams = {
      address,
      ...originMaybe,
      bounds: bounds,
      mapSearch: true,
      ...validFilterParams(
        { pub_venueVibe, pub_specialism, pub_listingSector, price },
        filterConfig
      ),
    };
    const redirectUrl = createResourceLocatorString(
      'SearchPage',
      routes,
      { slug: tab },
      searchParams
    );

    setTimeout(() => {
      window.open(redirectUrl, '_top');
    }, 2500);
  };

  const pages = [
    {
      title: "Let's start with the basics",
      body: (
        <WorktrippOverViewForm
          validators={validators}
          css={css}
          goals={goals}
          setGoals={setGoals}
          location={location}
          setLocation={setLocation}
        />
      ),
    },
    {
      title: 'How can we help?',
      body: (
        <WorktrippSupportForm
          validators={validators}
          css={css}
          listingTypes={listingTypes}
          setListingTypes={setListingTypes}
          venueVibe={venueVibe}
          setVenueVibe={setVenueVibe}
          supportAreas={supportAreas}
          setSupportAreas={setSupportAreas}
        />
      ),
    },
    // {
    //   title: 'Workshops & Activities',
    //   body: (
    //     <WorkshopRequirementsForm
    //       css={css}
    //       workshopOrActivityRequired={workshopOrActivityRequired}
    //       setWorkshopOrActivityRequired={setWorkshopOrActivityRequired}
    //       listingTypes={listingTypes}
    //       setListingTypes={setListingTypes}
    //       levelOfExperience={levelOfExperience}
    //       setLevelOfExperience={setLevelOfExperience}
    //       experienceType={experienceType}
    //       setExperienceType={setExperienceType}
    //     />
    //   ),
    // },
  ];

  if (!isLoggedIn) {
    pages.push({
      title: (
        <span>
          Do you want to save these <br />
          search filters for later?
        </span>
      ),
      body: (
        <SignUpForm
          validators={validators}
          css={css}
          subTitle="Shorten your time to enquiries by signing up to your free account now."
        />
      ),
    });
  }

  const [currentPage, setCurrentPage] = useState(1);

  const nextPage = () => {
    executeScroll();
    setCurrentPage(prevPage => Math.min(prevPage + 1, pages.length));
  };

  const previousPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const onSubmit = async values => {
    setErrorMessage(null);

    if (loading) {
      return;
    }

    const email = values.email ?? (isLoggedIn ? currentUser?.attributes?.email : 'no email');
    // Note: for now we are only able to look-up one location
    const singularLocation = location?.length === 1 ? location[0] : DEFUALT_WORLD_ID;

    try {
      setLoading(true);
      let worktripp = {
        ...values,
        venueVibe,
        goals,
        listingTypes,
        levelOfExperience,
        experiences: getExperiences(experienceType),
        location: singularLocation,
        supportAreas,
        startDate: new Date(values.wtRange.startDate),
        endDate: new Date(values.wtRange.endDate),
        email: email,
        listings: getListings(listingId),
      };
      delete worktripp.wtRange;

      await dispatch(createWorktripp(worktripp));

      if (!isLoggedIn) {
        const { email, password, firstName, lastName, organisationName, jobtitle } = values;

        dispatch(
          signup(
            {
              email,
              password,
              firstName,
              lastName,
              organisationName,
              jobtitle,
              interestedIn: 'Booking a WorkTripp',
            },
            true,
            'openSpecificTab'
          )
        );
      }

      redirectToSearchPage(worktripp, redirectedTab());
    } catch (err) {
      setLoading(false);
      setErrorMessage(err?.message ? err.message : 'Failed to process the request');
      console.log(err);
    }
  };

  const checkDisabled = (values, selectedPage) => {
    if (loading) return true;
    const {
      noOfParticipants,
      wtRange,
      budgetPerPerson,
      firstName,
      lastName,
      email,
      password,
      organisationName,
      jobtitle,
      title,
    } = values;
    const { startDate, endDate } = wtRange || {};
    const pageToCheck = selectedPage || currentPage;

    switch (pageToCheck) {
      case 1:
        return !(
          title &&
          noOfParticipants &&
          startDate &&
          endDate &&
          budgetPerPerson &&
          goals.length > 0 &&
          location?.length > 0
        );
      case 2:
        return listingTypes.length === 0 || (requiresVenue && venueVibe.length === 0);
      case 3:
        return !(firstName && lastName && email && password && organisationName && jobtitle);
      default:
        return true;
    }
  };

  const formModalPage = () => {
    const { title, body } = pages[currentPage - 1] || {};

    const renderDots = (form, values) => {
      if (pages.length <= 1) {
        return <div />;
      }
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {pages.map((page, pageIndex) => {
            let canProceed = true;
            for (var i = 0; i < pageIndex; i++) {
              const notAllValuesProvided = checkDisabled(values, i + 1);
              if (notAllValuesProvided) canProceed = false;
            }
            return (
              <span
                className={classNames(
                  css.dot,
                  pageIndex + 1 == currentPage && css.selected,
                  !canProceed && css.dotDisabled
                )}
                key={pageIndex + 1}
                onClick={() => {
                  if (canProceed) {
                    setCurrentPage(pageIndex + 1);
                    executeScroll();
                    form.change('page', pageIndex + 1);
                  }
                }}
              ></span>
            );
          })}
        </div>
      );
    };

    const renderNavButtons = (form, values) => {
      const hasLeftButton = currentPage !== 1;
      const hasNextButton = currentPage < pages.length;

      const onPrevButton = () => {
        previousPage();
        form.change('page', currentPage - 1);
      };

      const onNextButton = () => {
        nextPage();
        form.change('page', currentPage + 1);
      };

      return (
        <div className={classNames(css.navigationContainer)}>
          <div className={css.emptyButtonType}>
            {hasLeftButton && (
              <Button type="button" onClick={onPrevButton} className={css.buttonType}>
                <FormattedMessage id="RecommendationPopup.previous" />
              </Button>
            )}
          </div>

          <div className={css.dotsAndLinkContainer}>
            {renderDots(form, values)}
            {!isLoggedIn && (
              <NamedLink name="LoginPage" className={css.loginLink}>
                <FormattedMessage id="RecommendationPopup.login" />
              </NamedLink>
            )}
          </div>

          {hasNextButton && (
            <Button
              type="button"
              onClick={onNextButton}
              disabled={checkDisabled(values)}
              className={css.buttonType}
            >
              <FormattedMessage id="RecommendationPopup.next" />
            </Button>
          )}

          {!hasNextButton && (
            <Button type="submit" disabled={checkDisabled(values)} className={css.buttonType}>
              <FormattedMessage
                id={isLoggedIn ? 'RecommendationPopup.submit' : 'RecommendationPopup.signup'}
              />
            </Button>
          )}
        </div>
      );
    };

    return (
      <FinalForm
        onSubmit={onSubmit}
        render={({ handleSubmit, form, values }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <h1 style={{ textAlign: 'center', fontSize: '30px', marginTop: '24px' }}>{title}</h1>
              <hr style={{ borderBottom: '1px solid #bbb' }}></hr>
            </div>
            <div className={css.formBody}>
              <div ref={scrollRef}></div>
              <div key={currentPage - 1}>{body}</div>
            </div>
            {errorMessage && (
              <div style={{ color: 'red', marginTop: '24px', marginBottom: '24px' }}>
                {errorMessage}
              </div>
            )}
            <div className={css.setBottomFlex}>{renderNavButtons(form, values)}</div>
          </form>
        )}
      />
    );
  };

  return (
    <Modal
      id="RecommendationPopup"
      isOpen={toggleDisplay}
      onClose={() => setToggleDisplay(false)}
      onManageDisableScrolling={() => {}}
      closeButtonMessage="hidden"
      containerClassName={css.container}
      usePortal
    >
      {loading && (
        <div className={css.loadingOverlayStyle}>
          <IconSpinner />
        </div>
      )}
      {formModalPage()}
    </Modal>
  );
};

export default RecommendationPopup;
