import React from 'react';
import classNames from 'classnames';
import { ExternalLink } from '../../components';

import css from './EducationalResourceLink.module.css';

const EducationalResourceLink = ({ userRole, text, customerLink, providerLink }) => {
  const isCustomer = userRole === 'customer';
  const isProvider = userRole === 'provider';

  const href = isCustomer ? customerLink : isProvider ? providerLink : null;

  if (!href) {
    return null;
  }

  return (
    <p>
      <ExternalLink className={css.externalResourceLink} href={href}>
        {text}
      </ExternalLink>
    </p>
  );
};

export default EducationalResourceLink;
