import React, { useMemo } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { LINE_ITEM_CUSTOMER_COMMISSION, LINE_ITEM_PROVIDER_COMMISSION } from '../../util/types';

import css from './BookingBreakdown.module.css';

const withCommissionLineItem = (lineItems, isCustomer, isProvider) => {
  return lineItems?.find(item => {
    const hasCustomerCommission = isCustomer && item.code === LINE_ITEM_CUSTOMER_COMMISSION;
    const hasProviderCommission = isProvider && item.code === LINE_ITEM_PROVIDER_COMMISSION;
    return (hasCustomerCommission || hasProviderCommission) && !item.reversal;
  });
};

const LineItemCommissionNotes = ({ lineItems, userRole }) => {
  const isCustomer = userRole === 'customer';
  const isProvider = userRole === 'provider';

  const hasCommissionLineItem = useMemo(
    () => withCommissionLineItem(lineItems, isCustomer, isProvider),
    [lineItems, userRole]
  );
  const addressType = isCustomer ? 'payment billing' : 'listing';
  console.lo;

  if (!hasCommissionLineItem) {
    return <div />;
  }

  return (
    <span className={css.feeInfo}>
      <FormattedMessage id="BookingBreakdown.commissionFeeNote" />
      <br />
      <br />
      <FormattedMessage id="BookingBreakdown.commissionVatFeeNote" values={{ addressType }} />
    </span>
  );
};

export default LineItemCommissionNotes;
