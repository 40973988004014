import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_PROVIDER_COMMISSION, WORKTRIPP_COMISSIONS, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const { Money } = sdkTypes;

// Validate the assumption that the commission exists and the amount
// is zero or negative.
const isValidCommission = commissionLineItem => {
  return commissionLineItem.lineTotal instanceof Money && commissionLineItem.lineTotal.amount <= 0;
};
// if (!isValidCommission(providerCommissionLineItem)) {
//      // eslint-disable-next-line no-console
//      console.error('invalid commission line item:', providerCommissionLineItem);
//      throw new Error('Commission should be present and the value should be zero or negative');
//    }

const LineItemProviderCommissionMaybe = ({ lineItems, isProvider, calcValue }) => {
  const providerCommissionLineItems = lineItems.filter(
    item => item.code === LINE_ITEM_PROVIDER_COMMISSION && !item.reversal
  );

  if (!isProvider || !providerCommissionLineItems) {
    return null;
  }

  return (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage
          id="BookingBreakdown.providerCommission"
          values={{ percentage: WORKTRIPP_COMISSIONS.WORKTRIPP_PROVIDER_COMISSION }}
        />
      </span>
      <span className={css.itemValue}>{calcValue(providerCommissionLineItems)}</span>
    </div>
  );
};

LineItemProviderCommissionMaybe.propTypes = {
  // lineItems: propTypes.lineItems.isRequired,
  // isProvider: bool.isRequired,
};

export default LineItemProviderCommissionMaybe;
