import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { Form, LocationAutocompleteInput, PrimaryButton } from '../../components';
import IconHourGlass from '../../components/LocationAutocompleteInput/IconHourGlass';
import css from './TopbarSearchForm.module.css';

const identity = v => v;

class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onKeywordsChange = this.onKeywordsChange.bind(this);
    this.searchInput = null;

    this.state = {
      keywords: this.props.initialValues.keywords || '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.initialValues?.keywords !== this.props?.initialValues?.keywords) {
      this.setState({
        keywords: this.props.initialValues.keywords,
      });
    }
  }

  onChange(location) {
    if (location.selectedPlace) {
      // Note that we use `onSubmit` instead of the conventional
      // `handleSubmit` prop for submitting. We want to autosubmit
      // when a place is selected, and don't require any extra
      // validations for the form.
      this.props.onSubmit({ location }, 'location');
      // blur search input to hide software keyboard
      if (this.searchInput) {
        this.searchInput.blur();
      }
    }
  }

  onKeywordsChange(keywords) {
    this.setState({ keywords });
    this.props.onSubmit({ keywords }, 'keywords');
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const { rootClassName, className, desktopInputRoot, intl, isMobile } = formRenderProps;

          const classes = classNames(rootClassName, className);
          const desktopInputRootClass = desktopInputRoot || css.desktopInputRoot;

          // Allow form submit only when the place has changed
          const preventFormSubmit = e => e.preventDefault();

          return (
            <Form
              className={classes}
              onSubmit={preventFormSubmit}
              enforcePagePreloadFor="SearchPage"
            >
              <Field
                name="location"
                format={identity}
                render={({ input, meta }) => {
                  const { onChange, ...restInput } = input;

                  // Merge the standard onChange function with custom behaviur. A better solution would
                  // be to use the FormSpy component from Final Form and pass this.onChange to the
                  // onChange prop but that breaks due to insufficient subscription handling.
                  // See: https://github.com/final-form/react-final-form/issues/159
                  const searchOnChange = value => {
                    onChange(value);
                    this.onChange(value);
                  };

                  const searchInput = { ...restInput, onChange: searchOnChange };
                  return (
                    <div className={css.topbarFiltersContainer}>
                      <LocationAutocompleteInput
                        className={isMobile ? css.mobileInputRoot : desktopInputRootClass}
                        iconClassName={isMobile ? css.mobileIcon : css.desktopIcon}
                        inputClassName={isMobile ? css.mobileInput : css.desktopInput}
                        predictionsClassName={
                          isMobile ? css.mobilePredictions : css.desktopPredictions
                        }
                        predictionsAttributionClassName={
                          isMobile ? css.mobilePredictionsAttribution : null
                        }
                        placeholder={intl.formatMessage({ id: 'TopbarSearchForm.placeholder' })}
                        closeOnBlur={!isMobile}
                        inputRef={node => {
                          this.searchInput = node;
                        }}
                        input={searchInput}
                        meta={meta}
                      />

                      {isMobile ? (
                        <div
                          className={classNames(css.mobileInputRoot, css.desktopDisplayNone)}
                          style={{
                            marginLeft: '24px',
                            paddingRight: '50px',
                          }}
                        >
                          <IconHourGlass />
                          <input
                            style={{ marginLeft: '12px' }}
                            type="text"
                            placeholder={intl.formatMessage({ id: 'KeywordFilter.placeholder' })}
                            value={this.state.keywords}
                            onChange={e => this.setState({ keywords: e.target.value })}
                          />
                          <PrimaryButton
                            onClick={() => this.onKeywordsChange(this.state.keywords)}
                            disabled={!this.state.keywords}
                            style={{
                              width: '80px',
                              height: '40px',
                              minHeight: '40px',
                              padding: '0px',
                              marginLeft: '12px',
                            }}
                          >
                            Search
                          </PrimaryButton>
                        </div>
                      ) : (
                        <div className={css.borderAlignWrapper}>
                          <div className={css.borderAlign}>
                            <IconHourGlass />
                            <input
                              className={isMobile ? css.desktopDisplayNone : desktopInputRootClass}
                              style={{
                                borderBottom: 'none',
                              }}
                              type="text"
                              autoFocus
                              placeholder={intl.formatMessage({ id: 'KeywordFilter.placeholder' })}
                              value={this.state.keywords}
                              onChange={e => this.onKeywordsChange(e.target.value)}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  );
                }}
              />
            </Form>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarSearchForm = injectIntl(TopbarSearchFormComponent);

export default TopbarSearchForm;
