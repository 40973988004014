import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import QueryForm from './QueryForm';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import css from './SectionHero.module.css';
import { types as sdkTypes } from '../../util/sdkLoader';

const SectionHero = props => {
  const [mounted, setMounted] = useState(false);
  const [dates, setDates] = useState('');
  const { rootClassName, className, history } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const { LatLng, LatLngBounds } = sdkTypes;

  const classes = classNames(rootClassName || css.root, className);

  const handleOnSubmitQueryForm = (values, specialismOptions) => {
    const { location, pub_specialism, ...rest } = values || {};
    let searchParams = { ...rest, sort: 'pub_priority' };
    // searchParams.bounds = new LatLngBounds(new LatLng(51.992387, 0.4691252), new LatLng(50.9576977, -0.73519817))

    if (location) {
      searchParams = {
        ...searchParams,
        bounds: location.selectedPlace.bounds,
        address: location.search,
      };
    }
    if (pub_specialism) {
      const values = specialismOptions
        .filter(sp => sp.parent === pub_specialism)
        .map(e => e.key)
        .join(',');
      searchParams = {
        ...searchParams,
        pub_specialism: `has_any:${values}`,
      };
    }

    if (dates) {
      searchParams = {
        ...searchParams,
        ...dates,
      };
    }

    const routes = routeConfiguration();
    const base_url = window.location.origin;
    const searchURL = `${base_url}${createResourceLocatorString(
      'SearchPage',
      routes,
      { slug: 'all' },
      searchParams
    )}`;
    window.open(searchURL, '_parent');
    // history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
  };

  const handleOnChangeQueryForm = e => {};

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h2 className={css.heroMainAboveText}>
          <FormattedMessage id="SectionHero.AboveTitleText" />{' '}
          <span className={css.betaTag}>Beta</span>
        </h2>
        <h1 className={classNames(css.heroMainTitle, { [css.heroMainTitleFEDelay]: mounted })}>
          <FormattedMessage id="SectionHero.title" />
        </h1>
        <h2 className={classNames(css.heroSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
          <FormattedMessage id="SectionHero.subTitlet" />
        </h2>
        <QueryForm
          mounted={mounted}
          onSubmit={handleOnSubmitQueryForm}
          onChange={handleOnChangeQueryForm}
          dates={dates}
          setDates={setDates}
        />
        <h2
          onClick={() => {
            window.open('/s', '_parent');
          }}
          className={classNames(css.browseOurListing, { [css.heroSubTitleFEDelay]: mounted })}
        >
          <FormattedMessage id="SectionHero.setYourGoals" />
        </h2>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
