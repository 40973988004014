import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_CUSTOMER_COMMISSION, WORKTRIPP_COMISSIONS, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const { Money } = sdkTypes;

// Validate the assumption that the commission exists and the amount
// is zero or positive.
const isValidCommission = commissionLineItem => {
  return (
    commissionLineItem &&
    commissionLineItem.lineTotal instanceof Money &&
    commissionLineItem.lineTotal.amount >= 0
  );
};
// TODO: vald VAT line items
// if (isValidCommission(customerCommissionLineItems)) {
//   // eslint-disable-next-line no-console
//   throw new Error('Commission should be present and the value should be zero or positive');
// }

const LineItemCustomerCommissionMaybe = ({ lineItems, isCustomer, calcValue }) => {
  const customerCommissionLineItems = lineItems?.filter(
    item => item.code === LINE_ITEM_CUSTOMER_COMMISSION && !item.reversal
  );

  if (!isCustomer || !customerCommissionLineItems) {
    return null;
  }

  return (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage
          id="BookingBreakdown.customerCommission"
          values={{ percentage: WORKTRIPP_COMISSIONS.WORKTRIPP_CUSTOMER_COMISSION }}
        />
      </span>
      <span className={css.itemValue}>{calcValue(customerCommissionLineItems)}</span>
    </div>
  );
};

LineItemCustomerCommissionMaybe.propTypes = {
  // lineItems: propTypes.lineItems.isRequired,
  // isCustomer: bool.isRequired,
};

export default LineItemCustomerCommissionMaybe;
