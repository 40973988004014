import React from 'react';
import { PrimaryButton } from '../Button/Button';
import Page from '../Page/Page';
import { TopbarContainer } from '../../containers';
import css from './NoAccess.module.css';

const NoAccess = props => {
  return (
    <Page title={'No Access'}>
      <TopbarContainer />
      <div className={css.mainContainer}>
        <h3>No Access</h3>
        <div>Sorry, You don't have permission to access this page!</div>
        <br />
        <PrimaryButton onClick={() => (window.location.href = '/s')}>
          Back to main page
        </PrimaryButton>
      </div>
    </Page>
  );
};

export default NoAccess;
