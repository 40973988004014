import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Button,
  FieldCheckboxGroup,
  FieldTextInput,
  FieldSelect,
  Form,
  Modal,
} from '../../components';
import css from './EditListingFeaturesForm.module.css';
import dropDown from '../../assets/icons/Dropdown.svg';
import dropUp from '../../assets/icons/Dropup.svg';
import { thingsToDoList } from '../../util/dataStore';

const EditListingFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        form,
        values,
        isVE,
        isVenue,
        isRetreat,
        toDo,
        setToDo,
        listingSectorOptions,
      } = formRenderProps;

      const mainCategory = isVE ? 'facilities' : 'specialism';
      const mainFilter = isVE ? 'facilities' : 'goals';

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      const listingSectorMessage = (
        <FormattedMessage id="EditListingDescriptionForm.listingSectorMessage" />
      );

      const groupBy = (arr, property) => {
        return arr.reduce((acc, cur) => {
          acc[cur[property]] = [...(acc[cur[property]] || []), cur];
          return acc;
        }, {});
      };

      const options = !isVE || isVenue ? findOptionsForSelectFilter(mainFilter, filterConfig) : [];
      const labelsArray = options
        .filter(option => option.parent == 'Filter Label')
        .map(l => l.label);
      const collections = groupBy(options, 'parent');
      const renderArray = Object.keys(collections)
        .map(k => {
          if (k !== 'Filter Label') {
            return collections[k];
          }
        })
        .filter(v => v);
      const arrayOfNonCheckboxes = renderArray.flat().filter(i => i.extraInputType);

      const [openTab, setOpenTab] = useState();
      function selectedOptions(groupOptions) {
        if (!values) {
          return null;
        }
        var holder = [];

        groupOptions.map(go => {
          if (values[go.key] || values[mainCategory]?.includes(go.key)) {
            return holder.push(go.label);
          } else {
            return null;
          }
        });
        return holder.join(', ');
      }

      const [isOpen, setIsOpen] = useState(false);
      const [toDoCat, setToDoCat] = useState('');
      const [toDoDesc, setToDoDesc] = useState('');

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          {isVE && (
            <>
              <br />
              <FieldTextInput
                id="bestFor"
                name="bestFor"
                type="text"
                label="Best for:"
                placeholder="Teams of 5 - 30, who want a natural retreat!"
              />
            </>
          )}
          <div style={{ margin: '20px 0' }}></div>

          {isRetreat ? (
            <div>
              <h2>Retreat Programme</h2>
              <FieldTextInput
                id="retreatProgramme"
                name="retreatProgramme"
                type="textarea"
                label={
                  <div style={{ lineHeight: '28px' }}>
                    Give a brief overview of the programme: eg number of days, number of sessions,
                    facilitator/coach cred, experiences included, catering included..
                  </div>
                }
                placeholder=""
              />
              <div style={{ margin: '20px 0' }}></div>
            </div>
          ) : null}

          {isVenue && isVE ? <h2>Facilities</h2> : null}
          {!isVE ? <h2>Specialism</h2> : null}

          {renderArray.map((item, i) => {
            const checkboxItems = item.filter(i => !i.extraInputType);
            const nonCheckboxItems = item.filter(i => i.extraInputType);
            return (
              <>
                <div
                  key={i}
                  className={css.image}
                  onClick={() => {
                    openTab == i ? setOpenTab(null) : setOpenTab(i);
                  }}
                >
                  <img className={css.dropDownUpImage} src={openTab == i ? dropUp : dropDown} />
                </div>
                <div className={css.wrapper}>
                  <div className={css.collapsible}>
                    <label
                      onClick={() => {
                        openTab == i ? setOpenTab(null) : setOpenTab(i);
                      }}
                      className={css.labels}
                    >
                      {labelsArray[i]}
                    </label>
                    {openTab !== i ? (
                      <div>{selectedOptions(item)}</div>
                    ) : (
                      <div className={css.collapsibleText}>
                        <FieldCheckboxGroup
                          className={css.features}
                          id={mainCategory}
                          name={mainCategory}
                          options={checkboxItems}
                        />
                        {nonCheckboxItems.map(ni => {
                          if (ni.extraInputType == 'select') {
                            return (
                              <>
                                <div>{ni.label}</div>
                                <FieldSelect
                                  id={ni.key}
                                  name={ni.key}
                                  className={css.subInput}
                                  style={{ marginBottom: '20px' }}
                                >
                                  <option value="">Please select ...</option>
                                  {ni.selectOptions.map((op, i) => {
                                    return (
                                      <option key={i} value={op.label}>
                                        {op.label}
                                      </option>
                                    );
                                  })}
                                </FieldSelect>
                              </>
                            );
                          } else {
                            return (
                              <FieldTextInput
                                id={ni.key}
                                name={ni.key}
                                className={css.subInput}
                                type={ni.extraInputType}
                                label={ni.label}
                                placeholder={
                                  ni.extraInputType == 'text'
                                    ? 'Enter short description'
                                    : 'Enter value ...'
                                }
                              />
                            );
                          }
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </>
            );
          })}

          {!isVE || isVenue ? (
            <div
              className={css.clearAllSelection}
              onClick={() => {
                form.change(mainCategory, '');
                arrayOfNonCheckboxes.map(ni => {
                  form.change(ni.key, '');
                });
              }}
            >
              Clear all selection
            </div>
          ) : null}

          {!isVE && (
            <FieldCheckboxGroup
              className={css.specialismFeatures}
              id="listingSector"
              name="listingSector"
              label={listingSectorMessage}
              options={listingSectorOptions}
            />
          )}

          {isVE && !isRetreat && (
            <>
              <br />
              <h2>Things to do</h2>
              {toDo?.map(td => {
                return (
                  <p key={td.category} style={{ margin: '0 0 10px 0' }}>
                    <span
                      className={css.removeToDo}
                      onClick={() => {
                        setToDo(
                          toDo.filter(t => t.category !== td.category),
                          values
                        );
                      }}
                    >
                      X
                    </span>
                    <img
                      style={{ marginRight: '10px' }}
                      src={thingsToDoList.find(th => td.category == th.name)?.icon}
                    />
                    {td.description}
                  </p>
                );
              })}
              <Button
                type="button"
                className={css.addOneButton}
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                Add more
              </Button>
            </>
          )}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>

          <Modal
            id="EditListingFeaturesForm.editToDo"
            isOpen={isOpen}
            onClose={() => {
              setIsOpen(false);
            }}
            usePortal
            onManageDisableScrolling={() => {}}
          >
            <label>
              Category
              <select name="cat" value={toDoCat} onChange={e => setToDoCat(e.target.value)}>
                <option value="">Tell customers about things to do in your local area</option>
                {thingsToDoList.map(thing => {
                  if (toDo?.map(t => t.category).includes(thing.name)) {
                    return null;
                  }
                  return (
                    <option key={thing.name} value={thing.name}>
                      {thing.name}
                    </option>
                  );
                })}
              </select>
            </label>
            <br />
            <label>
              Description
              <input
                type="text"
                name="desc"
                value={toDoDesc}
                onChange={e => setToDoDesc(e.target.value)}
              ></input>
            </label>

            <Button
              type="button"
              className={css.addOneButton}
              disabled={!toDoCat || !toDoDesc}
              onClick={() => {
                setToDo([...toDo, { category: toDoCat, description: toDoDesc }], values);
                setToDoCat('');
                setToDoDesc('');
                setIsOpen(false);
              }}
            >
              Save
            </Button>
          </Modal>
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default EditListingFeaturesForm;
