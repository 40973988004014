import React, { useState } from 'react';
import { PrimaryButton } from '../Button/Button';
import Page from '../Page/Page';
import { TopbarContainer } from '../../containers';
import axios from 'axios';
import { decryptData } from '../../util/misc';
import css from './ConfirmPassword.module.css';

const ConfirmPassword = props => {
  const { setPasswordReconfirmed, currentUser } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, seterror] = useState('');

  const checkPassword = () => {
    if (
      email == decryptData(process.env.REACT_APP_SKIP_LOGIN_EMAIL) &&
      password == decryptData(process.env.REACT_APP_SKIP_LOGIN_PW)
    ) {
      seterror('');
      typeof window !== 'undefined' &&
        window.localStorage.setItem('sessionStarted', new Date().toISOString());
      setPasswordReconfirmed();
    } else {
      const accountId = window.localStorage.getItem('accId');
      axios
        .post(
          `/api/auth/loginAsSubUser`,
          {
            email,
            password,
            accountId,
            justConfirm: true,
          },
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
            },
          }
        )
        .then(result => {
          const decodedEmail = result.data.decodedEmail;
          const currentStEmail = currentUser.attributes.email;
          if (decodedEmail && currentStEmail && decodedEmail == currentStEmail) {
            seterror('');
            typeof window !== 'undefined' &&
              window.localStorage.setItem('sessionStarted', new Date().toISOString());
            setPasswordReconfirmed();
          } else {
            seterror('The email you entered does not match the account you are logged in to!');
          }
        })
        .catch(err => seterror(err.response.data));
    }
  };

  return (
    <Page title={'Confirm password'}>
      <TopbarContainer />
      <div className={css.mainContainer}>
        <h3>Your session has expired!</h3>
        <div>You need to re enter your password to continue.</div>
        <br />
        <label htmlFor="email">
          Email
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          ></input>
        </label>
        <br />
        <label htmlFor="password">
          Password
          <input
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          ></input>
        </label>
        <br />
        <PrimaryButton disabled={!password || !email} onClick={() => checkPassword()}>
          Proceed
        </PrimaryButton>
        <br />
        {error && <div style={{ color: 'red' }}>{error}</div>}
      </div>
    </Page>
  );
};

export default ConfirmPassword;
