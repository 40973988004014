import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getParticipants,
  addParticipant,
  deleteParticipant,
  changeParticipant,
} from '../../containers/WorktrippPage/Worktripp.duck';
import { loadSubUser } from '../../containers/SubUsers/SubUsersPage.duck';
import { PrimaryButton, Button, Modal, CustomSpinner } from '../../components';
import { decryptData } from '../../util/misc';
import checkedIcon from '../../assets/icons/squareCheckRegular.png';
import uncheckedIcon from '../../assets/icons/squareUncheckedRegular.png';
import css from './WorktrippParticipants.module.css';
import PeopleIcon from './peopleIcon.svg';

const WorktrippParticipants = props => {
  const { worktrippId, isViewOnly } = props;
  const worktripp_id = worktrippId;
  const dispatch = useDispatch();
  const { participants } = useSelector(state => state.Worktripp);
  const { subUsers } = useSelector(state => state.SubUsersPage);

  const [loading, setLoading] = useState(false);
  const [manageModalOpen, setManageModalOpen] = useState(false);

  const storedAccId = typeof window !== 'undefined' && window.localStorage.getItem('accId');
  const parentAccId = storedAccId ? decryptData(storedAccId) : '';

  const usersOnly = subUsers.filter(su => su.permissionLevel == 'User');

  const onAddParticipant = subUser_id => {
    dispatch(
      addParticipant({
        worktripp_id,
        subUser_id,
      })
    );
  };

  const onDeleteParticipant = subUser_id => {
    dispatch(
      deleteParticipant({
        worktripp_id,
        subUser_id,
      })
    );
  };

  const onChangeParticipant = (isCheck, subUsers_ids) => {
    dispatch(
      changeParticipant({
        worktripp_id,
        isCheck,
        subUsers_ids,
      })
    );
  };

  useEffect(() => {
    if (worktripp_id) {
      dispatch(getParticipants(worktripp_id));
    }
  }, [worktripp_id]);

  useEffect(() => {
    if (parentAccId) {
      dispatch(loadSubUser(parentAccId));
    }
  }, [parentAccId]);

  useEffect(() => {
    setLoading(false);
  }, [participants]);

  return (
    <>
      <div>
        <div className={css.topInfoWrapper}>
          {!isViewOnly ? (
            <div className={css.infoSectionWrapper}>
              <img src={PeopleIcon} className={css.supportIcon} />
              <div className={css.infoWrapper}>
                <span className={css.totalBudgetText}>Participants</span>
                <span>Total participants: {participants.length}</span>
              </div>
              <button
                onClick={() => setManageModalOpen(true)}
                disabled={isViewOnly}
                className={css.manageParticipantsButton}
              >
                Manage participants
              </button>
            </div>
          ) : null}
        </div>
      </div>

      {loading ? <CustomSpinner /> : null}

      <Modal
        id="ViewWorktripp.ManageParticipants"
        isOpen={manageModalOpen}
        onClose={() => {
          setManageModalOpen(false);
        }}
        usePortal
        onManageDisableScrolling={() => {}}
      >
        <table className={css.wpTable}>
          <tbody>
            <tr>
              <th>First Name</th>
              <th>Email</th>
              <th>Checked</th>
            </tr>

            {usersOnly.map((su, i) => {
              const { firstName, email, _id } = su;
              const isAdded = participants.includes(_id);
              return (
                <tr key={i}>
                  <td>{firstName}</td>
                  <td>{email}</td>
                  <td>
                    <img
                      src={isAdded ? checkedIcon : uncheckedIcon}
                      className={css.checkImg}
                      onClick={() => {
                        setLoading(true);
                        if (!isAdded) {
                          onAddParticipant(_id);
                        } else {
                          onDeleteParticipant(_id);
                        }
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className={css.modalButton}>
          <Button
            disabled={participants.length >= usersOnly.length}
            onClick={() => {
              setLoading(true);
              onChangeParticipant(
                true,
                usersOnly.map(u => u._id)
              );
            }}
          >
            Select all
          </Button>

          <Button
            disabled={participants.length == 0}
            onClick={() => {
              setLoading(true);
              onChangeParticipant(
                false,
                usersOnly.map(u => u._id)
              );
            }}
          >
            Remove all
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default WorktrippParticipants;
