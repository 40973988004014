import { format } from 'date-fns';
import { ensureTransaction } from './data';
import { isBalancePaymentDue, getBalanceDueDate } from './misc';

/**
 * Transitions
 *
 * These strings must sync with values defined in Marketplace API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

export const transitions = {
  // A customer can also initiate a transaction with an inquiry, and
  // then transition that with a request.
  INQUIRE: 'transition/inquire',

  EXPIRE_INQUIRY: 'transition/expire-inquiry',
  CUSTOMER_DECLINE_INQUIRY: 'transition/customer-decline-inquiry',
  PROVIDER_DECLINE_INQUIRY: 'transition/provider-decline-inquiry',

  SEND_QUOTE: 'transition/send-quote',
  UPDATE_QUOTE: 'transition/update-quote',
  EXPIRE_QUOTE: 'transition/expire-quote',
  CUSTOMER_DECLINE_QUOTE: 'transition/customer-decline-quote',
  PROVIDER_DECLINE_QUOTE: 'transition/provider-decline-quote',

  CREATE_BOOKING: 'transition/create-booking',

  PENDING_DEPOSIT_VAT: 'transition/pending-deposit-vat',
  UPDATE_DEPOSIT: 'transition/update-deposit',
  CONFIRM_DEPOSIT: 'transition/confirm-deposit',
  EXPIRE_DEPOSIT: 'transition/expire-deposit',
  COMPLETE_DEPOSIT: 'transition/complete-deposit',
  OPERATOR_COMPLETE_DEPOSIT: 'transition/operator-complete-deposit',
  CANCEL_DEPOSIT: 'transition/cancel-deposit',

  PENDING_PAYMENT_VAT: 'transition/pending-payment-vat',
  UPDATE_PAYMENT: 'transition/update-payment',
  // Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
  // Therefore we need to make another transition to Marketplace API,
  // to tell that the payment is confirmed.
  CONFIRM_PAYMENT: 'transition/confirm-payment',
  EXPIRE_PAYMENT: 'transition/expire-payment',
  CANCEL: 'transition/cancel',
  COMPLETE: 'transition/complete',
  OPERATOR_COMPLETE: 'transition/operator-complete',
  CANCEL_BOOKED: 'transition/cancel-booked',

  INITIATE_ADDITIONAL_PAYMENT: 'transition/initiate-additional-payment',
  PENDING_ADDITIONAL_PAYMENT_VAT: 'transition/pending-additional-payment-vat',
  UPDATE_ADDITIONAL_PAYMENT: 'transition/update-additional-payment',
  CONFIRM_ADDITIONAL_PAYMENT: 'transition/confirm-additional-payment',
  EXPIRE_ADDITIONAL_PAYMENT: 'transition/expire-additional-payment',
  OPERATOR_COMPLETE_ADDITIONAL_PAYMENT: 'transition/operator-complete-additional-payment',
  COMPLETE_ADDITIONAL_PAYMENT: 'transition/complete-additional-payment',
  CANCEL_ADDITIONAL_PAYMENT: 'transition/cancel-additional-payment',

  // Reviews are given through transaction transitions. Review 1 can be
  // by provider or customer, and review 2 will be the other party of
  // the transaction.
  READY_TO_REVIEW: 'transition/ready-to-review',
  REVIEW_1_BY_PROVIDER: 'transition/review-1-by-provider',
  REVIEW_2_BY_PROVIDER: 'transition/review-2-by-provider',
  REVIEW_1_BY_CUSTOMER: 'transition/review-1-by-customer',
  REVIEW_2_BY_CUSTOMER: 'transition/review-2-by-customer',
  EXPIRE_CUSTOMER_REVIEW_PERIOD: 'transition/expire-customer-review-period',
  EXPIRE_PROVIDER_REVIEW_PERIOD: 'transition/expire-provider-review-period',
  EXPIRE_REVIEW_PERIOD: 'transition/expire-review-period',
};

// original tx flow - TODO: remove these
export const TRANSITION_ENQUIRE = transitions.INQUIRE;

export const TRANSITION_DECLINE = transitions.DECLINE;
export const TRANSITION_CANCELLED_BY_CUSTOMER = 'transition/cancelled-by-customer';
export const TRANSITION_CONFIRM_PAYMENTS = 'transition/confirm-payments';

export const TRANSITION_CANCEL = transitions.CANCEL;
export const TRANSITION_COMPLETE = transitions.COMPLETE;
export const TRANSITION_COMPLETE_BY_OPERATOR = 'transition/complete-by-operator';

export const TRANSITION_EXPIRE_REVIEW_PERIOD = transitions.EXPIRE_REVIEW_PERIOD;

// payment tx flow
export const TRANSITION_MAKE_PAYMENT = 'transition/make-payment';

export const TRANSITION_EXPIRE_THE_PAYMENT = 'transition/expire-the-payment';
export const TRANSITION_CONFIRM_THE_PAYMENT = 'transition/confirm-the-payment';
export const TRANSITION_CONFIRM_AND_PAYOUT_PAYMENT = 'transition/confirm-and-payout-payment';

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
export const states = {
  INITIAL: 'initial',
  INQUIRY: 'inquiry',
  INQUIRY_EXPIRED: 'inquiry-expired',
  INQUIRY_DECLINED: 'inquiry-declined',

  QUOTE_SENT: 'quote-sent',
  QUOTE_EXPIRED: 'quote-expired',
  QUOTE_DECLINED: 'quote-declined',

  BOOKING_CREATED: 'booking-created',

  PENDING_DEPOSIT: 'pending-deposit',
  ACCEPTED_DEPOSIT: 'accepted-deposit',
  DEPOSIT_EXPIRED: 'deposit-expired',

  PENDING_PAYMENT: 'pending-payment',
  PAYMENT_EXPIRED: 'payment-expired',
  ACCEPTED: 'accepted',
  BOOKED: 'booked',
  CANCELLED: 'cancelled',
  CANCELED_AFTER_BOOKED: 'cancelled-after-booked',

  ADDITIONAL_PAYMENT_INITIATED: 'additional-payment-initiated',
  PENDING_ADDITIONAL_PAYMENT: 'pending-additional-payment',
  ACCEPTED_ADDITIONAL_PAYMENT: 'accepted-additional-payment',
  ADDITIONAL_PAYMENT_EXPIRED: 'additional-payment-expired',
  DELIVERED_ADDITIONAL_PAYMENT: 'delivered-additional-payment',
  CANCELLED_ADDITIONAL_PAYMENT: 'cancelled-additional-payment',

  REVIEW_READY: 'review-ready',
  REVIEWED: 'reviewed',
  REVIEWED_BY_CUSTOMER: 'reviewed-by-customer',
  REVIEWED_BY_PROVIDER: 'reviewed-by-provider',
};

/**
 * Actors
 *
 * There are 4 different actors that might initiate transitions:
 */

// Roles of actors that perform transaction transitions
export const TX_TRANSITION_ACTOR_CUSTOMER = 'customer';
export const TX_TRANSITION_ACTOR_PROVIDER = 'provider';
export const TX_TRANSITION_ACTOR_SYSTEM = 'system';
export const TX_TRANSITION_ACTOR_OPERATOR = 'operator';

export const TX_TRANSITION_ACTORS = [
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  TX_TRANSITION_ACTOR_SYSTEM,
  TX_TRANSITION_ACTOR_OPERATOR,
];

/**
 * Description of transaction process
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
const stateDescription = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'vat-booking/worktripp-live',

  // This 'initial' state is a starting point for new transaction
  initial: states.INITIAL,

  // States
  states: {
    [states.INITIAL]: {
      on: {
        [transitions.INQUIRE]: states.INQUIRY,
        [transitions.INITIATE_ADDITIONAL_PAYMENT]: states.ADDITIONAL_PAYMENT_INITIATED,
      },
    },
    [states.INQUIRY]: {
      on: {
        [transitions.SEND_QUOTE]: states.QUOTE_SENT,
        [transitions.EXPIRE_INQUIRY]: states.INQUIRY_EXPIRED,
        [transitions.CUSTOMER_DECLINE_INQUIRY]: states.INQUIRY_DECLINED,
        [transitions.PROVIDER_DECLINE_INQUIRY]: states.INQUIRY_DECLINED,
      },
    },
    [states.INQUIRY_EXPIRED]: {},
    [states.INQUIRY_DECLINED]: {},

    [states.QUOTE_SENT]: {
      on: {
        [transitions.CREATE_BOOKING]: states.BOOKING_CREATED,
        [transitions.UPDATE_QUOTE]: states.QUOTE_SENT,
        [transitions.EXPIRE_QUOTE]: states.QUOTE_EXPIRED,
        [transitions.CUSTOMER_DECLINE_QUOTE]: states.QUOTE_DECLINED,
        [transitions.PROVIDER_DECLINE_QUOTE]: states.QUOTE_DECLINED,
      },
    },
    [states.QUOTE_EXPIRED]: {},
    [states.QUOTE_DECLINED]: {},

    [states.BOOKING_CREATED]: {
      on: {
        [transitions.PENDING_DEPOSIT_VAT]: states.PENDING_DEPOSIT,
        [transitions.PENDING_PAYMENT_VAT]: states.PENDING_PAYMENT,
      },
    },

    [states.PENDING_DEPOSIT]: {
      on: {
        [transitions.UPDATE_DEPOSIT]: states.PENDING_DEPOSIT,
        [transitions.CONFIRM_DEPOSIT]: states.ACCEPTED_DEPOSIT,
        [transitions.EXPIRE_DEPOSIT]: states.DEPOSIT_EXPIRED,
      },
    },
    [states.DEPOSIT_EXPIRED]: {},
    [states.ACCEPTED_DEPOSIT]: {
      on: {
        [transitions.CANCEL_DEPOSIT]: states.CANCELLED,
        [transitions.COMPLETE_DEPOSIT]: states.BOOKED,
        [transitions.OPERATOR_COMPLETE_DEPOSIT]: states.BOOKED,
      },
    },

    [states.PENDING_PAYMENT]: {
      on: {
        [transitions.EXPIRE_PAYMENT]: states.PAYMENT_EXPIRED,
        [transitions.UPDATE_PAYMENT]: states.PENDING_PAYMENT,
        [transitions.CONFIRM_PAYMENT]: states.ACCEPTED,
      },
    },
    [states.PAYMENT_EXPIRED]: {},
    [states.ACCEPTED]: {
      on: {
        [transitions.CANCEL]: states.CANCELLED,
        [transitions.COMPLETE]: states.BOOKED,
        [transitions.OPERATOR_COMPLETE]: states.BOOKED,
      },
    },
    [states.CANCELLED]: {},

    [states.ADDITIONAL_PAYMENT_INITIATED]: {
      on: {
        [transitions.PENDING_ADDITIONAL_PAYMENT_VAT]: states.PENDING_ADDITIONAL_PAYMENT,
      },
    },
    [states.PENDING_ADDITIONAL_PAYMENT]: {
      on: {
        [transitions.UPDATE_ADDITIONAL_PAYMENT]: states.PENDING_ADDITIONAL_PAYMENT,
        [transitions.CONFIRM_ADDITIONAL_PAYMENT]: states.ACCEPTED_ADDITIONAL_PAYMENT,
        [transitions.EXPIRE_ADDITIONAL_PAYMENT]: states.ADDITIONAL_PAYMENT_EXPIRED,
      },
    },
    [states.ADDITIONAL_PAYMENT_EXPIRED]: {},
    [states.ACCEPTED_ADDITIONAL_PAYMENT]: {
      on: {
        [transitions.OPERATOR_COMPLETE_ADDITIONAL_PAYMENT]: states.DELIVERED_ADDITIONAL_PAYMENT,
        [transitions.COMPLETE_ADDITIONAL_PAYMENT]: states.DELIVERED_ADDITIONAL_PAYMENT,
        [transitions.CANCEL_ADDITIONAL_PAYMENT]: states.CANCELLED_ADDITIONAL_PAYMENT,
      },
    },

    [states.BOOKED]: {
      on: {
        [transitions.READY_TO_REVIEW]: states.REVIEW_READY,
        [transitions.CANCEL_BOOKED]: states.CANCELED_AFTER_BOOKED,
      },
    },

    [states.REVIEW_READY]: {
      on: {
        [transitions.EXPIRE_REVIEW_PERIOD]: states.REVIEWED,
        [transitions.REVIEW_1_BY_CUSTOMER]: states.REVIEWED_BY_CUSTOMER,
        [transitions.REVIEW_1_BY_PROVIDER]: states.REVIEWED_BY_PROVIDER,
      },
    },

    [states.REVIEWED_BY_CUSTOMER]: {
      on: {
        [transitions.REVIEW_2_BY_PROVIDER]: states.REVIEWED,
        [transitions.EXPIRE_PROVIDER_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
    [states.REVIEWED_BY_PROVIDER]: {
      on: {
        [transitions.REVIEW_2_BY_CUSTOMER]: states.REVIEWED,
        [transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
    [states.REVIEWED]: { type: 'final' },
  },
};

// Note: currently we assume that state description doesn't contain nested states.
const statesFromStateDescription = description => description.states || {};

// Get all the transitions from states object in an array
const getTransitions = states => {
  const stateNames = Object.keys(states);

  const transitionsReducer = (transitionArray, name) => {
    const stateTransitions = states[name] && states[name].on;
    const transitionKeys = stateTransitions ? Object.keys(stateTransitions) : [];
    return [
      ...transitionArray,
      ...transitionKeys.map(key => ({ key, value: stateTransitions[key] })),
    ];
  };

  return stateNames.reduce(transitionsReducer, []);
};

// This is a list of all the transitions that this app should be able to handle.
export const TRANSITIONS = getTransitions(statesFromStateDescription(stateDescription)).map(
  t => t.key
);

// This is used to be able to show users old transactions pre vat-booking process
export const OLD_TRANSITIONS = ['transition/enquire'];

// This function returns a function that has given stateDesc in scope chain.
const getTransitionsToStateFn = stateDesc => state =>
  getTransitions(statesFromStateDescription(stateDesc))
    .filter(t => t.value === state)
    .map(t => t.key);

// Get all the transitions that lead to specified state.
const getTransitionsToState = getTransitionsToStateFn(stateDescription);

// This is needed to fetch transactions that need response from provider.
// I.e. transactions which provider needs to accept or decline
export const transitionsToRequested = getTransitionsToState(states.INQUIRY);

/**
 * Helper functions to figure out if transaction is in a specific state.
 * State is based on lastTransition given by transaction object and state description.
 */

export const txIsAtState = (tx, state) => {
  return getTransitionsToState(state).includes(txLastTransition(tx));
};

const txLastTransition = tx => ensureTransaction(tx).attributes.lastTransition;

export const txIsEnquired = tx =>
  getTransitionsToState(states.INQUIRY).includes(txLastTransition(tx));

export const txIsToQuoteSent = tx => {
  return getTransitionsToState(states.QUOTE_SENT).includes(txLastTransition(tx));
};

export const txIsToBookingCreated = tx => {
  return getTransitionsToState(states.BOOKING_CREATED).includes(txLastTransition(tx));
};

// can be declined by either customer or provider
export const txIsToDeclinedInquiry = tx =>
  getTransitionsToState(states.INQUIRY_DECLINED).includes(txLastTransition(tx));

export const txIsToExpiredInquiry = tx =>
  getTransitionsToState(states.INQUIRY_EXPIRED).includes(txLastTransition(tx));

// can be declined by either customer or provider
export const txToIsDeclinedQuote = tx =>
  getTransitionsToState(states.QUOTE_DECLINED).includes(txLastTransition(tx));

export const txIsToExpiredQuote = tx =>
  getTransitionsToState(states.QUOTE_EXPIRED).includes(txLastTransition(tx));

export const txIsDepositPending = tx => {
  return getTransitionsToState(states.PENDING_DEPOSIT).includes(txLastTransition(tx));
};

export const txIsDepositAccepted = tx =>
  getTransitionsToState(states.ACCEPTED_DEPOSIT).includes(txLastTransition(tx));

export const txIsAdditionalPaymentInitiated = tx => {
  return getTransitionsToState(states.ADDITIONAL_PAYMENT_INITIATED).includes(txLastTransition(tx));
};

export const txIsBalancePending = tx => {
  return getTransitionsToState(states.PENDING_ADDITIONAL_PAYMENT).includes(txLastTransition(tx));
};

export const txIsPaymentPending = tx => {
  return getTransitionsToState(states.PENDING_PAYMENT).includes(txLastTransition(tx));
};

export const txIsPaymentExpired = tx =>
  getTransitionsToState(states.PAYMENT_EXPIRED).includes(txLastTransition(tx));

export const txIsDepositExpired = tx =>
  getTransitionsToState(states.DEPOSIT_EXPIRED).includes(txLastTransition(tx));

export const txIsAccepted = tx =>
  getTransitionsToState(states.ACCEPTED).includes(txLastTransition(tx));

export const txIsAcceptedDeposit = tx =>
  getTransitionsToState(states.ACCEPTED_DEPOSIT).includes(txLastTransition(tx));

export const txIsCancelled = tx =>
  getTransitionsToState(states.CANCELLED).includes(txLastTransition(tx));

export const txIsCancelledBooked = tx =>
  getTransitionsToState(states.CANCELED_AFTER_BOOKED).includes(txLastTransition(tx));

export const txIsBooked = tx => getTransitionsToState(states.BOOKED).includes(txLastTransition(tx));

export const txIsReviewReady = tx =>
  getTransitionsToState(states.REVIEW_READY).includes(txLastTransition(tx));

const firstReviewTransitions = [
  ...getTransitionsToState(states.REVIEWED_BY_CUSTOMER),
  ...getTransitionsToState(states.REVIEWED_BY_PROVIDER),
];
export const txIsInFirstReview = tx => firstReviewTransitions.includes(txLastTransition(tx));

export const txIsInFirstReviewBy = (tx, isCustomer) =>
  isCustomer
    ? getTransitionsToState(states.REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx))
    : getTransitionsToState(states.REVIEWED_BY_PROVIDER).includes(txLastTransition(tx));

export const txIsReviewed = tx =>
  getTransitionsToState(states.REVIEWED).includes(txLastTransition(tx));

/**
 * Helper functions to figure out if transaction has passed a given state.
 * This is based on transitions history given by transaction object.
 */

const txTransitions = tx => ensureTransaction(tx).attributes.transitions || [];
const hasPassedTransition = (transitionName, tx) =>
  !!txTransitions(tx).find(t => t.transition === transitionName);

const hasPassedStateFn = state => tx =>
  getTransitionsToState(state).filter(t => hasPassedTransition(t, tx)).length > 0;

export const txHasBeenAccepted = hasPassedStateFn(states.ACCEPTED);
export const txHasBeenBooked = hasPassedStateFn(states.BOOKED);

/**
 * Other transaction related utility functions
 */

export const transitionIsReviewed = transition =>
  getTransitionsToState(states.REVIEWED).includes(transition);

export const transitionIsFirstReviewedBy = (transition, isCustomer) =>
  isCustomer
    ? getTransitionsToState(states.REVIEWED_BY_CUSTOMER).includes(transition)
    : getTransitionsToState(states.REVIEWED_BY_PROVIDER).includes(transition);

export const getReview1Transition = isCustomer =>
  isCustomer ? transitions.REVIEW_1_BY_CUSTOMER : transitions.REVIEW_1_BY_PROVIDER;

export const getReview2Transition = isCustomer =>
  isCustomer ? transitions.REVIEW_2_BY_CUSTOMER : transitions.REVIEW_2_BY_PROVIDER;

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    transitions.INQUIRE,
    transitions.EXPIRE_INQUIRY,
    transitions.CUSTOMER_DECLINE_INQUIRY,
    transitions.PROVIDER_DECLINE_INQUIRY,
    transitions.EXPIRE_QUOTE,
    transitions.CUSTOMER_DECLINE_QUOTE,
    transitions.PROVIDER_DECLINE_QUOTE,

    transitions.CANCEL,
    transitions.CANCEL_DEPOSIT,
    transitions.CONFIRM_DEPOSIT,
    transitions.CONFIRM_PAYMENT,
    transitions.CONFIRM_ADDITIONAL_PAYMENT,

    transitions.COMPLETE,
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.REVIEW_2_BY_PROVIDER,
  ].includes(transition);
};

export const isCustomerReview = transition => {
  return [transitions.REVIEW_1_BY_CUSTOMER, transitions.REVIEW_2_BY_CUSTOMER].includes(transition);
};

export const isProviderReview = transition => {
  return [transitions.REVIEW_1_BY_PROVIDER, transitions.REVIEW_2_BY_PROVIDER].includes(transition);
};

export const getUserTxRole = (currentUserId, transaction) => {
  const tx = ensureTransaction(transaction);
  const customer = tx.customer;
  if (currentUserId && currentUserId.uuid && tx.id && customer.id) {
    // user can be either customer or provider
    return currentUserId.uuid === customer.id.uuid
      ? TX_TRANSITION_ACTOR_CUSTOMER
      : TX_TRANSITION_ACTOR_PROVIDER;
  } else {
    throw new Error(`Parameters for "userIsCustomer" function were wrong.
      currentUserId: ${currentUserId}, transaction: ${transaction}`);
  }
};

export const txRoleIsProvider = userRole => userRole === TX_TRANSITION_ACTOR_PROVIDER;
export const txRoleIsCustomer = userRole => userRole === TX_TRANSITION_ACTOR_CUSTOMER;

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
  return [
    transitions.CREATE_BOOKING,

    transitions.PENDING_DEPOSIT_VAT,
    transitions.UPDATE_DEPOSIT,

    transitions.PENDING_PAYMENT_VAT,
    transitions.UPDATE_PAYMENT,

    transitions.INITIATE_ADDITIONAL_PAYMENT,
    transitions.PENDING_ADDITIONAL_PAYMENT_VAT,
    transitions.UPDATE_ADDITIONAL_PAYMENT,
  ].includes(transition);
};

// Check when transaction is refunded (booking did not happen)
// In these transitions action/stripe-refund-payment is called
export const isRefunded = transition => {
  const txRefundedTransitions = [
    transitions.EXPIRE_PAYMENT,
    transitions.EXPIRE_DEPOSIT,
    transitions.EXPIRE_ADDITIONAL_PAYMENT,
    transitions.CANCEL,
    transitions.CANCEL_DEPOSIT,
    transitions.CANCEL_ADDITIONAL_PAYMENT,
  ];
  return txRefundedTransitions.includes(transition);
};

export const isPaymentExpired = transition => {
  return [
    transitions.EXPIRE_DEPOSIT,
    transitions.EXPIRE_PAYMENT,
    transitions.EXPIRE_ADDITIONAL_PAYMENT,
  ].includes(transition);
};

export const isStripeCreatePaymentIntentTransition = transition => {
  return [
    transitions.PENDING_DEPOSIT_VAT,
    transitions.PENDING_PAYMENT_VAT,
    transitions.PENDING_ADDITIONAL_PAYMENT_VAT,
  ].includes(transition);
};

export const isPayingTransition = (transition, isAdditionalPaymentDue) => {
  if (
    [
      transitions.CONFIRM_DEPOSIT,
      transitions.COMPLETE_DEPOSIT,
      transitions.OPERATOR_COMPLETE_DEPOSIT,
    ].includes(transition)
  ) {
    return isAdditionalPaymentDue;
  }

  return [
    transitions.SEND_QUOTE,
    transitions.UPDATE_QUOTE,
    transitions.CREATE_BOOKING,
    transitions.PENDING_DEPOSIT_VAT,
    transitions.UPDATE_DEPOSIT,
    transitions.CONFIRM_DEPOSIT,
    transitions.COMPLETE_DEPOSIT,
    transitions.OPERATOR_COMPLETE_DEPOSIT,
    transitions.PENDING_PAYMENT_VAT,
    transitions.UPDATE_PAYMENT,
  ].includes(transition);
};

export const getUpdatePaymentTransitionName = tx => {
  if (txIsBalancePending(tx)) {
    return {
      transitionName: transitions.UPDATE_ADDITIONAL_PAYMENT,
      paymentType: 'balance-payment',
    };
  }

  if (hasPassedTransition(transitions.PENDING_DEPOSIT_VAT, tx)) {
    return { transitionName: transitions.UPDATE_DEPOSIT, paymentType: 'deposit-payment' };
  }

  return { transitionName: transitions.UPDATE_PAYMENT, paymentType: 'full-payment' };
};

export const getConfirmPaymentTransitionName = tx => {
  if (txIsBalancePending(tx)) {
    return {
      transitionName: transitions.CONFIRM_ADDITIONAL_PAYMENT,
      paymentType: 'balance-payment',
    };
  }

  if (txIsDepositPending(tx)) {
    return { transitionName: transitions.CONFIRM_DEPOSIT, paymentType: 'balance-payment' };
  }
  // TODO: should check agains specific pending payment state in case it gets itself twisted
  return { transitionName: transitions.CONFIRM_PAYMENT, paymentType: 'full-payment' };
};

// TODO: should we check transaction is paid off as well?
export const txHasAdditionalPaymentInitiated = transaction => {
  const hasPaymentIntents =
    transaction.attributes.protectedData &&
    transaction.attributes.protectedData.stripePaymentIntents;

  if (hasPaymentIntents) {
    return false;
  }

  let additionalTransactions = transaction.attributes?.metadata?.additionalTransactions;
  if (!additionalTransactions) {
    return false;
  }

  let pendingTx = Object.values(additionalTransactions).find(otherTx => {
    return otherTx.txStatus === 'pending' || txIsAdditionalPaymentInitiated(otherTx);
  });

  return pendingTx !== undefined;
};

export const txHasAdditionalPaymentPending = transaction => {
  const hasPaymentIntents =
    transaction.attributes.protectedData &&
    transaction.attributes.protectedData.stripePaymentIntents;

  if (hasPaymentIntents) {
    return false;
  }

  let additionalTransactions = transaction.attributes?.metadata?.additionalTransactions;
  if (!additionalTransactions) {
    return false;
  }

  let pendingTx = Object.values(additionalTransactions).find(otherTx => {
    return (
      otherTx.attributes.protectedData && otherTx.attributes.protectedData.stripePaymentIntents
    );
  });

  return pendingTx !== undefined;
};

// TODO: add cancel buttons
// tx can be a full transaction or a listing transaction
export const getPayingButtonText = tx => {
  if (!hasPassedStateFn(states.BOOKING_CREATED)(tx) || txIsToBookingCreated(tx)) {
    return { btnText: 'Accept & Pay' };
  }

  if (hasPassedStateFn(states.PENDING_PAYMENT)(tx)) {
    return { btnText: 'Pay full amount' };
  }

  if (txIsDepositPending(tx)) {
    return { btnText: 'Complete deposit payment' };
  }

  if (txIsPaymentPending(tx)) {
    return { btnText: 'Complete payment' };
  }

  const isDepositPaid =
    txIsDepositAccepted(tx) ||
    [transitions.COMPLETE_DEPOSIT, transitions.OPERATOR_COMPLETE_DEPOSIT].includes(
      txLastTransition(tx)
    );

  if (isDepositPaid && txHasAdditionalPaymentInitiated(tx)) {
    let { bookingStart } = tx?.attributes?.metadata?.activeProposal || {};
    const isBalanceDue = isBalancePaymentDue(bookingStart);
    const balanceDueDate = getBalanceDueDate(bookingStart);
    let text = isBalanceDue
      ? 'Pay balance'
      : `Pay balance - due ${format(balanceDueDate, "do 'of' MMM")}`;

    return { btnText: text, disabled: false /*!isBalanceDue*/ };
  }

  if (isDepositPaid && txHasAdditionalPaymentPending(tx)) {
    return { btnText: 'Complete balance payment' };
  }

  return { btnText: null };
};
