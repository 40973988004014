import React, { Component } from 'react';
import { string, bool, func } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import {
  Form,
  FieldTextInput,
  SecondaryButton,
  PrimaryButton,
  FieldDateInput,
  FieldSelect,
} from '../../../components';
import { propTypes } from '../../../util/types';
import * as validators from '../../../util/validators';

import css from './ScheduleMeetingForm.module.css';
import FieldTimeZoneSelect from '../../FieldTimeZoneSelect/FieldTimeZoneSelect';

const BLUR_TIMEOUT_MS = 100;

class ScheduleMeetingFormComponent extends Component {
  constructor(props) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.blurTimeoutId = null;
  }

  handleFocus() {
    this.props.onFocus();
    window.clearTimeout(this.blurTimeoutId);
  }

  handleBlur() {
    // We only trigger a blur if another focus event doesn't come
    // within a timeout. This enables keeping the focus synced when
    // focus is switched between the message area and the submit
    // button.
    this.blurTimeoutId = window.setTimeout(() => {
      this.props.onBlur();
    }, BLUR_TIMEOUT_MS);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            messagePlaceholder,
            handleSubmit,
            inProgress,
            invalid,
            form,
            formId,
            initialValues,
            intl,
          } = formRenderProps;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = inProgress;
          const submitDisabled = invalid || submitInProgress;

          const urlLabel = intl.formatMessage({
            id: 'ChatActionsSection.urlLabel',
          });
          const urlPlaceholder = intl.formatMessage({
            id: 'ChatActionsSection.urlPlaceholder',
          });
          const urlRequired = <FormattedMessage id="ChatActionsSection.urlRequired" />;

          const dateLabel = intl.formatMessage({
            id: 'ChatActionsSection.dateLabel',
          });
          const datePlaceholder = intl.formatMessage({
            id: 'ChatActionsSection.datePlaceholder',
          });
          const dateRequired = <FormattedMessage id="ChatActionsSection.dateRequired" />;

          const timeZoneLabel = intl.formatMessage({
            id: 'ChatActionsSection.timeZoneLabel',
          });

          const timeZoneRequired = <FormattedMessage id="ChatActionsSection.timeZoneRequired" />;

          const timeLabel = intl.formatMessage({
            id: 'ChatActionsSection.timeLabel',
          });
          const timePlaceholder = intl.formatMessage({
            id: 'ChatActionsSection.timePlaceholder',
          });
          const timeRequired = <FormattedMessage id="ChatActionsSection.timeRequired" />;

          const meetingLengthLabel = intl.formatMessage({
            id: 'ChatActionsSection.meetingLengthLabel',
          });
          const meetingLengthPlaceholder = intl.formatMessage({
            id: 'ChatActionsSection.meetingLengthPlaceholder',
          });
          const meetingLengthRequired = (
            <FormattedMessage id="ChatActionsSection.meetingLengthRequired" />
          );

          return (
            <Form
              className={classes}
              onSubmit={values => handleSubmit(values, form)}
              initialValues={initialValues}
            >
              <br></br>
              <br></br>
              <FieldDateInput
                type="date"
                id="date"
                name="date"
                label={dateLabel}
                placeholderText={datePlaceholder}
                validate={validators.required(dateRequired)}
              />
              <br></br>
              <FieldTimeZoneSelect
                id="timeZone"
                name="timeZone"
                label={timeZoneLabel}
                validate={validators.required(timeZoneRequired)}
              />
              <br></br>

              <FieldSelect
                id="time"
                name="time"
                label={timeLabel}
                validate={validators.required(timeRequired)}
              >
                <option value="" disabled>
                  {timePlaceholder}
                </option>
                <option>00:00</option>
                <option>00:30</option>
                <option>01:00</option>
                <option>01:30</option>
                <option>02:00</option>
                <option>02:30</option>
                <option>03:00</option>
                <option>03:30</option>
                <option>04:00</option>
                <option>04:30</option>
                <option>05:00</option>
                <option>05:30</option>
                <option>06:00</option>
                <option>06:30</option>
                <option>07:00</option>
                <option>07:30</option>
                <option>08:00</option>
                <option>08:30</option>
                <option>09:00</option>
                <option>09:30</option>
                <option>10:00</option>
                <option>10:30</option>
                <option>11:00</option>
                <option>11:30</option>
                <option>12:00</option>
                <option>12:30</option>
                <option>13:00</option>
                <option>13:30</option>
                <option>14:00</option>
                <option>14:30</option>
                <option>15:00</option>
                <option>15:30</option>
                <option>16:00</option>
                <option>16:30</option>
                <option>17:00</option>
                <option>17:30</option>
                <option>18:00</option>
                <option>18:30</option>
                <option>19:00</option>
                <option>19:30</option>
                <option>20:00</option>
                <option>20:30</option>
                <option>21:00</option>
                <option>21:30</option>
                <option>22:00</option>
                <option>22:30</option>
                <option>23:00</option>
                <option>23:30</option>
              </FieldSelect>
              <br></br>

              <FieldSelect
                id="meetingLength"
                name="meetingLength"
                label={meetingLengthLabel}
                validate={validators.required(meetingLengthRequired)}
              >
                <option value="" disabled>
                  {meetingLengthPlaceholder}
                </option>
                <option value="30">30 minutes</option>
                <option value="60">1 hour</option>
                <option value="120">2 hours</option>
              </FieldSelect>
              <br></br>

              <FieldTextInput
                type="text"
                id="url"
                name="url"
                label={urlLabel}
                placeholder={urlPlaceholder}
                validate={validators.required(urlRequired)}
              />
              <br></br>

              <PrimaryButton disabled={submitDisabled} type="submit">
                Book
              </PrimaryButton>
            </Form>
          );
        }}
      />
    );
  }
}

ScheduleMeetingFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  messagePlaceholder: null,
  onFocus: () => null,
  onBlur: () => null,
  sendMessageError: null,
};

ScheduleMeetingFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,

  messagePlaceholder: string,
  onSubmit: func.isRequired,
  onFocus: func,
  onBlur: func,
  sendMessageError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ScheduleMeetingForm = compose(injectIntl)(ScheduleMeetingFormComponent);

ScheduleMeetingForm.displayName = 'ScheduleMeetingForm';

export default ScheduleMeetingForm;
