import React, { Component } from 'react';
import { array, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { nonEmptyArray, isYoutubeLink, composeValidators } from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import {
  AddImages,
  Button,
  SecondaryButton,
  Modal,
  Form,
  ValidationError,
  FieldTextInput,
  FieldCheckbox,
} from '../../components';
import rocketImg from '../../assets/icons/rocket.png';
import fileIcon from '../../assets/icons/fileIcon.png';
import css from './EditListingPhotosForm.module.css';
import ImageCropModal from '../../ImageCropModal/ImageCropModal';
import axios from 'axios';

const ACCEPT_IMAGES = 'image/*';

export class EditListingPhotosFormComponent extends Component {
  constructor(props) {
    super(props);

    this.state = { imageUploadRequested: false };
    this.onImageUploadHandler = this.onImageUploadHandler.bind(this);
    this.submittedImages = [];
    this.uploadDelayTimeoutId = null;
    this.state = {
      uploadDelay: false,
      playerType: [],
      crop: null,
      imgSrc: '',
      tempId: '',
      imageModalState: false,
      tempFile: {},
      uploadFileModalOpen: false,
      sendFileInProgress: false,
    };
    this.submittedValues = {};
    this.onSelectFile = this.onSelectFile.bind(this);
    this.setCrop = this.setCrop.bind(this);
  }
  componentDidMount() {
    // const user = ensureCurrentUser(this.props.currentUser);
    // const { publicData } = user.attributes.profile;
    // this.setState({
    //   playerType: publicData.playerType ? publicData.playerType : [],
    // });
  }
  componentDidUpdate(prevProps) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
    if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
      this.setState({ uploadDelay: true });
      this.uploadDelayTimeoutId = window.setTimeout(() => {
        this.setState({ uploadDelay: false });
      }, UPLOAD_CHANGE_DELAY);
    }
  }
  componentWillUnmount() {
    window.clearTimeout(this.uploadDelayTimeoutId);
  }

  onSelectFile(e) {
    if (e?.target && e.target.files && e.target.files.length > 0) {
      // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({
          imgSrc: reader.result.toString() || '',
        })
      );
      reader.readAsDataURL(e.target.files[0]);
    } else {
      this.setState({
        imgSrc: '',
        tempId: '',
      });
    }
  }

  setCrop(value) {
    this.setState({
      crop: value,
    });
  }

  onImageUploadHandler(file) {
    if (file) {
      this.setState({ imageUploadRequested: true });
      this.props
        .onImageUpload({ id: `${file.name}_${Date.now()}`, file })
        .then(() => {
          this.setState({ imageUploadRequested: false });
        })
        .catch(() => {
          this.setState({ imageUploadRequested: false });
        });
    }
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        onImageUploadHandler={this.onImageUploadHandler}
        imageUploadRequested={this.state.imageUploadRequested}
        initialValues={{
          images: this.props.images,
          introductoryVideo: this.props.introductoryVideo,
          agreedToTerms: this.props.agreedToTerms,
        }}
        render={formRenderProps => {
          const {
            form,
            className,
            fetchErrors,
            handleSubmit,
            images,
            imageUploadRequested,
            intl,
            invalid,
            onImageUploadHandler,
            onRemoveImage,
            disabled,
            ready,
            saveActionMsg,
            updated,
            updateInProgress,
            values,
            isNewListingFlow,
            tempStorage,
            setTempStorage,
          } = formRenderProps;

          const chooseImageText = (
            <span className={css.chooseImageText}>
              <span className={css.chooseImage}>
                <FormattedMessage id="EditListingPhotosForm.chooseImage" />
              </span>
              <span className={css.imageTypes}>
                <FormattedMessage id="EditListingPhotosForm.imageTypes" />
              </span>
            </span>
          );

          const imageRequiredMessage = intl.formatMessage({
            id: 'EditListingPhotosForm.imageRequired',
          });

          const { publishListingError, showListingsError, updateListingError, uploadImageError } =
            fetchErrors || {};
          const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

          let uploadImageFailed = null;

          if (uploadOverLimit) {
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadOverLimit" />
              </p>
            );
          } else if (uploadImageError) {
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
              </p>
            );
          }

          // NOTE: These error messages are here since Photos panel is the last visible panel
          // before creating a new listing. If that order is changed, these should be changed too.
          // Create and show listing errors are shown above submit button
          const publishListingFailed = publishListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.publishListingFailed" />
            </p>
          ) : null;
          const showListingFailed = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.showListingFailed" />
            </p>
          ) : null;

          const submittedOnce = this.submittedImages.length > 0;
          // imgs can contain added images (with temp ids) and submitted images with uniq ids.
          const arrayOfImgIds = imgs =>
            imgs.map(i => (typeof i.id === 'string' ? i.imageId : i.id));
          const imageIdsFromProps = arrayOfImgIds(images);
          const imageIdsFromPreviousSubmit = arrayOfImgIds(this.submittedImages);
          const imageArrayHasSameImages = isEqual(imageIdsFromProps, imageIdsFromPreviousSubmit);
          const pristineSinceLastSubmit = submittedOnce && imageArrayHasSameImages;

          const submitReady = (updated && pristineSinceLastSubmit) || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled =
            invalid ||
            disabled ||
            submitInProgress ||
            imageUploadRequested ||
            ready ||
            !values.agreedToTerms;

          const classes = classNames(css.root, className);

          const CheckboxWithLink = () => {
            return (
              <span name="agreement" id="agreement">
                Before your publish, you need to agree to WorkTripp SLA Agreement{' '}
                <a
                  href="https://www.worktripp.com/terms-of-service"
                  target="_blank"
                  className={css.hereLink}
                >
                  here
                </a>
              </span>
            );
          };

          const onUploadFile = params => {
            this.setState({
              uploadFileModalOpen: false,
              sendFileInProgress: true,
            });
            axios
              .post('/api/files/upload', params, {
                headers: { 'Content-Type': 'multipart/form-data' },
              })
              .then(response => {
                setTempStorage([...tempStorage, response.data]);
                this.setState({ sendFileInProgress: false, tempFile: {} });
              })
              .catch(e => {
                this.setState({ sendFileInProgress: false, tempFile: {} });
                console.log(e.response && e.response.data ? e.response.data : 'Unknown error');
              });
          };

          const onRemoveFile = key => {
            const newFiles = tempStorage.filter(f => f.fileKey !== key);
            setTempStorage(newFiles);
          };

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedImages = images;
                handleSubmit(e);
              }}
            >
              {updateListingError ? (
                <p className={css.error}>
                  <FormattedMessage id="EditListingPhotosForm.updateFailed" />
                </p>
              ) : null}

              {this.state.imgSrc && (
                <ImageCropModal
                  crop={this.state.crop}
                  setCrop={this.setCrop}
                  imgSrc={this.state.imgSrc}
                  tempId={this.state.tempId}
                  onSelectFile={this.onSelectFile}
                  onImageUpload={this.props.onImageUpload}
                  onManageDisableScrolling={this.props.onManageDisableScrolling}
                  imageModalState={this.state.imageModalState}
                  closeImageModal={() => {
                    this.setState({
                      imageModalState: false,
                    });
                  }}
                />
              )}
              <AddImages
                className={css.imagesField}
                images={images}
                form={form}
                thumbnailClassName={css.thumbnail}
                savedImageAltText={intl.formatMessage({
                  id: 'EditListingPhotosForm.savedImageAltText',
                })}
                onRemoveImage={onRemoveImage}
              >
                <Field
                  id="addImage"
                  name="addImage"
                  accept={ACCEPT_IMAGES}
                  form={null}
                  label={chooseImageText}
                  type="file"
                  disabled={imageUploadRequested}
                >
                  {fieldprops => {
                    const {
                      accept,
                      input,
                      label,
                      disabled: fieldDisabled,
                      uploadImageError,
                    } = fieldprops;
                    const { name, type } = input;
                    const onChange = e => {
                      const file = e.target.files[0];
                      form.change(`addImage`, file);
                      form.blur(`addImage`);
                      // onImageUploadHandler(file)
                      if (file != null) {
                        this.onSelectFile(e);
                        this.setState({
                          tempId: `${file.name}_${Date.now()}`,
                          imageModalState: true,
                        });
                      }
                    };
                    let error = null;

                    if (isUploadImageOverLimitError(uploadImageError)) {
                      error = <div className={css.error}></div>;
                    } else if (uploadImageError) {
                      error = <div className={css.error}></div>;
                    }
                    const inputProps = { accept, id: name, name, onChange, type };
                    return (
                      <div className={css.addImageWrapper}>
                        <div className={css.aspectRatioWrapper}>
                          {fieldDisabled ? null : (
                            <input {...inputProps} className={css.addImageInput} />
                          )}
                          <label htmlFor={name} className={css.addImage}>
                            {label}
                          </label>
                        </div>
                      </div>
                    );
                  }}
                </Field>

                <Field
                  component={props => {
                    const { input, meta } = props;
                    return (
                      <div className={css.imageRequiredWrapper}>
                        <input {...input} />
                        <ValidationError fieldMeta={meta} />
                      </div>
                    );
                  }}
                  name="images"
                  type="hidden"
                  validate={composeValidators(nonEmptyArray(imageRequiredMessage))}
                />
              </AddImages>
              {uploadImageFailed}

              <p className={css.tip}>
                <FormattedMessage id="EditListingPhotosForm.addImagesTip" />
              </p>
              {publishListingFailed}
              {showListingFailed}

              <p className={css.videoInputTitle}>
                <FormattedMessage id="EditListingPhotosForm.addVideoInputTitle" />
                <br></br>
              </p>
              {
                //Client requested to remove this field -------------
                /* <p className={css.videoInputText}>
              <span className={css.redStar}>*</span> 
              <FormattedMessage id="EditListingPhotosForm.addVideoInputText"  />
              </p> */
              }
              <FieldTextInput
                type="text"
                placeholder="Link to a Youtube video to introduce your work"
                className={css.videoInput}
                id="introductoryVideo"
                name="introductoryVideo"
                validate={composeValidators(isYoutubeLink('Must be a valid Youtube link'))}
              />

              <div>
                <div className={css.extraFilesTitle}>
                  <FormattedMessage id="EditListingPhotosForm.extraFilesTitle" />
                </div>
                <div className={css.extraFilesText}>
                  <FormattedMessage id="EditListingPhotosForm.extraFilesText" />
                </div>

                {tempStorage && tempStorage.length > 0
                  ? tempStorage.map(f => {
                      return (
                        <div key={f.fileKey} className={css.uploadedExtraFiles}>
                          <img src={fileIcon} />
                          {f.fileName}
                          <span onClick={() => onRemoveFile(f.fileKey)}>X</span>
                        </div>
                      );
                    })
                  : null}

                {tempStorage.length < 3 && (
                  <SecondaryButton
                    type="button"
                    className={css.uploadExtraFilesButton}
                    inProgress={this.state.sendFileInProgress}
                    disabled={this.state.sendFileInProgress}
                    onClick={() => {
                      this.setState({ uploadFileModalOpen: true });
                    }}
                  >
                    <FormattedMessage id="EditListingPhotosForm.uploadFileBtn" />
                  </SecondaryButton>
                )}
              </div>

              <FieldCheckbox
                className={css.checkboxBeforePublishing}
                id="agreedToTerms"
                name="agreedToTerms"
                label={<CheckboxWithLink />}
              />

              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                <img className={css.rocket} src={rocketImg} /> {saveActionMsg}
              </Button>
              <p className={css.requiredPhotos}>
                <FormattedMessage id="EditListingPhotosForm.minimumPhoto" />
              </p>

              <Modal
                id="EditListingPhotosForm.uploadFile"
                isOpen={this.state.uploadFileModalOpen}
                onClose={() => {
                  this.setState({ uploadFileModalOpen: false });
                }}
                usePortal
                onManageDisableScrolling={this.props.onManageDisableScrolling}
              >
                <div className={css.extraFilesModalTitle}>
                  <FormattedMessage id="EditListingPhotosForm.uploadFileBtn" />
                </div>

                <input
                  type="file"
                  id="file"
                  name="file"
                  onChange={e => {
                    this.setState({ tempFile: e.target.files[0] });
                  }}
                />

                <SecondaryButton
                  className={css.uploadExtraFilesButton}
                  disabled={!this.state.tempFile || !this.state.tempFile.name}
                  type="button"
                  onClick={() => {
                    const formData = new FormData();
                    formData.append('file', this.state.tempFile);
                    formData.append('name', this.state.tempFile.name.substring(0, 24));
                    formData.append('isListingFile', true);
                    onUploadFile(formData);
                  }}
                >
                  <FormattedMessage id="EditListingPhotosForm.uploadFileBtn" />
                </SecondaryButton>
              </Modal>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingPhotosFormComponent.defaultProps = { fetchErrors: null, images: [] };

EditListingPhotosFormComponent.propTypes = {
  fetchErrors: shape({
    publishListingError: propTypes.error,
    showListingsError: propTypes.error,
    uploadImageError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  images: array,
  intl: intlShape.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
};

export default compose(injectIntl)(EditListingPhotosFormComponent);
