import React from 'react';
import classNames from 'classnames';

import { filters } from '../../marketplace-custom-config';
import { unparseListingType } from '../../util/misc';

import css from './ListingLabel.module.css';

const capitalizeFirstLetter = word => {
  if (!word) return ''; // Handle empty input
  return word.charAt(0).toUpperCase() + word.slice(1);
};

const ListingLabel = ({ rootClassName, listingType, serviceType }) => {
  const listingTypeOptions = filters.find(filter => filter.id === 'type').config.options;
  const listingFilter = listingTypeOptions.find(({ key }) => key === listingType);

  const listingLabel = listingFilter
    ? listingType === 'retreat'
      ? 'Retreat'
      : listingFilter.label
    : capitalizeFirstLetter(listingType);

  let cssLabelClass =
    serviceType === 'CFS'
      ? css.serviceTypeCFS
      : listingType === 'venue'
      ? css.serviceTypeV
      : css.serviceTypeE;

  return (
    <div className={classNames(css.label, cssLabelClass, rootClassName)}>
      {unparseListingType(listingType)}
    </div>
  );
};

export default ListingLabel;
