import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingPhotosForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';

import css from './EditListingPhotosPanel.module.css';

class EditListingPhotosPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tempStorage: null,
    };

    this.setTempStorage = this.setTempStorage.bind(this);
  }

  setTempStorage(value) {
    this.setState({
      tempStorage: value,
    });
  }

  render() {
    const {
      className,
      rootClassName,
      errors,
      disabled,
      ready,
      images,
      listing,
      onImageUpload,
      onUpdateImageOrder,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      onChange,
      onSubmit,
      onRemoveImage,
      isNewListingFlow,
      onManageDisableScrolling,
    } = this.props;

    const rootClass = rootClassName || css.root;
    const classes = classNames(rootClass, className);
    const currentListing = ensureOwnListing(listing);
    const {
      introductoryVideo,
      clientTestimonials,
      agreedToTerms,
      extraFiles,
    } = currentListing?.attributes.publicData;

    const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingPhotosPanel.title"
        values={{ listingTitle: <ListingLink listing={listing} /> }}
      />
    ) : (
      <FormattedMessage id="EditListingPhotosPanel.createListingTitle" />
    );

    const initailFiles = this.state.tempStorage
      ? this.state.tempStorage
      : extraFiles
      ? extraFiles
      : [];

    return (
      <div className={classes}>
        <h1 className={css.title}>{panelTitle}</h1>
        <p className={css.createListingText}>
          <FormattedMessage id="EditListingPhotosPanel.createListingText" />
        </p>
        <EditListingPhotosForm
          className={css.form}
          disabled={disabled}
          ready={ready}
          fetchErrors={errors}
          initialValues={{ images, agreedToTerms }}
          agreedToTerms={agreedToTerms}
          images={images}
          onImageUpload={onImageUpload}
          onSubmit={values => {
            const { addImage, introductoryVideo, agreedToTerms, ...updateValues } = values;
            delete updateValues['agree'];
            const wrongVideoLink = introductoryVideo?.includes('watch?v=');
            const wrongVideoLink2 = introductoryVideo?.includes('youtu.be');
            const correctLink = wrongVideoLink
              ? `https://www.youtube.com/embed/${introductoryVideo
                  .split('watch?v=')
                  .pop()
                  .substring(0, 11)}`
              : wrongVideoLink2
              ? `${introductoryVideo.split('youtu.be')[0]}youtube.com/embed${
                  introductoryVideo.split('youtu.be')[1]
                }`
              : introductoryVideo;
            onSubmit({
              ...updateValues,
              publicData: {
                introductoryVideo: correctLink,
                extraFiles: this.state.tempStorage
                  ? this.state.tempStorage
                  : extraFiles
                  ? extraFiles
                  : [],
                agreedToTerms,
              },
            });
          }}
          onChange={onChange}
          onUpdateImageOrder={onUpdateImageOrder}
          onRemoveImage={onRemoveImage}
          saveActionMsg={submitButtonText}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          introductoryVideo={introductoryVideo}
          clientTestimonials={clientTestimonials}
          tempStorage={initailFiles}
          setTempStorage={this.setTempStorage}
          isPublished={isPublished}
          isNewListingFlow={isNewListingFlow}
          onManageDisableScrolling={onManageDisableScrolling}
        />
      </div>
    );
  }
}

EditListingPhotosPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  images: [],
  listing: null,
};

EditListingPhotosPanel.propTypes = {
  className: string,
  rootClassName: string,
  errors: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  images: array,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
};

export default EditListingPhotosPanel;
