export const TX_TYPES = {
  'full-payment': {
    id: 'full-payment',
    value: 'Full payment',
  },
  'deposit-payment': {
    id: 'deposit-payment',
    value: 'Deposit payment',
  },
  'balance-payment': {
    id: 'balance-payment',
    value: 'Balance payment',
  },
};

export const TX_STATUS = {
  PENDING: 'pending',
  PAID: 'paid',
};

// TODO: improve this based on PI for additional patments
export const getNextPendingPaymentTransaction = (originalTransaction, ignoreOriginalTx = false) => {
  const hasPaymentIntents =
    originalTransaction.attributes.protectedData &&
    originalTransaction.attributes.protectedData.stripePaymentIntents;

  if (!ignoreOriginalTx && hasPaymentIntents) {
    return originalTransaction;
  }

  let additionalTransactions = originalTransaction.attributes?.metadata?.additionalTransactions;
  if (!additionalTransactions) {
    return null;
  }

  let pendingTx = Object.values(additionalTransactions).find(otherTx => {
    const { txStatus } = otherTx.attributes?.metadata?.paymentDetails || {};
    return txStatus === TX_STATUS.PENDING;
  });

  return pendingTx;
};

export const getTxTypeName = transaction => {
  const { txType } = transaction?.attributes?.metadata?.paymentDetails || {};
  return txType && TX_TYPES[txType] ? TX_TYPES[txType].value : 'Payment';
};

export const getStripeTransactionMessage = transaction => {
  const { txType } = transaction.attributes.metadata.paymentDetails || {};

  let value = txType && TX_TYPES[txType] ? TX_TYPES[txType].value : 'Payment';

  return `${value} complete`;
};

export const hasAdditonalPaymentPendingStatus = additionalTransactions => {
  if (!additionalTransactions) {
    return false;
  }

  return Object.values(additionalTransactions).some(additionalTransaction => {
    let txStatus =
      additionalTransaction.txStatus ??
      additionalTransaction.attributes?.metadata?.paymentDetails?.txStatus;
    return txStatus === TX_STATUS.PENDING;
  });
};

export const shouldUpdateAdditionalTransactions = (originalTransaction, transaction) => {
  let originalTxId = transaction.attributes.metadata.originalTxId;

  let additionalTransaction =
    originalTransaction.attributes.metadata.additionalTransactions?.[transaction.id.uuid];

  let should =
    originalTxId &&
    originalTxId !== transaction.id.uuid &&
    originalTxId === originalTransaction.id.uuid &&
    additionalTransaction !== undefined;
  return should;
};

export const updateStatusAdditonalTransactions = (originalTransaction, transaction) => {
  let updatedTransactions = {};

  Object.values(originalTransaction.attributes.metadata.additionalTransactions).forEach(
    additionalTransaction => {
      let txStatus =
        transaction.id.uuid === additionalTransaction.id.uuid
          ? TX_STATUS.PAID
          : additionalTransaction.attributes.metadata.paymentDetails?.txStatus;
      let txType = additionalTransaction.attributes.metadata.paymentDetails?.txType;
      updatedTransactions[additionalTransaction.id.uuid] = {
        txId: additionalTransaction.id.uuid,
        txStatus: txStatus,
        txType: txType,
      };
    }
  );
  return updatedTransactions;
};
