import React from 'react';
import css from './CustomSpinner.module.css';

const CustomSpinner = () => {
  return (
    <div className={css.wholeContainer}>
      <div className={css.spinnerFullScreen}></div>
      <div className={css.loadingSpinner}></div>
    </div>
  );
};

export default CustomSpinner;
