import unionWith from 'lodash/unionWith';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { formatDateStringToUTC, getExclusiveEndDate } from '../../util/dates';
import { parse } from '../../util/urlHelpers';
import config from '../../config';
import { fetchCurrentUser } from '../../ducks/user.duck';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
// Default number is 24.
const RESULT_PAGE_SIZE = 24;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_SUCCESS4 = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS4';
export const SEARCH_LISTINGS_SUCCESS1 = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS1';
export const SEARCH_LISTINGS_SUCCESS2 = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS2';
export const SEARCH_LISTINGS_SUCCESS3 = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS3';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const GET_FAVOURITES_REQUEST = 'app/SearchPage/GET_FAVOURITES_REQUEST';
export const GET_FAVOURITES_SUCCESS = 'app/SearchPage/GET_FAVOURITES_SUCCESS';
export const GET_FAVOURITES_ERROR = 'app/SearchPage/GET_FAVOURITES_ERROR';

export const GET_RECOMMENDED_REQUEST = 'app/SearchPage/GET_RECOMMENDED_REQUEST';
export const GET_RECOMMENDED_SUCCESS = 'app/SearchPage/GET_RECOMMENDED_SUCCESS';
export const GET_RECOMMENDED_ERROR = 'app/SearchPage/GET_RECOMMENDED_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

export const CATEGORIES_AND_OMITTED_KEYS = {
  retreat: [
    'price',
    'bounds',
    'pub_specialism',
    'pub_maxEventCapacity',
    'pub_noOfUniqueBedrooms',
    'pub_maxSleepCapacity',
    'pub_listingSector',
    'pub_modeOfEngagement',
  ],
  venue: ['pub_specialism', 'pub_listingSector', 'pub_modeOfEngagement'],
  'certifiedCoach,domainSpecialistCoach,expertFacilitator,inspiredSpeaker': [
    'bounds',
    'pub_venueVibe',
    'pub_maxEventCapacity',
    'pub_noOfUniqueBedrooms',
    'pub_maxSleepCapacity',
    'pub_venueType',
    'pub_alsoIncludes',
  ],
  experiences: [
    'pub_specialism',
    'pub_venueVibe',
    'pub_maxEventCapacity',
    'pub_noOfUniqueBedrooms',
    'pub_maxSleepCapacity',
    'pub_listingSector',
    'pub_alsoIncludes',
    'pub_modeOfEngagement',
  ],
};

export const categoryDisplay = {
  all: 'All',
  venue: 'Venues',
  experiences: 'Away day experiences',
  'certifiedCoach,domainSpecialistCoach,expertFacilitator,inspiredSpeaker': 'Workshops',
  retreat: 'Retreat organisers',
};

export const CATEGORIES_AND_SUCCESS_ACTIONS = {
  venue: SEARCH_LISTINGS_SUCCESS1,
  experiences: SEARCH_LISTINGS_SUCCESS2,
  'certifiedCoach,domainSpecialistCoach,expertFacilitator,inspiredSpeaker': SEARCH_LISTINGS_SUCCESS3,
  retreat: SEARCH_LISTINGS_SUCCESS4,
};

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  pagination4: null,
  pagination1: null,
  pagination2: null,
  pagination3: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  currentPageResultIds4: [],
  currentPageResultIds1: [],
  currentPageResultIds2: [],
  currentPageResultIds3: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
  updateFavouritesInProgress: false,
  reccomendedListings: [],
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_SUCCESS4:
      return {
        ...state,
        currentPageResultIds4: resultIds(payload.data),
        pagination4: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_SUCCESS1:
      return {
        ...state,
        currentPageResultIds1: resultIds(payload.data),
        pagination1: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_SUCCESS2:
      return {
        ...state,
        currentPageResultIds2: resultIds(payload.data),
        pagination2: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_SUCCESS3:
      return {
        ...state,
        currentPageResultIds3: resultIds(payload.data),
        pagination3: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_LISTINGS_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_LISTINGS_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }
    case SEARCH_MAP_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchMapListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };
    case GET_FAVOURITES_REQUEST: {
      return {
        ...state,
        getFavouritesInProgress: true,
      };
    }
    case GET_FAVOURITES_SUCCESS: {
      return {
        ...state,
        getFavouritesInProgress: false,
        favouriteListings: payload,
      };
    }
    case GET_FAVOURITES_ERROR: {
      return {
        ...state,
        getFavouritesInProgress: false,
        getFavouritesError: payload,
      };
    }
    case GET_RECOMMENDED_REQUEST: {
      return {
        ...state,
        getReccomendedInProgress: true,
      };
    }
    case GET_RECOMMENDED_SUCCESS: {
      return {
        ...state,
        getReccomendedInProgress: false,
        reccomendedListings: payload,
      };
    }
    case GET_RECOMMENDED_ERROR: {
      return {
        ...state,
        getReccomendedInProgress: false,
        getFavouritesError: payload,
      };
    }
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = (response, sa) => ({
  type: sa ? sa : SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = response => ({
  type: SEARCH_MAP_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = e => ({
  type: SEARCH_MAP_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const getFavouritesRequest = () => ({
  type: GET_FAVOURITES_REQUEST,
});

export const getFavouritesSuccess = response => ({
  type: GET_FAVOURITES_SUCCESS,
  payload: response,
});

export const getFavouritesError = e => ({
  type: GET_FAVOURITES_ERROR,
  error: true,
  payload: e,
});

export const getRecommendedRequest = () => ({
  type: GET_RECOMMENDED_REQUEST,
});

export const getRecommendedSuccess = response => ({
  type: GET_RECOMMENDED_SUCCESS,
  payload: response,
});

export const getRecommendedError = e => ({
  type: GET_RECOMMENDED_ERROR,
  error: true,
  payload: e,
});

export const searchListings = (searchParams, sa) => (dispatch, getState, sdk) => {
  // the sa refers to searchAction and it's the action success variable
  // that shaould be called when loading is successful.

  // there are four of them and can be found in this object CATEGORIES_AND_SUCCESS_ACTIONS above.

  dispatch(searchListingsRequest(searchParams));

  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
        }
      : {};
  };

  const datesSearchParams = datesParam => {
    const values = datesParam ? datesParam.split(',') : [];
    const hasValues = datesParam && values.length === 2;
    const startDate = hasValues ? values[0] : null;
    const isNightlyBooking = config.bookingUnitType === 'line-item/night';
    const endDate =
      hasValues && isNightlyBooking ? values[1] : hasValues ? getExclusiveEndDate(values[1]) : null;

    return hasValues
      ? {
          start: formatDateStringToUTC(startDate),
          end: formatDateStringToUTC(endDate),
          // Availability can be full or partial. Default value is full.
          availability: 'full',
        }
      : {};
  };

  const { perPage, price, dates, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates);

  const params = {
    ...rest,
    ...priceMaybe,
    ...datesMaybe,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(response, sa));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchMapListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchMapListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchMapListingsError(storableError(e)));
      throw e;
    });
};

export const getRecommendedListings = listingIds => (dispatch, getState, sdk) => {
  dispatch(getRecommendedRequest());
  sdk.listings
    .query({
      ids: listingIds,
      include: ['author', 'images'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
      'limit.images': 1,
    })
    .then(response => {
      dispatch(getRecommendedSuccess(response.data));
    })
    .catch(e => {
      dispatch(getRecommendedError(storableError(e)));
      throw e;
    });
};

// this function is used instead of the load data original function.
// it's triggered from componentDidMount and componentDidUpdate in SearchPage.js

// it read the params like usual but instead it calls the searchListings function 4 times
// once for each listing category.
export const searchListingsByCategory = (search, i, limit) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  let calls = [];
  Object.keys(CATEGORIES_AND_OMITTED_KEYS).map(key => {
    const specificQueryParams = { ...queryParams };
    CATEGORIES_AND_OMITTED_KEYS[key].map(v => {
      delete specificQueryParams[v];
    });
    specificQueryParams.pub_listingType = key;

    const {
      page = 1,
      pageC0,
      pageC1,
      pageC2,
      pageC3,
      address,
      origin,
      sort,
      ...rest
    } = specificQueryParams;
    const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
    const sortMaybe = sort ? { sort } : { sort: 'pub_priority' };
    let correctCatPage = pageC3;
    if (i == 0) {
      correctCatPage = pageC0;
    } else if (i == 1) {
      correctCatPage = pageC1;
    } else if (i == 2) {
      correctCatPage = pageC2;
    }

    const finalCatPage = correctCatPage ? correctCatPage : page;

    calls.push({
      data: {
        ...rest,
        ...originMaybe,
        ...sortMaybe,
        page: finalCatPage,
        perPage: limit ? limit : RESULT_PAGE_SIZE,
        include: ['author', 'images'],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
        'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
        'limit.images': 1,
      },
      action: CATEGORIES_AND_SUCCESS_ACTIONS[key],
    });
  });

  return searchListings(calls[i].data, calls[i].action);
};
