import React, { Component, useCallback, useEffect, useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { confirmCardPayment, retrievePaymentIntent } from '../../ducks/stripe.duck';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useStripe, useElements, AddressElement, PaymentElement } from '@stripe/react-stripe-js';

import { CustomSpinner, PrimaryButton } from '../../components';

import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';

import css from './StripePaymentForm.module.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const StripePaymentForm = ({
  onSubmit,
  inProgress,
  onUpdateBillingAddress,
  onStripeInitialized,
  clientSecret,
  stripeMessage,
}) => {
  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  };

  if (!clientSecret) {
    return <div />;
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm
        onSubmit={onSubmit}
        inProgress={inProgress}
        onStripeInitialized={onStripeInitialized}
        onUpdateBillingAddress={onUpdateBillingAddress}
        stripeMessage={stripeMessage}
      />
    </Elements>
  );
};

const CheckoutForm = ({
  onSubmit,
  inProgress,
  onStripeInitialized,
  onUpdateBillingAddress,
  stripeMessage,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [disabled, setDisabled] = useState(true);
  const [billingDetails, setBillingDetails] = useState(null);
  const [isCustomLoading, setIsCustomLoading] = useState(false);

  useEffect(() => {
    if (stripe) {
      onStripeInitialized(stripe);
    }
  }, [stripe]);

  const handleOnChangeAddressElement = async event => {
    if (event.complete) {
      const address = event.value.address;
      await onUpdateBillingAddress(address, true);
      setBillingDetails(event.value);
      setDisabled(false);
    } else {
      onUpdateBillingAddress(null, true);
      setBillingDetails(null);
      setDisabled(true);
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      setDisabled(true);
      return;
    }
    setDisabled(false);
    setIsCustomLoading(true);

    // Trigger form validation and wallet collection
    const { error } = await elements.submit();
    if (error) {
      setIsCustomLoading(false);
      return;
    }

    const newTx = await onUpdateBillingAddress(billingDetails.address, false);
    await onSubmit({ stripe, elements, billingDetails, paymentIntentTx: newTx });
    setIsCustomLoading(false);
  };

  return (
    <>
      {(isCustomLoading || inProgress) && <CustomSpinner />}
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <AddressElement options={{ mode: 'billing' }} onChange={handleOnChangeAddressElement} />
        <ConfirmButton stripeMessage={stripeMessage} disabled={disabled} />
      </form>
    </>
  );
};

const ConfirmButton = ({ stripeMessage, disabled }) => {
  return (
    <PrimaryButton
      className={css.submitButton}
      type="submit"
      // inProgress={submitInProgress}
      disabled={disabled}
    >
      {disabled ? 'Final amount calculated after entering billing address' : stripeMessage}
    </PrimaryButton>
  );
};

StripePaymentForm.defaultProps = {};

StripePaymentForm.propTypes = {};

export default injectIntl(StripePaymentForm);
