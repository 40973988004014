import { addDays, isAfter } from 'date-fns';
const PROPOSAL_EXPIRY_DAYS = 7;

export const getProposalExpiryDate = proposal => {
  if (!proposal) {
    return null;
  }
  const { createdAt, expiredAt } = proposal;
  return expiredAt
    ? new Date(expiredAt)
    : createdAt
    ? addDays(new Date(createdAt), PROPOSAL_EXPIRY_DAYS)
    : null;
};

export const generateProposalExpiryISODate = createdAt => {
  return createdAt ? addDays(new Date(createdAt), PROPOSAL_EXPIRY_DAYS).toISOString() : null;
};

export const isProposalExpired = (proposal, activeProposalId) => {
  if (!proposal) {
    return true;
  }
  const { proposalId, acceptedAt, bookingStart } = proposal;
  const expiryDate = getProposalExpiryDate(proposal);
  const now = new Date();

  // expired - if not the active proposal anymore
  if (activeProposalId && activeProposalId !== proposalId) {
    return true;
  }

  // not expired - if already accepted
  if (acceptedAt) {
    return false;
  }

  // expired - if now is after expiryDate or now is after booking start
  return isAfter(now, expiryDate) || isAfter(now, bookingStart);
};

export const expireAllProposals = (proposals, expiredAt) => {
  if (!proposals) {
    return {};
  }

  let updatedProposals = { ...proposals };

  for (const proposalId in updatedProposals) {
    if (
      updatedProposals.hasOwnProperty(proposalId) &&
      typeof updatedProposals[proposalId] === 'object' &&
      updatedProposals[proposalId] !== null
    ) {
      if (
        !updatedProposals[proposalId].hasOwnProperty('expiredAt') ||
        isAfter(new Date(updatedProposals[proposalId].expiredAt), new Date(expiredAt))
      ) {
        updatedProposals[proposalId].expiredAt = expiredAt;
      }
    }
  }

  return updatedProposals;
};

export const acceptProposal = (proposals, activeProposal, acceptedAt) => {
  if (!proposals || !activeProposal) {
    return { proposals, activeProposal };
  }

  const updatedActiveProposal = {
    ...activeProposal,
    acceptedAt: acceptedAt.toISOString(),
  };

  const updatedProposals = {
    ...proposals,
    [activeProposal.proposalId]: {
      ...updatedActiveProposal,
    },
  };

  return { proposals: updatedProposals, activeProposal: updatedActiveProposal };
};
