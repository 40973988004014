import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import Decimal from 'decimal.js';
import { formatMoney } from '../../util/currency';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  propTypes,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_CUSTOMER_COMMISSION_VAT,
  LINE_ITEM_PROVIDER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION_VAT,
} from '../../util/types';

import css from './BookingBreakdown.module.css';

const { Money } = sdkTypes;

/**
 * Checks if line item represents commission
 */
const isCommission = lineItem => {
  return (
    lineItem.code === LINE_ITEM_PROVIDER_COMMISSION ||
    lineItem.code === LINE_ITEM_PROVIDER_COMMISSION_VAT ||
    lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION ||
    lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION_VAT
  );
};

/**
 * Returns non-commission, non-reversal line items
 */
const nonCommissionNonReversalLineItems = lineItems => {
  return lineItems.filter(item => !isCommission(item) && !item.reversal);
};

/**
 * Checks if a transaction has a commission line-item for
 * a given user role.
 */
const txHasCommission = (lineItems, userRole) => {
  let commissionLineItem = null;
  if (!lineItems) {
    return false;
  }

  if (userRole === 'customer') {
    commissionLineItem = lineItems.find(item => item.code === LINE_ITEM_CUSTOMER_COMMISSION);
  } else if (userRole === 'provider') {
    commissionLineItem = lineItems.find(item => item.code === LINE_ITEM_PROVIDER_COMMISSION);
  }
  return !!commissionLineItem;
};

const LineItemSubTotalMaybe = ({ lineItems, unitType, userRole, calcValue }) => {
  const refund = lineItems.find(item => item.code === unitType && item.reversal);

  // Show unit purchase line total (unit price * quantity) as a subtotal.
  // PLEASE NOTE that this assumes that the transaction doesn't have other
  // line item types than the defined unit type (e.g. week, month, year).
  const showSubTotal = txHasCommission(lineItems, userRole) || refund;

  // all non-reversal, non-commission line items
  const subTotalLineItems = nonCommissionNonReversalLineItems(lineItems);

  if (subTotalLineItems?.length <= 0 || !showSubTotal) {
    return null;
  }

  return (
    <>
      <hr className={css.totalDivider} />
      <div className={css.subTotalLineItem}>
        <span className={css.itemLabel}>
          <strong>
            <FormattedMessage id="BookingBreakdown.subTotal" />
          </strong>
        </span>
        <span className={css.itemValue}>{calcValue(subTotalLineItems)}</span>
      </div>
    </>
  );
};

LineItemSubTotalMaybe.propTypes = {
  // transaction: propTypes.transaction.isRequired,
  // userRole: string.isRequired,
  // intl: intlShape.isRequired,
};

export default LineItemSubTotalMaybe;
