import React, { useState, useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
} from '../../util/validators';
import {
  Form,
  LocationAutocompleteInputField,
  Button,
  FieldTextInput,
  Modal,
} from '../../components';

import css from './EditListingLocationForm.module.css';

const identity = v => v;

export const EditListingLocationFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        initialValues,
        distancing,
        setDistancing,
        isVE,
      } = formRenderProps;

      const titleRequiredMessage = intl.formatMessage({ id: 'EditListingLocationForm.address' });
      const addressPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressPlaceholder',
      });
      const addressRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressRequired',
      });
      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressNotRecognized',
      });

      const optionalText = intl.formatMessage({
        id: 'EditListingLocationForm.optionalText',
      });

      const buildingMessage = intl.formatMessage(
        { id: 'EditListingLocationForm.building' },
        { optionalText: optionalText }
      );
      const buildingPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.buildingPlaceholder',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const originalDistanceState = {
        parent: '',
        title: '',
        desc: '',
      };

      const [isOpen, setIsOpen] = useState(false);
      const [distance, setDistance] = useState(originalDistanceState);

      useEffect(() => {
        if (initialValues && initialValues.distances?.length > 0) {
          setDistancing(initialValues.distances);
        }
      }, [initialValues]);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          <LocationAutocompleteInputField
            className={css.locationAddress}
            inputClassName={css.locationAutocompleteInput}
            iconClassName={css.locationAutocompleteInputIcon}
            predictionsClassName={css.predictionsRoot}
            validClassName={css.validLocation}
            autoFocus
            name="location"
            label={titleRequiredMessage}
            placeholder={addressPlaceholderMessage}
            useDefaultPredictions={false}
            format={identity}
            valueFromForm={values.location}
            validate={composeValidators(
              autocompleteSearchRequired(addressRequiredMessage),
              autocompletePlaceSelected(addressNotRecognizedMessage)
            )}
          />

          {isVE && (
            <FieldTextInput
              className={css.building}
              type="text"
              name="building"
              id="building"
              label={buildingMessage}
              placeholder={buildingPlaceholderMessage}
            />
          )}

          {isVE ? (
            <>
              <br />
              <div className={css.distancesHeader}>Distances</div>
              <div>
                Let customers know how far you are from the nearest public transport, airport or
                large city.
              </div>
              {distancing?.map(dc => {
                return (
                  <div className={css.rateCard}>
                    <div className={css.vColored}>
                      <strong>{dc.parent}</strong>
                    </div>
                    <div>
                      <strong>{dc.title}</strong>: {dc.desc}
                    </div>
                    <div className={css.rateActions}>
                      <div
                        className={css.editRate}
                        onClick={() => {
                          setDistance(dc);
                          setIsOpen(true);
                        }}
                      >
                        Edit
                      </div>
                      <div
                        className={css.removeRate}
                        onClick={() => {
                          setDistancing(distancing.filter(r => r.id !== dc.id));
                        }}
                      >
                        Remove
                      </div>
                    </div>
                  </div>
                );
              })}

              <Button
                type="button"
                className={css.addRateButton}
                onClick={() => {
                  setDistance(originalDistanceState);
                  setIsOpen(true);
                }}
              >
                Add distance
              </Button>
            </>
          ) : null}

          <Modal
            id="EditListingPricingForm.editDistancing"
            isOpen={isOpen}
            onClose={() => {
              setIsOpen(false);
            }}
            usePortal
            onManageDisableScrolling={() => {}}
          >
            <label>
              Location
              <select
                name="parent"
                value={distance.parent}
                onChange={e => setDistance({ ...distance, parent: e.target.value })}
              >
                <option value="">Choose...</option>
                <option value="Train station">Train station</option>
                <option value="Airport">Airport</option>
                <option value="Major city">Major city</option>
              </select>
            </label>
            <br />
            <label>
              Location name
              <input
                type="text"
                name="title"
                placeholder="Add a location name"
                value={distance.title}
                onChange={e => setDistance({ ...distance, title: e.target.value })}
              ></input>
            </label>
            <br />
            <label>
              Description
              <input
                type="text"
                placeholder="How far is it from the selected location"
                name="desc"
                value={distance.desc}
                onChange={e => setDistance({ ...distance, desc: e.target.value })}
              ></input>
            </label>
            <Button
              type="button"
              className={css.addRateButton}
              disabled={!distance || !distance.parent || !distance.desc}
              onClick={() => {
                if (distance.id) {
                  setDistancing(
                    distancing.map(r => {
                      if (distance.id == r.id) {
                        return distance;
                      } else {
                        return r;
                      }
                    })
                  );
                } else {
                  setDistancing([...distancing, { ...distance, id: Date.now().toString() }]);
                }
                setIsOpen(false);
              }}
            >
              Save
            </Button>
          </Modal>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingLocationFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
};

EditListingLocationFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingLocationFormComponent);
