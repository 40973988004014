import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import fileIcon from '../../../assets/icons/fileIcon.png';
import css from '../ActivityFeed.module.css';

const File = props => {
  const { file, formattedDate } = props;
  return (
    <div className={css.message}>
      <div>
        <div
          className={css.messageContent}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ marginRight: '20px' }}>
            <img src={fileIcon} />
          </div>

          <div>
            <div style={{ marginBottom: '8px', fontWeight: '600' }}>{file.file_desc}</div>
            <a href={file.file_URL} target="_blank">
              <FormattedMessage id="TransactionPage.ActivityFeed.downloadFileFooter" />
            </a>
          </div>
        </div>
        <p className={css.messageDate}>{formattedDate}</p>
      </div>
    </div>
  );
};

export default File;
