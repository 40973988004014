import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingEnquireButton.module.css';

// TODO: isOwnListing is disconsidered for now
const ListingEnquireButton = ({ listing, isOwnListing, onToggleEnquiryModal }) => {
  const { selectedWorktripp } = useSelector(state => state.Worktripp);

  const listingInSelectedWorktripp = useMemo(
    () => selectedWorktripp?.listings?.find(ls => ls.listing_id === listing?.id?.uuid),
    [selectedWorktripp, listing?.id?.uuid]
  );

  const buttonMessage = useMemo(() => {
    if (isOwnListing) {
      return <FormattedMessage id="ListingPage.ownListingButton" />;
    }

    return listingInSelectedWorktripp?.tx_id === 'NOT CONTACTED' ? (
      <FormattedMessage id="ListingPage.enquireWorktripp" />
    ) : (
      <FormattedMessage id="ListingPage.worktrippEnquired" />
    );
  }, [listingInSelectedWorktripp, isOwnListing]);

  const onClickButton = () => {
    if (!selectedWorktripp?._id || !listingInSelectedWorktripp) {
      return;
    }

    if (listingInSelectedWorktripp?.tx_id === 'NOT CONTACTED') {
      onToggleEnquiryModal();
    } else {
      window.location.assign(`/order/${listingInSelectedWorktripp.tx_id}/details`);
    }
  };

  if (!selectedWorktripp?._id || !listingInSelectedWorktripp) {
    return <div />;
  }

  return (
    <Button rootClassName={css.enquireButton} disabled={isOwnListing} onClick={onClickButton}>
      {buttonMessage}
    </Button>
  );
};

export default ListingEnquireButton;
