import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  Button,
  Form,
  FieldCurrencyInput,
  FieldTextInput,
  FieldCheckbox,
  Modal,
} from '../../components';
import css from './EditListingPricingForm.module.css';

const { Money } = sdkTypes;

export const EditListingPricingFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        rates,
        setRates,
        isVE,
      } = formRenderProps;

      const unitType = config.bookingUnitType;
      const isNightly = unitType === LINE_ITEM_NIGHT;
      const isDaily = unitType === LINE_ITEM_DAY;

      const translationKey = isNightly
        ? 'EditListingPricingForm.pricePerNight'
        : isDaily
        ? 'EditListingPricingForm.pricePerDay'
        : 'EditListingPricingForm.pricePerUnit';

      const pricePerUnitMessage = intl.formatMessage({
        id: translationKey,
      });

      const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.priceInputPlaceholder',
      });

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};

      const originalRateState = {
        parent: '',
        title: '',
        desc: '',
      };
      const [isOpen, setIsOpen] = useState(false);
      const [rate, setRate] = useState({});

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}
          <FieldCurrencyInput
            id="price"
            name="price"
            className={css.priceInput}
            autoFocus
            label={pricePerUnitMessage}
            placeholder={pricePlaceholderMessage}
            currencyConfig={config.currencyConfig}
            validate={priceValidators}
          />

          <FieldCheckbox
            id="openPriceOptions"
            name="openPriceOptions"
            label="Price options"
            type="checkbox"
          />
          {values.openPriceOptions && (
            <>
              <FieldTextInput
                id="vatValue"
                name="vatValue"
                type="number"
                label="VAT percentage (optional)"
                placeholder="For ex: 10."
                className={css.priceInput}
              />
              <FieldTextInput
                id="priceIncludes"
                name="priceIncludes"
                type="text"
                label="Indicative price includes (optional)"
                placeholder="For ex: Standard double room only..."
                className={css.priceInput}
              />
              <FieldTextInput
                id="priceExcludes"
                name="priceExcludes"
                type="text"
                label="Indicative price excludes (optional)"
                placeholder="For ex: Catering..."
              />{' '}
            </>
          )}

          {isVE ? (
            <>
              <br />
              <h2>Rates</h2>
              {rates?.map(rt => {
                return (
                  <div className={css.rateCard}>
                    <div className={css.vColored}>
                      <strong>{rt.parent}</strong>
                    </div>
                    <div>
                      <strong>{rt.title}</strong>: {rt.desc}
                    </div>
                    <div className={css.rateActions}>
                      <div
                        className={css.editRate}
                        onClick={() => {
                          setRate(rt);
                          setIsOpen(true);
                        }}
                      >
                        Edit
                      </div>
                      <div
                        className={css.removeRate}
                        onClick={() => {
                          setRates(rates.filter(r => r.id !== rt.id));
                        }}
                      >
                        Remove
                      </div>
                    </div>
                  </div>
                );
              })}
              <Button
                type="button"
                className={css.addRateButton}
                onClick={() => {
                  setRate(originalRateState);
                  setIsOpen(true);
                }}
              >
                Add more
              </Button>
            </>
          ) : null}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>

          <Modal
            id="EditListingPricingForm.editRate"
            isOpen={isOpen}
            onClose={() => {
              setIsOpen(false);
            }}
            usePortal
            onManageDisableScrolling={() => {}}
          >
            <label>
              Seasonality
              <select
                name="parent"
                value={rate.parent}
                onChange={e => setRate({ ...rate, parent: e.target.value })}
              >
                <option value="">Choose...</option>
                <option value="High Season">High Season</option>
                <option value="Low Season">Low Season</option>
              </select>
            </label>
            <br />
            <label>
              Rate type
              <input
                type="text"
                name="title"
                value={rate.title}
                onChange={e => setRate({ ...rate, title: e.target.value })}
              ></input>
            </label>
            <br />
            <label>
              Add price range for this rate
              <input
                type="text"
                name="desc"
                value={rate.desc}
                onChange={e => setRate({ ...rate, desc: e.target.value })}
              ></input>
            </label>

            <Button
              type="button"
              className={css.addRateButton}
              disabled={!rate || !rate.parent || !rate.title || !rate.desc}
              onClick={() => {
                if (rate.id) {
                  setRates(
                    rates.map(r => {
                      if (rate.id == r.id) {
                        return rate;
                      } else {
                        return r;
                      }
                    })
                  );
                } else {
                  setRates([...rates, { ...rate, id: Date.now().toString() }]);
                }
                setIsOpen(false);
              }}
            >
              Save
            </Button>
          </Modal>
        </Form>
      );
    }}
  />
);

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
