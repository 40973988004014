/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import React, { useMemo, useState } from 'react';
import { oneOf, string } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { getNextPendingPaymentTransaction, TX_TYPES } from '../../util/transactionHelpers';

import {
  LineItemBookingPeriod,
  LineItemBasePriceMaybe,
  LineItemBasePriceVatMaybe,
  LineItemCommissionNotes,
  LineItemCustomerCommissionMaybe,
  LineItemCustomerCommissionRefundMaybe,
  LineItemCustomerCommissionVatMaybe,
  LineItemProviderCommissionMaybe,
  LineItemProviderCommissionRefundMaybe,
  LineItemProviderCommissionVatMaybe,
  LineItemRefundMaybe,
  LineItemSubTotalMaybe,
  LineItemTotalPrice,
  LineItemUnknownItemsMaybe,
  LineItemUnitsMaybe,
} from './index';

import css from './BookingBreakdown.module.css';
const { Money } = sdkTypes;

const getSpeculatedTransaction = (originalTx, speculatedTx) => {
  return speculatedTx && speculatedTx.id?.uuid === originalTx.id?.uuid ? speculatedTx : originalTx;
};

const getSpeculatedLineItems = (originalTx, speculatedTx) => {
  let transaction =
    speculatedTx && speculatedTx.id?.uuid === originalTx.id?.uuid ? speculatedTx : originalTx;
  return transaction.attributes?.lineItems ?? [];
};

const sumLineItems = (originalTransaction, speculatedTransaction) => {
  let txLineItems = getSpeculatedLineItems(originalTransaction, speculatedTransaction);
  let additionalTransactions = originalTransaction.attributes.metadata.additionalTransactions;

  if (!additionalTransactions || Object.keys(additionalTransactions).length === 0) {
    return txLineItems;
  }

  let otherTxLineItems = Object.values(additionalTransactions)
    .map(otherTx => {
      return getSpeculatedLineItems(otherTx, speculatedTransaction);
    })
    .flat(1);

  return [...txLineItems, ...otherTxLineItems];
};

/**
 * BookingBreakdown contains different line items:
 *
 * LineItemBookingPeriod: prints booking start and booking end types. Prop dateType
 * determines if the date and time or only the date is shown
 *
 * LineItemUnitsMaybe: if he unitType is line-item/unit print the name and
 * quantity of the unit
 *
 * LineItemBasePriceMaybe: prints the base price calculation for the listing, e.g.
 * "$150.00 * 2 nights $300"
 *
 * LineItemUnitPriceMaybe: prints just the unit price, e.g. "Price per night $32.00".
 * This line item is not used by default in the BookingBreakdown.
 *
 * LineItemUnknownItemsMaybe: prints the line items that are unknown. In ideal case there
 * should not be unknown line items. If you are using custom pricing, you should create
 * new custom line items if you need them.
 *
 * LineItemSubTotalMaybe: prints subtotal of line items before possible
 * commission or refunds
 *
 * LineItemRefundMaybe: prints the amount of refund
 *
 * LineItemCustomerCommissionMaybe: prints the amount of customer commission
 * The default transaction process used by FTW doesn't include the customer commission.
 *
 * LineItemCustomerCommissionRefundMaybe: prints the amount of refunded customer commission
 *
 * LineItemProviderCommissionMaybe: prints the amount of provider commission
 *
 * LineItemProviderCommissionRefundMaybe: prints the amount of refunded provider commission
 *
 * LineItemTotalPrice: prints total price of the transaction
 *
 */

export const BookingBreakdownComponent = props => {
  const {
    rootClassName,
    className,
    userRole,
    unitType,
    hidePaymentsBreakdown,
    transaction,
    speculatedTransaction,
    booking,
    intl,
    dateType,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const nextPendingTransaction = useMemo(
    () => speculatedTransaction ?? getNextPendingPaymentTransaction(transaction),
    [speculatedTransaction, transaction]
  );

  const allLineItems = useMemo(() => sumLineItems(transaction, speculatedTransaction), [
    transaction,
    speculatedTransaction,
  ]);
  const hasRefundItems = allLineItems.find(item => item.reversal);

  const hasAdditionalPayments =
    transaction.attributes?.metadata?.additionalTransactions &&
    Object.values(transaction.attributes.metadata.additionalTransactions).length > 0;

  const calcValue = lineItems => {
    const totalAmount = lineItems
      ? lineItems.map(item => item.lineTotal.amount).reduce((sum, acc) => sum + acc, 0)
      : 0;

    return formatMoney(intl, new Money(totalAmount, 'GBP'));
  };

  const renderPaymentBreakdown = () => {
    if (!hasAdditionalPayments) {
      return <div />;
    }
    if (hidePaymentsBreakdown && hasAdditionalPayments) {
      return (
        <LineItemsBreakdown
          collapsed={!!nextPendingTransaction}
          hasRefundItems={hasRefundItems}
          isFullPayment
          highlightPayment={false}
          lineItems={allLineItems}
          userRole={userRole}
          intl={intl}
          calcValue={calcValue}
          unitType={unitType}
          transaction={transaction}
        />
      );
    }

    return (
      <div>
        <strong>Payments breakdown</strong>

        <LineItemsBreakdown
          hasRefundItems={hasRefundItems}
          lineItems={transaction.attributes.lineItems}
          userRole={userRole}
          intl={intl}
          calcValue={calcValue}
          unitType={unitType}
          transaction={transaction}
        />

        {Object.values(transaction.attributes?.metadata?.additionalTransactions).map(
          additionalTransaction => {
            let updatedTx = getSpeculatedTransaction(additionalTransaction, speculatedTransaction);
            return (
              <LineItemsBreakdown
                key={additionalTransaction?.id.uuid}
                hasRefundItems={hasRefundItems}
                lineItems={updatedTx.attributes?.lineItems}
                userRole={userRole}
                intl={intl}
                calcValue={calcValue}
                unitType={unitType}
                transaction={updatedTx}
              />
            );
          }
        )}
        <LineItemsBreakdown
          collapsed={nextPendingTransaction}
          hasRefundItems={hasRefundItems}
          isFullPayment
          highlightPayment
          lineItems={allLineItems}
          userRole={userRole}
          intl={intl}
          calcValue={calcValue}
          unitType={unitType}
          transaction={transaction}
        />
      </div>
    );
  };

  return (
    <div className={classes}>
      <LineItemBookingPeriod booking={booking} unitType={unitType} dateType={dateType} />

      {nextPendingTransaction ? (
        <LineItemsBreakdown
          collapsed={false}
          hasRefundItems={hasRefundItems}
          highlightPayment
          lineItems={nextPendingTransaction?.attributes?.lineItems}
          userRole={userRole}
          intl={intl}
          calcValue={calcValue}
          unitType={unitType}
          transaction={nextPendingTransaction}
        />
      ) : (
        <LineItemsBreakdown
          collapsed={false}
          hasRefundItems={hasRefundItems}
          isFullPayment
          highlightPayment
          lineItems={allLineItems}
          userRole={userRole}
          intl={intl}
          calcValue={calcValue}
          unitType={unitType}
          transaction={transaction}
        />
      )}
      <LineItemCommissionNotes lineItems={allLineItems} userRole={userRole} />

      {renderPaymentBreakdown()}
    </div>
  );
};

export const getTransactionPaymentDetails = (transaction, isFullPayment) => {
  if (isFullPayment) {
    return { type: 'Booking total', txStatus: '' };
  }
  const { txType, txStatus, rate } = transaction?.attributes?.metadata?.paymentDetails || {};
  let type = txType && TX_TYPES[txType] ? TX_TYPES[txType].value : 'Payment';
  return { type, status: txStatus, rate };
};

const LineItemsBreakdown = ({
  isFullPayment,
  hasRefundItems,
  highlightPayment,
  collapsed = true,
  lineItems,
  userRole,
  intl,
  calcValue,
  unitType,
  transaction,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);
  const isProvider = userRole === 'provider';
  const isCustomer = userRole === 'customer';
  const { type, status, rate } = getTransactionPaymentDetails(transaction, isFullPayment);

  // TODO: this is a bit of a hack for now

  const statusShown = hasRefundItems ? 'refunded' : status;

  const onClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  const calcActualTxValue = oriValue => {
    const amount = oriValue.amount;
    return formatMoney(intl, new Money(amount, 'GBP'));
  };

  if (!lineItems) {
    return <div />;
  }

  return (
    <div
      onClick={onClick}
      style={{
        background: highlightPayment ? '#c2cfcc' : 'lightGrey',
        color: 'black',
        padding: 10,
        borderRadius: 10,
        marginBottom: 10,
      }}
    >
      <div style={{ justifyContent: 'space-between', display: 'flex' }}>
        <strong style={{ fontSize: isCollapsed ? 14 : 16 }}>
          {type}
          {rate < 1 ? ` (${Math.round(rate * 100)}%) ` : ''}
          {statusShown ? ` - ${statusShown}` : ''}
        </strong>
        <span style={{ fontSize: 12 }}>{isCollapsed ? 'See details' : ''}</span>
      </div>
      {!isCollapsed && (
        <div>
          <LineItemUnitsMaybe lineItems={lineItems} unitType={unitType} calcValue={calcValue} />
          <LineItemBasePriceMaybe lineItems={lineItems} unitType={unitType} calcValue={calcValue} />
          <LineItemBasePriceVatMaybe
            lineItems={lineItems}
            unitType={unitType}
            calcValue={calcValue}
          />
          <LineItemUnknownItemsMaybe
            transaction={transaction}
            lineItems={lineItems}
            isProvider={isProvider}
            intl={intl}
            calcActualTxValue={calcActualTxValue}
          />
          <LineItemSubTotalMaybe lineItems={lineItems} userRole={userRole} calcValue={calcValue} />
          <LineItemCustomerCommissionMaybe
            lineItems={lineItems}
            isCustomer={isCustomer}
            calcValue={calcValue}
          />
          <LineItemCustomerCommissionVatMaybe
            lineItems={lineItems}
            isCustomer={isCustomer}
            calcValue={calcValue}
          />
          <LineItemCustomerCommissionRefundMaybe
            transaction={transaction}
            isCustomer={isCustomer}
            intl={intl}
            calcActualTxValue={calcActualTxValue}
          />
          <LineItemProviderCommissionMaybe
            lineItems={lineItems}
            isProvider={isProvider}
            calcValue={calcValue}
          />
          <LineItemProviderCommissionVatMaybe
            lineItems={lineItems}
            isProvider={isProvider}
            calcValue={calcValue}
          />
          <LineItemProviderCommissionRefundMaybe
            transaction={transaction}
            isProvider={isProvider}
            intl={intl}
            calcActualTxValue={calcActualTxValue}
          />
          <LineItemRefundMaybe
            transaction={transaction}
            intl={intl}
            isProvider={isProvider}
            calcActualTxValue={calcActualTxValue}
          />
          {transaction && (
            <LineItemTotalPrice
              transaction={transaction}
              lineItems={lineItems}
              userRole={userRole}
              calcValue={calcValue}
            />
          )}
        </div>
      )}
    </div>
  );
};

BookingBreakdownComponent.defaultProps = { rootClassName: null, className: null, dateType: null };

BookingBreakdownComponent.propTypes = {
  rootClassName: string,
  className: string,

  userRole: oneOf(['customer', 'provider']).isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  transaction: propTypes.transaction.isRequired,
  booking: propTypes.booking.isRequired,
  dateType: propTypes.dateType,

  // from injectIntl
  intl: intlShape.isRequired,
};

const BookingBreakdown = injectIntl(BookingBreakdownComponent);

BookingBreakdown.displayName = 'BookingBreakdown';

export default BookingBreakdown;
