import React from 'react';
import FieldTextInput from '../../FieldTextInput/FieldTextInput';
import FieldDateRangeInput from '../../FieldDateRangeInput/FieldDateRangeInput';
import FieldCurrencyInput from '../../FieldCurrencyInput/FieldCurrencyInput';
import MultipleChoice from './MultipleChoice';
import { currencyConfiguration } from '../../../currency-config';
import { goalsLabels } from '../../../marketplace-custom-config';
import { DEFAULT_LOCATIONS } from '../../../default-location-searches';
import { FormattedMessage } from 'react-intl';

import ownCss from './WorktrippOverViewForm.module.css';

const WorktrippOverViewForm = props => {
  const { validators, css, goals, setGoals, location, setLocation } = props;
  const dateRequired = <FormattedMessage id="ChatActionsSection.dateRequired" />;
  const teamSizeRequired = <FormattedMessage id="ChatActionsSection.teamSizeRequired" />;
  const titleRequired = <FormattedMessage id="ChatActionsSection.titleRequired" />;
  const budgetRequired = <FormattedMessage id="ChatActionsSection.budgetRequired" />;

  const getStyledLabel = label => <div className={ownCss.formSectionTitle}>{label}</div>;

  return (
    <div>
      <MultipleChoice
        css={css}
        title="Our goals for this offsite are..."
        titleClassName={ownCss.formSectionTitle}
        items={goalsLabels}
        selectedValues={goals}
        setSelectedValues={setGoals}
      />

      <FieldTextInput
        type="number"
        className={css.field}
        name="noOfParticipants"
        id="noOfParticipants"
        label={getStyledLabel("We're booking for a team of...")}
        placeholder="15"
        validate={validators.required(teamSizeRequired)}
      />
      <FieldDateRangeInput
        customLabel={getStyledLabel("We're looking at dates...")}
        className={ownCss.bookingDates}
        name="wtRange"
        id="wtRange"
        minDays={0}
        validate={validators.required(dateRequired)}
      />

      <div style={{ zIndex: 10 }}>
        <FieldCurrencyInput
          className={css.field}
          name="budgetPerPerson"
          id="budgetPerPerson"
          label={getStyledLabel('The per-person budget is... ')}
          placeholder="£250"
          currencyConfig={currencyConfiguration('GBP')}
          validate={validators.required(budgetRequired)}
        />
      </div>
      <MultipleChoice
        css={css}
        singleChoice
        title="What location are you considering?"
        titleClassName={ownCss.formSectionTitle}
        items={DEFAULT_LOCATIONS}
        selectedValues={location}
        setSelectedValues={setLocation}
      />

      <FieldTextInput
        type="text"
        className={css.field}
        name="title"
        id="title"
        label={getStyledLabel('Give your WorkTripp a name')}
        placeholder="Retreat to.."
        validate={validators.required(titleRequired)}
      />
    </div>
  );
};

export default WorktrippOverViewForm;
