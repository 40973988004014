import React from 'react';
import css from './ContactSupport.module.css';

const SUPPORT_EMAIL = 'admin@worktripp.com';

const ContactSupport = ({ subject = 'Hello', body }) => {
  const emailHref = `mailto:${SUPPORT_EMAIL}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;

  return (
    <div className={css.wholeContainer}>
      <a href={emailHref} rel="noopener" target="_blank">
        <strong>{SUPPORT_EMAIL}</strong>
      </a>
    </div>
  );
};

export default ContactSupport;
