import React, { useState, useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, Modal } from '../../components';
import css from './EditListingPoliciesForm.module.css';

export const EditListingPoliciesFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        initialValues,
        setService,
        setListingType,
        allowSwitch,
        onDirectSubmit,
        ensuredCurrentUser,
      } = formRenderProps;

      const { serviceType, listingType } = initialValues;
      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const [openModal, setOpenModal] = useState(false);

      function classNameSelector(value, selectedListing) {
        if (serviceType == value && listingType == selectedListing) {
          return classNames(css.serviceCard, css.selectedCard);
        } else {
          return css.serviceCard;
        }
      }

      function selectService(type, listingType) {
        if (allowSwitch) {
          setService(type);
          setListingType(listingType);
          // uncomment the following line to stop autoRedirect after choose
          onDirectSubmit(type, listingType);
        }
      }

      useEffect(() => {
        if (!ensuredCurrentUser.attributes.profile.bio) {
          setOpenModal(true);
        }
      }, [ensuredCurrentUser]);

      return (
        <>
          <Modal
            isOpen={openModal}
            onClose={() => setOpenModal(false)}
            onManageDisableScrolling={() => {}}
          >
            <h2>
              <FormattedMessage id="NoBioModal.title" />
            </h2>
            <p>
              <FormattedMessage id="NoBioModal.text" />
            </p>
            <p>
              Click{' '}
              <a target="_blank" href="/profile-settings" className={css.editProfileLink}>
                Edit Profile
              </a>{' '}
              <FormattedMessage id="NoBioModal.text2" />
            </p>
            <p>
              <FormattedMessage id="NoBioModal.footer" />
            </p>
          </Modal>

          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessage}
            {errorMessageShowListing}

            <div
              className={classNameSelector('CFS')}
              onClick={() => {
                selectService('CFS');
              }}
            >
              <h5 className={css.cfsTitle}>
                <FormattedMessage id="EditListingPoliciesForm.CFS_Title" />
              </h5>
              <p>
                <FormattedMessage id="EditListingPoliciesForm.CFS_Content" />
              </p>
              <span className={css.subtitle}>
                <FormattedMessage id="EditListingPoliciesForm.CFS_subTitle" />
              </span>
            </div>
            <br />
            <div
              className={classNameSelector('VE')}
              onClick={() => {
                selectService('VE');
              }}
            >
              <h5 className={css.veTitle}>
                <FormattedMessage id="EditListingPoliciesForm.VE_Title" />
              </h5>
              <p>
                <FormattedMessage id="EditListingPoliciesForm.VE_Content" />
              </p>
              <span className={css.subtitle}>
                <FormattedMessage id="EditListingPoliciesForm.VE_subTitle" />
              </span>
            </div>
            <br />
            <div
              className={classNameSelector('VE', 'retreat')}
              onClick={() => {
                selectService('VE', 'retreat');
              }}
            >
              <h5 className={css.veTitle}>
                <FormattedMessage id="EditListingPoliciesForm.PR_Title" />
              </h5>
              <p>
                <FormattedMessage id="EditListingPoliciesForm.PR_Content" />
              </p>
              <span className={css.subtitle}>
                <FormattedMessage id="EditListingPoliciesForm.PR_subTitle" />
              </span>
            </div>

            <p className={css.notAllowSwitch}>
              <span>*</span>
              <FormattedMessage id="EditListingPoliciesForm.notAllowSwitch" />
            </p>

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled || !serviceType}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        </>
      );
    }}
  />
);

EditListingPoliciesFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingPoliciesFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPoliciesFormComponent);
