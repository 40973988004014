import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { LINE_ITEM_BASE_PRICE, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const LineItemBasePriceMaybe = ({ lineItems, calcValue }) => {
  const basePriceLineItems = lineItems?.filter(
    item => item.code === LINE_ITEM_BASE_PRICE && !item.reversal
  );

  if (!basePriceLineItems) {
    return null;
  }

  return (
    <div className={css.lineItem}>
      <FormattedMessage id="BookingBreakdown.basePrice" />
      <span className={css.itemValue}>{calcValue(basePriceLineItems)}</span>
    </div>
  );
};

LineItemBasePriceMaybe.propTypes = {};

export default LineItemBasePriceMaybe;
