import React, { useState, useEffect } from 'react';
import { required, composeValidators } from '../../../util/validators';
import ClientTsCard from '../../../components/ClientTsCard/ClientTsCard';
import addClTs from '../../../assets/icons/addClTs.png';
import shutUpArrow from '../../../assets/icons/Dropup.svg';
import { Button, FieldTextInput } from '../../../components';
import css from '../EditListingDescriptionForm.module.css';

const ClientTestimonials = props => {
  const {
    intl,
    values,
    testimonials,
    addTestimonial,
    deleteTestimonial,
    form,
    onImageUpload,
    lastImageId,
    images,
  } = props;

  const clientTestimonials = intl.formatMessage({
    id: 'EditListingDescriptionForm.clientTestimonials',
  });
  const clientTestimonialsText = intl.formatMessage({
    id: 'EditListingDescriptionForm.clientTestimonialsText',
  });

  const clientName = intl.formatMessage({
    id: 'EditListingDescriptionForm.clientName',
  });
  const clientNamePlaceHolder = intl.formatMessage({
    id: 'EditListingDescriptionForm.clientNamePlaceholder',
  });
  const clientNameRequired = clientName + ' is required';

  const testimonialText = intl.formatMessage({
    id: 'EditListingDescriptionForm.testimonialTitle',
  });
  const testimonialInputPlaceholder = intl.formatMessage({
    id: 'EditListingDescriptionForm.testimonialInputPlaceholder',
  });
  const testimonialTextRequired = testimonialText + ' is required';

  const addTestimonials = intl.formatMessage({
    id: 'EditListingDescriptionForm.addTestimonials',
  });

  const [clientTsOpen, setClientTsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [currentFile, setCurrentFile] = useState({});
  const [indexInUse, setIndexInUse] = useState();
  // const [tempTextStorage, setTempTextStorage] = useState();

  // useEffect(() => {
  //     if (lastImageId) {
  //         form.change('imageId', lastImageId);
  //         if (tempTextStorage?.name) {
  //             form.change('clientName', tempTextStorage?.name);
  //         };
  //         if (tempTextStorage?.text) {
  //             form.change('testimonialText', tempTextStorage?.text);
  //         };
  //         setLoading(false);
  //     }
  // }, [lastImageId]);

  // async function handleImageUpload(e) {
  //     const file = e.target.files[0];
  //     if (file) {
  //         setLoading(true);
  //         indexInUse && setCurrentFile({ ...currentFile, indexInUse: '' });
  //         setTempTextStorage({ name: values.clientName, text: values.testimonialText });
  //         onImageUpload({ id: `${file.name}_${Date.now()}`, file })
  //             .then(() => {
  //                 const path = (window.URL || window.webkitURL).createObjectURL(file);
  //                 const correctIndex = indexInUse ? indexInUse : testimonials.length + 1;
  //                 // setIndexInUse(correctIndex);
  //                 setCurrentFile({ ...currentFile, [correctIndex]: path });
  //             })
  //             .catch(() => {
  //                 setLoading(false);
  //             });
  //     };
  // };

  function handleEdit(id) {
    const currentTs = testimonials.find(t => t.id == id);
    // const currentImage = images.find(img => img.id.uuid == currentTs.imageId);
    setIndexInUse(id);
    setClientTsOpen(true);
    form.change('clientName', currentTs.clientName);
    // form.change('imageId', currentTs.imageId);
    // const path = currentImage?.attributes.variants['square-small'].url;
    // setCurrentFile({ ...currentFile, [id]: path ? path : currentFile[id] });
    form.change('testimonialText', currentTs.testimonialText);
  }

  function handleDelete(index) {
    deleteTestimonial(index);
  }

  return (
    <>
      <h1 className={css.clientTestimonials}>{clientTestimonials}</h1>
      <p className={css.clientTestimonialsText}>{clientTestimonialsText}</p>

      {testimonials.length
        ? testimonials
            .sort(function(a, b) {
              return a.id - b.id;
            })
            .map((ts, i) => {
              if (ts.id == indexInUse) return;
              return (
                <ClientTsCard
                  key={i}
                  testimonial={ts}
                  // images={images}
                  // currentFile={currentFile[ts.id]}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                />
              );
            })
        : null}

      {testimonials.length < 3 && !clientTsOpen ? (
        <div
          className={css.addTestimonials}
          onClick={() => {
            setClientTsOpen(true);
            setIndexInUse('');
          }}
        >
          {addTestimonials}
        </div>
      ) : null}

      {clientTsOpen && (
        <div className={css.addTsWrapper}>
          <div className={css.flexWrapper}>
            <strong>
              {values.clientName ? values.clientName : `Customer ${testimonials.length + 1}`}
            </strong>
            <div
              className={css.withCursor}
              onClick={() => {
                setClientTsOpen(false);
              }}
            >
              <img src={shutUpArrow} />
            </div>
          </div>
          <br />
          {loading ? (
            <div>Uploading...</div>
          ) : (
            <label for="imageId">
              <FieldTextInput
                id="clientName"
                name="clientName"
                className={css.description}
                type="text"
                label={clientName}
                placeholder={clientNamePlaceHolder}
                validate={composeValidators(required(clientNameRequired))}
              />
              {/* <img
                            className={currentFile[indexInUse ? indexInUse : testimonials.length + 1] ? css.clientImage : css.uploadImageBtn}
                            src={currentFile[indexInUse ? indexInUse : testimonials.length + 1] ? currentFile[indexInUse ? indexInUse : testimonials.length + 1] : addClTs}
                        />
                  


                        <input
                            id="imageId"
                            name="imageId"
                            className={css.uploadImage}
                            type="file"
                            accept="image/*"
                            placeholder="Upload img"
                            onChange={(e) => { handleImageUpload(e) }}
                        /> */}
              <FieldTextInput
                id="testimonialText"
                name="testimonialText"
                className={css.description}
                type="text"
                label={testimonialText}
                placeholder={testimonialInputPlaceholder}
                validate={composeValidators(required(testimonialTextRequired))}
              />{' '}
            </label>
          )}

          <Button
            className={css.uploadButton}
            type="button"
            disabled={!values.clientName || !values.testimonialText || testimonials.length > 3}
            onClick={() => {
              addTestimonial({
                clientName: values.clientName,
                // imageId: values.imageId,
                testimonialText: values.testimonialText,
                id: indexInUse ? indexInUse : null,
              });
              form.change('clientName', '');
              // form.change('imageId', '');
              form.change('testimonialText', '');
              setClientTsOpen(false);
              setIndexInUse(null);
            }}
          >
            {' '}
            Update{' '}
          </Button>
        </div>
      )}
    </>
  );
};

export default ClientTestimonials;
