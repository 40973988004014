import React, { useState, useEffect } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingDescriptionForm } from '../../forms';
import config from '../../config';

import css from './EditListingDescriptionPanel.module.css';

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    onImageUpload,
    lastImageId,
    images,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, publicData } = currentListing.attributes;
  const {
    serviceType,
    listingType,
    venueVibe,
    industryAccreditationURL,
    industryAccreditationURLTwo,
    venueType,
    maxEventCapacity,
    noOfUniqueBedrooms,
    maxSleepCapacity,
    noOfBathrooms,
    modeOfEngagement,
    clientTestimonials,
    priceIncludes,
    priceExcludes,
  } = publicData;

  const [testimonials, setTestimonials] = useState([]);

  function addTestimonial(data) {
    if (data.id) {
      const holder = [...testimonials];
      const newValues = holder.filter(t => t.id !== data.id);
      setTestimonials([...newValues, data]);
    } else {
      data.id = testimonials.length + 1;
      setTestimonials([...testimonials, data]);
    }
  }

  function deleteTestimonial(id) {
    const holder = [...testimonials];
    const newValues = holder.filter(t => t.id !== id);
    setTestimonials([...newValues]);
  }

  useEffect(() => {
    if (clientTestimonials) {
      const tesimonialsWithIds = clientTestimonials.map((ts, i) => {
        if (ts.id) {
          return ts;
        } else {
          ts.id = i + 1;
          return ts;
        }
      });
      setTestimonials(tesimonialsWithIds);
    } else {
      setTestimonials([]);
    }
  }, [clientTestimonials]);

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle =
    serviceType == 'VE' ? (
      listingType == 'retreat' ? (
        <FormattedMessage id="EditListingDescriptionPanel.createListingTitlePR" />
      ) : (
        <FormattedMessage id="EditListingDescriptionPanel.createListingTitleVE" />
      )
    ) : (
      <FormattedMessage id="EditListingDescriptionPanel.createListingTitleCFS" />
    );
  const cfsText = <FormattedMessage id="EditListingDescriptionPanel.createListingTextCFS" />;
  const veText =
    listingType == 'retreat' ? (
      <FormattedMessage id="EditListingDescriptionPanel.createListingTextPR" />
    ) : (
      <FormattedMessage id="EditListingDescriptionPanel.createListingTextVE" />
    );

  const listingTypeOptions = findOptionsForSelectFilter('type', config.custom.filters);
  const specifiedListingTypeOptions = listingTypeOptions.filter(t => t.parent == serviceType);
  const venueTypeOptions = findOptionsForSelectFilter('venueType', config.custom.filters);
  const venueVibeOptions = findOptionsForSelectFilter('venueVibe', config.custom.filters);
  const modeOfEngagementOptions = findOptionsForSelectFilter(
    'modeOfEngagement',
    config.custom.filters
  );
  const notDraftTitle = title == 'Draft Listing' ? '' : title;

  const notCoachListingType =
    listingType && listingType.includes('Coach') ? 'expertFacilitator' : listingType;

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <p className={css.text}>{serviceType == 'VE' ? veText : cfsText}</p>
      <div style={{ margin: '-28px 0 28px 0' }}>
        Before you get started, please take a look at our{' '}
        <a href="https://www.worktripp.com/terms-of-service" target="_blank">
          Code of Conduct and Service Level Agreement
        </a>
        , which sets out how we work together in the WorkTripp community.
      </div>
      <EditListingDescriptionForm
        className={css.form}
        initialValues={{
          title: notDraftTitle,
          description,
          listingType: notCoachListingType,
          industryAccreditationURL,
          industryAccreditationURLTwo,
          venueType,
          venueVibe,
          maxEventCapacity,
          noOfUniqueBedrooms,
          maxSleepCapacity,
          noOfBathrooms,
          modeOfEngagement,
          priceIncludes,
          priceExcludes,
        }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const {
            title,
            description,
            listingType,
            industryAccreditationURL,
            industryAccreditationURLTwo,
            venueType,
            venueVibe,
            maxEventCapacity,
            noOfUniqueBedrooms,
            maxSleepCapacity,
            noOfBathrooms,
            modeOfEngagement,
          } = values;

          const filteredVenueTypeOptions = venueType
            ?.map(v => {
              const option = venueTypeOptions.find(o => o.key == v);
              if (option?.parent == 'venue' && listingType == 'experiences') {
                return null;
              } else {
                return v;
              }
            })
            .filter(c => c);

          const filteredVenueVibeOptions = venueVibe
            ?.map(c => {
              const vibeOption = venueVibeOptions.find(vv => vv.key == c);
              if (vibeOption?.parent == 'venue' && listingType == 'experiences') {
                return null;
              } else {
                return c;
              }
            })
            .filter(c => c);

          const oldTsImages = publicData.clientTestimonials?.map(ts => ts.imageId) || [];
          const newTsImages = testimonials?.map(ts => ts.imageId) || [];
          const unUsedImages = [];

          oldTsImages.map(ts => {
            // if (!newTsImages.includes(ts)) {
            unUsedImages.push(ts);
            // }
          });

          const updatedImages = images.filter(img => !unUsedImages.includes(img.id.uuid));

          const tsWithoutImages = testimonials.map(ts => {
            delete ts.imageId;
            return ts;
          });

          const updateValues = {
            title: title.trim(),
            description,
            images: updatedImages,
            publicData: {
              listingType,
              industryAccreditationURL,
              priceIncludes,
              priceExcludes,
              industryAccreditationURLTwo,
              venueType: filteredVenueTypeOptions,
              venueVibe: filteredVenueVibeOptions,
              maxEventCapacity: Number(maxEventCapacity),
              noOfUniqueBedrooms: Number(noOfUniqueBedrooms),
              maxSleepCapacity: Number(maxSleepCapacity),
              noOfBathrooms: Number(noOfBathrooms),
              modeOfEngagement,
              clientTestimonials: tsWithoutImages,
            },
          };
          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        listingTypes={specifiedListingTypeOptions}
        serviceType={serviceType}
        venueTypeOptions={venueTypeOptions}
        venueVibeOptions={venueVibeOptions}
        modeOfEngagementOptions={modeOfEngagementOptions}
        testimonials={testimonials}
        addTestimonial={addTestimonial}
        deleteTestimonial={deleteTestimonial}
        onImageUpload={onImageUpload}
        lastImageId={lastImageId}
        images={images}
      />
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingDescriptionPanel;
