import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { LINE_ITEM_UNITS } from '../../util/types';

import css from './BookingBreakdown.module.css';

const LineItemUnitsMaybe = ({ lineItems, unitType, calcValue }) => {
  if (unitType !== LINE_ITEM_UNITS) {
    return null;
  }

  const unitLineItems = lineItems?.filter(item => item.code === unitType && !item.reversal);

  if (!unitLineItems || unitLineItems.length === 0) {
    return null;
  }

  const quantity = unitLineItems
    .map(unitLineItem => unitLineItem.quantity ?? 0)
    .reduce((sum, acc) => sum + acc, 0);

  return (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdown.quantityUnit" />
      </span>
      <span className={css.itemValue}>
        <FormattedMessage id="BookingBreakdown.quantity" values={{ quantity }} />
      </span>
    </div>
  );
};

LineItemUnitsMaybe.propTypes = {};

export default LineItemUnitsMaybe;
