import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { useIntl } from 'react-intl';

import {
  Modal,
  Button,
  Form,
  FieldTextInput,
  FieldDateRangeInput,
  FieldCurrencyInput,
} from '../../components';
import { required } from '../../util/validators';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney, convertMoneyToNumber, getMoneyPercentage } from '../../util/currency';
import css from './ProposePriceModal.module.css';
import config from '../../config';

const { Money } = sdkTypes;

const getGrossPrice = (netAmount, vatPercentage) => {
  if (!netAmount || !vatPercentage || isNaN(vatPercentage)) {
    return {
      showCalculation: false,
      vatMoney: new Money(0, config.currency),
      grossMoney: new Money(0, config.currency),
    };
  }

  let vatMoney = getMoneyPercentage(netAmount, vatPercentage);
  let grossMoney = new Money(netAmount.amount + vatMoney.amount, config.currency);

  return {
    showCalculation: true,
    vatMoney: vatMoney,
    grossMoney: grossMoney,
  };
};

const ProposePriceModal = props => {
  const intl = useIntl();

  const {
    isOpen,
    onClose,
    onManageDisableScrolling,
    closeButtonMessage,
    customerDisplayName,
  } = props;

  return (
    <Modal
      id={'proposePrice'}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onClose}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      <h2 className={css.modalTitle}>Send quote to {customerDisplayName}</h2>

      <FinalForm
        {...props}
        render={fieldRenderProps => {
          const { handleSubmit, invalid, disabled, updateInProgress, values } = fieldRenderProps;

          const submitDisabled = invalid || disabled || updateInProgress;
          const { showCalculation, vatMoney, grossMoney } = getGrossPrice(
            values.netMoney,
            values.vatPercentage
          );

          values.vatMoney = vatMoney;
          values.grossMoney = grossMoney;

          const onSubmit = e => {
            values.price = convertMoneyToNumber(grossMoney).toFixed(2);

            handleSubmit(e);
          };

          return (
            <Form onSubmit={onSubmit}>
              <FieldTextInput
                type="textarea"
                name="workScope"
                id="workScope"
                label="Scope of Work"
                placeholder="Brief description of the service provided"
                validate={required('Required')}
              />
              <br />
              <FieldDateRangeInput
                customLabel={<label>Booking dates</label>}
                name="bookingDates"
                id="bookingDates"
                minDays={0}
                validate={required('Required')}
              />
              <br />
              <FieldTextInput
                type="number"
                name="noOfParticipants"
                id="noOfParticipants"
                min={1}
                label="Number of participants"
                placeholder="50"
                validate={required('Required')}
              />
              <br />
              <FieldCurrencyInput
                name="netMoney"
                id="ProposePriceModal.netMoney"
                label={`Net total price `}
                description="VAT not included. Price for all participants."
                placeholder={`Amount in ${config.currencyConfig.currency}`}
                currencyConfig={config.currencyConfig}
                validate={required('Required')}
              />

              <br />
              <FieldTextInput
                type="number"
                name="vatPercentage"
                id="vatPercentage"
                min={0}
                label="VAT percentage (%)"
                description="Customer VAT rate."
                placeholder="8"
                validate={required('Required')}
              />
              <br />
              <Button type="submit" className={css.acceptSaleButton} disabled={submitDisabled}>
                Send Quote {showCalculation ? `for ${formatMoney(intl, grossMoney)}` : ''}
              </Button>
              <h5 className={css.saleDescription}>
                {showCalculation
                  ? `The total amount is ${formatMoney(
                      intl,
                      grossMoney
                    )} which includes ${formatMoney(intl, vatMoney)} of VAT.`
                  : ''}
              </h5>
            </Form>
          );
        }}
      />
    </Modal>
  );
};

export default ProposePriceModal;
