import React, { useMemo } from 'react';
import { IoTrash } from 'react-icons/io5';

import NoImageDefault from './NoImageDefault.png';
import { formatMoney, valueIsInstanceOfMoney } from '../../util/currency';
import { createSlug } from '../../util/urlHelpers';

import { ListingLabel, NamedLink } from '../../components';

import css from './SelectedListingCard.module.css';

const SelectedListingCard = props => {
  const { listing, intl, onRemoveListingFromFavourites } = props;

  const { attributes, images, id } = listing || {};
  const { title, price, publicData } = attributes || {};
  const { listingType, serviceType } = publicData || {};

  const listingImage = useMemo(
    () => images?.[0]?.attributes?.variants['square-small']?.url || NoImageDefault,
    [images]
  );

  const slug = useMemo(() => (title ? createSlug(title) : ''), [title]);

  if (!listing || !valueIsInstanceOfMoney(price)) {
    return <div />;
  }

  return (
    <div className={css.card}>
      <div className={css.topRow}>
        <NamedLink name="ListingPage" params={{ id: id.uuid, slug, listingType }}>
          <div className={css.detailsWrapper}>
            <img src={listingImage} className={css.listingImage} />
            <div className={css.detailsColumn}>
              <span className={css.title}>{title}</span>

              <span className={css.price}>
                <strong>{formatMoney(intl, price, true)}</strong> per person, per day
              </span>
            </div>
          </div>
        </NamedLink>
      </div>
      <div className={css.bottomRow}>
        <ListingLabel listingType={listingType} serviceType={serviceType} />
        <button
          className={css.actionWrapper}
          onClick={() => onRemoveListingFromFavourites(id.uuid)}
        >
          <IoTrash size={24} className={css.deleteIcon} />
        </button>
      </div>
    </div>
  );
};

export default SelectedListingCard;
