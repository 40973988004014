import React from 'react';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
import { bool, func } from 'prop-types';
import css from './SectionHero.module.css';
import classNames from 'classnames';
import {
  Button,
  FieldSelect,
  LocationAutocompleteInput,
  BookingDateRangeFilter,
  FieldTextInput,
  LocationAutocompleteInputField,
} from '../../components';
import config from '../../config';
import { findOptionsForSelectFilter } from '../../util/search';
import searchIcon from '../../assets/icons/searchIcon.svg';

const identity = v => v;

function submitDisabled(values) {
  return false;
}

const specialismOptions = findOptionsForSelectFilter('goals', config.custom.filters);
const listingTypeOptions = findOptionsForSelectFilter('type', config.custom.filters);
const goalsTypeOptions = findOptionsForSelectFilter('needs', config.custom.filters);

const QueryForm = props => {
  const { mounted, dates, setDates } = props;
  const displayDates = dates && dates.dates ? dates.dates.split(',') : null;

  return (
    <FinalForm
      {...props}
      onSubmit={e => {
        props.onSubmit(e, specialismOptions);
      }}
      render={fieldRenderProps => {
        const { handleSubmit, onChange, values } = fieldRenderProps;
        const specialismPlaceholder = 'We want to';
        const listingTypePlaceHolder = 'Looking For';
        // const goalsTypeOptionsPlaceHolder = "We want to"

        return (
          <form
            id="queryForm"
            name="queryForm"
            className={classNames(css.queryForm, {
              [css.heroButtonFEDelay]: mounted,
            })}
            onSubmit={e => {
              e.preventDefault();
              if (dates || dates == '') {
                handleSubmit(e);
              }
            }}
          >
            <FormSpy onChange={onChange} />
            {/* <div onClick={() => setDates(null)} className={classNames(css.inputContainer, css.inputDates)}>
                            <BookingDateRangeFilter
                                label={
                                    displayDates ? `${displayDates[0]} to ${displayDates[1]}` : "Find dates"
                                }
                                className={css.queryFormDates}
                                queryParamNames={['dates']}
                                showAsPopup
                                onSubmit={setDates}
                                key={`QueryForm.Dates`}
                                id={'queryFormDates'}
                                idPrefix="queryFormDates"
                                contentPlacementOffset={0}

                            />
                        </div> */}
            <div className={css.inputContainer}>
              <LocationAutocompleteInputField
                className={css.queryFormAirport}
                placeholder="Location"
                autoFocus
                name="location"
                useDefaultPredictions={true}
                format={identity}
                valueFromForm={values.location}
              />
            </div>

            {/* <div className={css.inputContainer}>
                            <FieldSelect
                                className={css.selectFilter}
                                id="pub_listingType"
                                name="pub_listingType">
                                <option value="">{listingTypePlaceHolder}</option>
                                {listingTypeOptions.map(ty => {
                                    if (ty.parent !== 'Filter Label') {
                                        return <option key={ty.key} value={ty.key}>
                                            {ty.label}
                                        </option>
                                    }
                                }
                                )}
                            </FieldSelect>
                        </div> */}

            <div className={css.inputContainer}>
              <FieldSelect id="pub_specialism" name="pub_specialism" className={css.selectFilter}>
                <option value="">{specialismPlaceholder}</option>
                {specialismOptions.map(sp => {
                  if (sp.parent == 'Filter Label') {
                    return (
                      <option key={sp.key} value={sp.key}>
                        {sp.longLabel}
                      </option>
                    );
                  }
                })}
              </FieldSelect>
            </div>

            {/* <div className={css.inputContainer}>
                            <FieldSelect
                                className={css.selectFilter}
                                id="pub_specialism"
                                name="pub_specialism">
                                <option value="">{goalsTypeOptionsPlaceHolder}</option>
                                {specialismOptions.map(gt => {
                                    if (gt.parent !== 'Filter Label') {
                                        return <option key={gt.key} value={gt.key}>
                                            {gt.label}
                                        </option>
                                    }
                                }
                                )}
                            </FieldSelect>
                        </div> */}

            <Button
              disabled={submitDisabled(values)}
              type="submit"
              className={classNames(css.heroButton, {
                [css.heroButtonFEDelay]: mounted,
              })}
            >
              <img className={css.searchIcon} src={searchIcon} />
              Search
            </Button>
          </form>
        );
      }}
    />
  );
};

QueryForm.propTypes = {
  onChange: func.isRequired,
  onSubmit: func.isRequired,
  mounted: bool,
};

export default QueryForm;
