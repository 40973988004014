import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
export const defaultLocations = [
  {
    id: 'default-europe',
    predictionPlace: {
      address: 'Europe',
      bounds: new LatLngBounds(
        new LatLng(82.87014119, 47.78187087),
        new LatLng(29.81921456, -49.467575)
      ),
    },
  },
  {
    id: 'uk',
    predictionPlace: {
      address: 'United Kingdom',
      bounds: new LatLngBounds(new LatLng(60.9093508, 1.8623843), new LatLng(49.80777, -8.7497874)),
    },
  },
  {
    id: 'portugal',
    predictionPlace: {
      address: 'Portugal',
      bounds: new LatLngBounds(
        new LatLng(42.154276, -6.189158),
        new LatLng(29.9501178, -31.3687699)
      ),
    },
  },
  {
    id: 'spain',
    predictionPlace: {
      address: 'Spain',
      bounds: new LatLngBounds(
        new LatLng(43.8640692, 4.4264828),
        new LatLng(27.5545087, -18.260243)
      ),
    },
  },
  {
    id: 'france',
    predictionPlace: {
      address: 'France',
      bounds: new LatLngBounds(
        new LatLng(51.1504571, 9.6598888),
        new LatLng(41.3108239, -5.2425456)
      ),
    },
  },
  {
    id: 'italy',
    predictionPlace: {
      address: 'Italy',
      bounds: new LatLngBounds(new LatLng(47.092146, 18.6201843), new LatLng(35.4122073, 6.626556)),
    },
  },
  {
    id: 'north-central-america',
    predictionPlace: {
      address: 'North and Central America',
      bounds: new LatLngBounds(
        new LatLng(71.54266961, -67.32803643),
        new LatLng(12.43270904, -139.41516102)
      ),
    },
  },
  {
    id: 'default-world',
    predictionPlace: {
      address: 'Worldwide',
      bounds: new LatLngBounds(new LatLng(90, 180), new LatLng(-90, -180)),
    },
  },
];

export const DEFAULT_LOCATIONS = defaultLocations.map(location => ({
  title: location.predictionPlace.address,
  key: location.id,
}));

export const DEFUALT_WORLD_ID = 'default-world';

export default defaultLocations;
