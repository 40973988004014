import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink, Tooltip } from '../../components';
import { EditListingPricingForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import css from './EditListingPricingPanel.module.css';

const { Money } = sdkTypes;

const EditListingPricingPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { price, publicData } = currentListing.attributes;
  const { priceIncludes, priceExcludes, seasonality, serviceType, vatValue } = publicData || {};
  const isVE = serviceType == 'VE';

  const [rates, setRates] = useState([]);

  useEffect(() => {
    if (seasonality?.length > 0) {
      setRates(seasonality);
    }
  }, [seasonality]);

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPricingPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingPricingPanel.createListingTitle" />
  );
  const pricingText = <FormattedMessage id="EditListingPricingPanel.createListingText" />;

  const priceCurrencyValid = price instanceof Money ? price.currency === config.currency : true;
  const form = priceCurrencyValid ? (
    <EditListingPricingForm
      className={css.form}
      initialValues={{
        price,
        priceIncludes,
        priceExcludes,
        openPriceOptions: priceIncludes || priceExcludes || vatValue ? true : false,
        vatValue,
      }}
      onSubmit={values => {
        const { price, priceIncludes, priceExcludes, vatValue } = values;
        const data = {
          price,
          publicData: {
            priceIncludes,
            priceExcludes,
            seasonality: rates,
            vatValue,
          },
        };
        onSubmit(data);
      }}
      onChange={onChange}
      saveActionMsg={submitButtonText}
      disabled={disabled}
      ready={ready}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
      rates={rates}
      setRates={setRates}
      isVE={isVE}
    />
  ) : (
    <div className={css.priceCurrencyInvalid}>
      <FormattedMessage id="EditListingPricingPanel.listingPriceCurrencyInvalid" />
    </div>
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>
        <span style={{ marginRight: '20px' }}>{panelTitle}</span>
        <Tooltip messageId={'EditListingPricingPanel.titleTooltip'} />
      </h1>
      <p className={css.text}>
        {pricingText}
        <br></br>
        <FormattedMessage id="EditListingPricingPanel.createListingText2" />
      </p>
      {form}
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPricingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPricingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPricingPanel;
