import axios from 'axios';
import { encryptData } from '../../util/misc';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/SubUsersPage/SET_INITIAL_STATE';

export const SHOW_SUBUSERS_REQUEST = 'app/SubUsersPage/SHOW_SUBUSERS_REQUEST';
export const SHOW_SUBUSERS_SUCCESS = 'app/SubUsersPage/SHOW_SUBUSERS_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  accId: null,
  subUsers: [],
};

export default function SubUsersPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case SHOW_SUBUSERS_REQUEST:
      return { ...state, accId: payload.accId };
    case SHOW_SUBUSERS_SUCCESS:
      return { ...state, subUsers: payload.subUsers };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const loadSubUsersRequest = accId => ({
  type: SHOW_SUBUSERS_REQUEST,
  payload: { accId },
});

export const showUserSuccess = users => ({
  type: SHOW_SUBUSERS_SUCCESS,
  payload: users,
});

// ================ Thunks ================ //

export const loadSubUser = accId => (dispatch, getState, sdk) => {
  dispatch(loadSubUsersRequest(accId));
  axios
    .post(
      `/api/auth/querySubUsers`,
      {
        accId,
      },
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      }
    )
    .then(result => {
      dispatch(showUserSuccess(result.data));
      return result.data;
    })
    .catch(err => console.log(err));
};

export const addSubUser = params => (dispatch, getState, sdk) => {
  axios
    .post(
      `/api/auth/createSubUser`,
      {
        ...params,
      },
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      }
    )
    .then(result => {
      dispatch(loadSubUser(params.parentAccId));
      return result.data;
    })
    .catch(err => {
      dispatch(loadSubUser(params.parentAccId));
    });
};

export const editSubUser = params => (dispatch, getState, sdk) => {
  axios
    .post(
      `/api/auth/editSubUser`,
      {
        ...params,
      },
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      }
    )
    .then(result => {
      dispatch(loadSubUser(params.parentAccId));
      return result.data;
    })
    .catch(err => console.log(err));
};

export const deleteSubUser = params => (dispatch, getState, sdk) => {
  axios
    .post(
      `/api/auth/deleteSubUser`,
      {
        ...params,
      },
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      }
    )
    .then(result => {
      dispatch(loadSubUser(params.parentAccId));
      return result.data;
    })
    .catch(err => console.log(err));
};

export const bulkCreateSubUsers = params => (dispatch, getState, sdk) => {
  axios
    .post(
      `/api/auth/bulkCreateSubUsers`,
      {
        ...params,
      },
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      }
    )
    .then(result => {
      dispatch(loadSubUser(params.parentAccId));
      return result.data;
    })
    .catch(err => console.log(err));
};

export const adminLogin = params => async (dispatch, getState, sdk) => {
  try {
    const result = await axios.post(
      `/api/auth/adminLogin`,
      {
        ...params,
      },
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      }
    );
    localStorage.setItem('accId', encryptData(result.data.parentAccId));
    localStorage.setItem('permissionLevel', encryptData(result.data.permissionLevel));
    dispatch(loadSubUser(result.data.parentAccId));
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};
