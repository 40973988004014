import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { Button, IconClose, Modal } from '../../components';

import css from './Tutorials.module.css';

const Tutorials = props => {
  const { isOpen, setIsOpen, id, pages } = props;

  const [selectedPage, setPage] = useState(0);
  const multiPage = pages.length > 1;

  const updatePage = count => {
    const updatedPage = selectedPage + count;
    if (updatedPage >= 0 && updatedPage < pages.length) {
      setPage(updatedPage);
    }
  };

  return (
    <Modal
      id={id}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      onManageDisableScrolling={() => {}}
      closeButtonMessage="Close"
    >
      <div className={css.contentWrapper}>
        {multiPage && (
          <p onClick={() => updatePage(-1)}>
            <i className={classNames(css.arrow, css.left)}></i>
          </p>
        )}
        <div className={css.content}>
          <h2 className={css.header}>{pages[selectedPage].title}</h2>
          {pages[selectedPage].body}
          {pages[selectedPage].actionComponent}
        </div>
        {multiPage && (
          <p onClick={() => updatePage(1)}>
            <i className={classNames(css.arrow, css.right)}></i>
          </p>
        )}
      </div>

      <div className={css.navigationWrapper}>
        {multiPage &&
          pages.map((page, pageIndex) => (
            <span
              key={pageIndex}
              className={classNames(css.dot, pageIndex == selectedPage && css.selected)}
              onClick={() => setPage(pageIndex)}
            ></span>
          ))}
      </div>
    </Modal>
  );
};

export default Tutorials;
