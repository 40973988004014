import { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import {
  addListingToWorktripp,
  removeListingFromWorktripp,
} from '../../containers/WorktrippPage/Worktripp.duck';
import RecommendationPopup from '../../components/PopUpForms/RecommendationPopup/RecommendationPopup';

import css from './ListingAddRemoveButton.module.css';

// fake one for now
const wtListingLimitReached2 = selectWorktripp => {
  return false;
};

// TODO: isOwnListing is disconsidered for now
const ListingAddRemoveButton = ({ minimised, listing, isOwnListing }) => {
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const { selectedWorktripp } = useSelector(state => state.Worktripp);
  const [errorMessage, setErrorMessage] = useState(null);

  const listingInSelectedWorktripp = useMemo(
    () => selectedWorktripp?.listings?.find(ls => ls.listing_id === listing?.id?.uuid),
    [selectedWorktripp, listing?.id?.uuid]
  );

  const isWTLimitReached = wtListingLimitReached2(selectedWorktripp);

  const buttonMessage = useMemo(() => {
    if (isOwnListing) {
      return <FormattedMessage id="ListingPage.ownListingButton" />;
    }
    if (isWTLimitReached) {
      setErrorMessage('You can add up to 5 listings for each category in a Worktripp.');
      return <FormattedMessage id="ListingPage.max5Listings" />;
    }

    if (listingInSelectedWorktripp) {
      return <FormattedMessage id="ListingPage.removeFromWorktripp" />;
    } else {
      return <FormattedMessage id="ListingPage.addToWorktripp" />;
    }
  }, [listingInSelectedWorktripp, isOwnListing, isWTLimitReached]);

  const onClickButton = () => {
    // TODO: should this be login / signup form - make it a pop-up and consolidate function
    if (!selectedWorktripp?._id) {
      setShowPopup(!showPopup);
      return;
    }

    // TODO: think of tx_id: removing it and adding it again means new transaction
    const dispatchParams = { worktripp: selectedWorktripp, listingId: listing.id.uuid };
    if (listingInSelectedWorktripp) {
      dispatch(removeListingFromWorktripp(dispatchParams));
    } else {
      dispatch(addListingToWorktripp(dispatchParams));
    }
  };

  const onClickMinimised = event => {
    event.preventDefault();
    onClickButton();
  };

  const renderButton = () => {
    if (minimised) {
      return <HeartIcon onClick={onClickMinimised} isLiked={listingInSelectedWorktripp} />;
    }

    return (
      <>
        <Button
          disabled={isOwnListing || isWTLimitReached}
          style={{ backgroundColor: isWTLimitReached ? 'red' : null }}
          className={listingInSelectedWorktripp ? css.removeButton : css.addButton}
          onClick={onClickButton}
        >
          {buttonMessage}
        </Button>
        {errorMessage && <p>{errorMessage}</p>}
      </>
    );
  };

  return (
    <div
      onClick={event => {
        event.stopPropagation();
      }}
    >
      {renderButton()}
      <RecommendationPopup
        toggleDisplay={showPopup}
        setToggleDisplay={setShowPopup}
        listingId={listing?.id?.uuid}
      />
    </div>
  );
};

const HeartIcon = ({ onClick, isLiked }) => {
  const isDisabled = !onClick;
  return (
    <div className={css.heartImageWrapper}>
      <div className={css.heartCircle}>
        <div
          className={isLiked ? `${css.heartIcon} ${css.liked}` : css.heartIcon}
          onClick={onClick}
          disabled={true}
          style={{
            cursor: isDisabled ? 'default' : 'pointer',
          }}
        ></div>
      </div>
    </div>
  );
};

export default ListingAddRemoveButton;
