import { string } from 'prop-types';
import css from './QuestionButton.module.css';
import classNames from 'classnames';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'react-final-form';

const QuestionButton = props => {
  const { title, onSelectionChange, selection } = props;

  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = option => {
    setSelectedOption(option);
    if (onSelectionChange) {
      onSelectionChange(option);
    }
  };

  let option = selection || selectedOption;

  const YesNoRadio = ({ input }) => (
    <div className={classNames(css.setFlex, css.setPadding)}>
      <label
        className={`${css['radio-label']} ${option === 'yes' ? css.selected : ''}`}
        onClick={() => handleOptionChange('yes')}
      >
        <FormattedMessage id="FieldBoolean.yes" />
      </label>

      <label
        className={`${css['radio-label']} ${option === 'no' ? css.selected : ''}`}
        onClick={() => handleOptionChange('no')}
      >
        <FormattedMessage id="FieldBoolean.no" />
      </label>
    </div>
  );

  return (
    <div className={classNames(css.setFlex, css.setBackground)}>
      {title && <div className={css.setTitle}>{title}</div>}
      {title && <Field name={title} render={({ input }) => <YesNoRadio input={input} />} />}
    </div>
  );
};

QuestionButton.prototype = {
  title: string,
};

QuestionButton.defaultProps = {
  title: null,
};

export default QuestionButton;
