import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: December 1, 2022</p>
      <h1>Customer Terms</h1>
      <h2>Our Mission.</h2>
      <p>
        Our purpose is to help all teams enjoy sustainable working culture. We aim to be an
        indispensable part of the working environment, creating a natural home to support offsite and
        back-at-desk self-development fit for the new world of work.
      </p>

      <h2>Searching and Booking on WorkTripp.</h2>
      <h3>Searching</h3>
      <p>
        You can search for offsite suppliers by specialism (facilitators, coaches, inspiring
        speakers), or by travel destination, travel dates, and number of attendees. You can also use
        filters to refine your search results. Search results are based on their relevance to your search
        and other criteria. Relevance considers factors like price, availability, reviews, customer service
        and cancellation history, popularity, and previous WorkTripps.
      </p>

      <h3>Enquiring and Booking</h3>
      <p>
        In WorkTripp, you can enquire with multiple suppliers. These suppliers will offer you a proposal fee. If you accept this your chosen payment method will be pre-authorised via Stripe, for payment two weeks after your WorkTripp. WorkTripp charges you a 3% admin fee on this agreed proposal price. This is transparent at the point of securing your booking.
      </p>
      <h3>Venue Reservations</h3>
      <p>
        A venue reservation is a limited license to
        enter, occupy and use the venue. The Venue suppliers retains the right to re-enter the
        venue during your stay, to the extent:
        <ul>
          <li>(i) it is reasonably necessary,</li>
          <li>(ii) permitted by your contract with the Venue, and</li>
          <li>(iii) consistent with applicable law.</li>
        </ul>
        If you stay past checkout,
        the Venue has the right to make you leave in a manner consistent with applicable law, including
        by imposing reasonable overstay penalties. You may not exceed the maximum number of
        allowed Customers.Thank you.
      </p>

      <h3>Reservations for Experiences and Other Supplier Services</h3>
      <p>
        You
        are responsible for confirming that you, and anyone you invite, meet minimum age, proficiency,
        fitness or other requirements. You are responsible for informing the Venue of any medical or
        physical conditions, or other circumstances that may impact your ability to participate, attend or
        use the experience. Except where expressly authorized, you may not allow any person to
        join a experience unless they are included as an additional Customer during the booking
        process.
      </p>

      <h3>Reservations for Facilitators, Coaches and Inspiring Speakers</h3>
      <p>
        We are aware that
        bringing a facilitator, coach and inspiring speaker to your offsite requires a personal connection.
        As such, we facilitate chats between favourited facilitators, coaches and
        inspiring speakers. If you do not want to proceed with that facilitator, coach or speaker, you can
        go back to search. If you do want to proceed with that facilitator, coach or speaker you can secure your booking and your card will be pre-authorised. You can continue your dialogue to help shape and share your offsite programme. Please note: any direct connection with suppliers off platform may lead to removal from the WorkTripp community network.
      </p>
      <h2>Cancellations, Travel Issues, Refunds and Booking Modifications.</h2>
      <h3>Cancellations</h3>
      <p>
        Travel Issues, and Refunds. In general, if you cancel a reservation, the
        amount refunded to you is determined by the cancellation policy that applies to that
        reservation. But, in certain situations, other policies take precedence and determine what
        amount is refunded to you. If something outside your control requires you to cancel a
        reservation, you may be entitled to a partial or full refund under our Extenuating
        Circumstances Policy. If the Venue cancels, or you experience a Travel Issue (as defined in
        our Customer Refund Policy), you may be entitled to rebooking assistance or a partial or full
        refund under our Customer Refund Policy. You may appeal a decision by WorkTripp by
        contacting us at <a href='mailto:hello@worktripp.com' target='_blank'>hello@worktripp.com</a>
      </p>
      <h3>Booking Modifications</h3>
      <p>
        Suppliers and Customers are responsible for any booking
        modifications they agree to make via WorkTripp or direct WorkTripp customer service to make
        on their behalf ("Booking Modifications"), and agree to pay any additional amounts, fees or
        taxes associated with any Booking Modification.
      </p>
      <h2>Your Responsibilities</h2>
      <p>
        You are responsible for your own acts and omissions and are also responsible for the
        acts and omissions of anyone you invite to join or provide access to any
        Venue or Experience. For example, this means:
        <ul>
          <li>(i) you are responsible for
            leaving a Venue(and related personal property) in the condition it was in
            when you arrived,</li>
          <li>(ii) you are responsible for paying all Damage Claim amounts
            necessary to cover damage that you cause to a Venue, and </li>
          <li>(iii) you must act with integrity, treat others with respect and comply with applicable laws at all times.</li>
        </ul>
      </p>
      <h1>Supplier Terms</h1>
      <p>
        <a href='https://www.worktripp.com/SLA' target='_blank'>Read More</a>
      </p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
