import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import css from './OldTxWarning.module.css';

const OldTxWarning = props => {
  const { isProvider } = props;

  return (
    <div className={css.root}>
      <FormattedMessage
        id={
          isProvider
            ? 'TransactionPanel.oldTxBlockedProvider'
            : 'TransactionPanel.oldTxBlockedCustomer'
        }
      />
    </div>
  );
};

export default OldTxWarning;
