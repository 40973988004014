import { useSelector } from 'react-redux';
import { FormattedMessage } from '../../util/reactIntl';

import {
  NamedLink,
  WorktrippBasicInfo,
  ListingAddRemoveButton,
  ListingBrowseMoreButton,
  ListingEnquireButton,
} from '../../components';

import css from './WorktrippPanel.module.css';

// TODO: isOwnListing is disconsidered for now
const WorktrippPanel = ({ show, listing, allListings, isOwnListing, onToggleEnquiryModal }) => {
  const { selectedWorktripp } = useSelector(state => state.Worktripp);

  if (!show) {
    return <div />;
  }

  const hasVenue = allListings?.some(l => l.attributes.publicData.listingType == 'venue');

  return (
    <>
      <div className={css.bookingContainer}>
        {selectedWorktripp?._id && (
          <div className={css.bookingHeader}>
            <NamedLink
              className={css.link}
              name="ViewWorktripp"
              params={{ id: selectedWorktripp?._id }}
            >
              <h2 className={css.title}>{selectedWorktripp.name}</h2>
            </NamedLink>
            <WorktrippBasicInfo worktripp={selectedWorktripp} isViewOnly={false} />
          </div>
        )}

        <ListingEnquireButton listing={listing} onToggleEnquiryModal={onToggleEnquiryModal} />
        <ListingAddRemoveButton listing={listing} isOwnListing={isOwnListing} />

        <div className={css.moreActionsContainer}>
          <ListingBrowseMoreButton />

          {!hasVenue && (
            <div className={css.noVenueInWorktripp}>
              <FormattedMessage id="ListingPage.noVenueInWorktripp" />
              <a href={`/s/venue/?pub_listingType=venue`} className={css.exploreText}>
                Explore venues
              </a>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default WorktrippPanel;
