import config from '../config';
import { findOptionsForSelectFilter } from './search';
import CryptoJS from 'crypto-js';
import { differenceInDays, subDays, format } from 'date-fns';

import { transitions } from './transaction';
import { hasAdditonalPaymentPendingStatus } from './transactionHelpers';

export const MIN_DAYS_FOR_DEPOSIT = 28;
export const MIN_DAYS_FOR_BALANCE = MIN_DAYS_FOR_DEPOSIT + 1;
export const DEPOSIT_PERCENTAGE = 0.5;
export const BALANCE_PERCENTAGE = 1 - DEPOSIT_PERCENTAGE;

export const decryptData = text => {
  if (!text) return '';
  const bytes = CryptoJS.AES.decrypt(text, process.env.REACT_APP_ENCRYPTION_KEY);
  try {
    const data = bytes ? JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) : null;
    return data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const encryptData = text => {
  const data = CryptoJS.AES.encrypt(
    JSON.stringify(text),
    process.env.REACT_APP_ENCRYPTION_KEY
  ).toString();
  return data;
};

export const getListingImagesOnly = (images, testemonials = []) => {
  return images;
  // if (testemonials.length) {
  //     const tsImagesIds = testemonials.map(ts => {
  //         return ts.imageId;
  //     });
  //     const finalImages = images.map(img => {
  //         if (!tsImagesIds.includes(img.id.uuid)) {
  //             return img
  //         }
  //     });
  //     return finalImages.filter(img => img)
  // } else {
  //     return images
  // };
};

export const unparseListingType = key => {
  const listingTypeOptions = findOptionsForSelectFilter('type', config.custom.filters);
  return listingTypeOptions.find(e => e.key == key)?.label || 'N/A';
};

const COLORS = {
  WORKTRIPP_GREEN: '#365e57',
  BLACK: '#000000',
  DANGER_RED: '#D10000',
  MUSTARD: '#FFAA00',
};

// TODO: check balance due date
//'Pay balance' depends on balance timing
export const unparseListingTxStatus = listingTx => {
  if (isOldTxFlow(listingTx?.txVersion)) {
    return {
      listingLabel: 'INVALID',
      labelColor: COLORS.DANGER_RED,
      showPayButton: false,
    };
  }

  const isAdditionalPaymentDue = hasAdditonalPaymentPendingStatus(
    listingTx?.metadata?.additionalTransactions
  );

  const key = listingTx?.status;

  let { bookingStart } = listingTx?.metadata?.activeProposal || {};

  const isBalanceDue = isBalancePaymentDue(bookingStart);

  const balanceDueText =
    !bookingStart || isBalanceDue
      ? 'BALANCE PAYMENT REQUIRED'
      : `BALANCE DUE - ${format(getBalanceDueDate(bookingStart), "do 'of' MMM")}`;

  const balanceDueStatus = isAdditionalPaymentDue ? balanceDueText : 'BOOKING SECURED';
  const balanceDueColor = isAdditionalPaymentDue ? COLORS.MUSTARD : COLORS.WORKTRIPP_GREEN;

  switch (key) {
    case transitions.INQUIRE:
      return {
        listingLabel: 'ENQUIRY SENT',
        labelColor: COLORS.MUSTARD,
        showPayButton: false,
      };
    case transitions.EXPIRE_INQUIRY:
    case transitions.EXPIRE_QUOTE:
      return {
        listingLabel: 'EXPIRED',
        labelColor: COLORS.DANGER_RED,
        showPayButton: false,
      };
    case transitions.CUSTOMER_DECLINE_INQUIRY:
    case transitions.PROVIDER_DECLINE_INQUIRY:
      return {
        listingLabel: 'DECLINED',
        labelColor: COLORS.DANGER_RED,
        showPayButton: false,
      };

    case transitions.SEND_QUOTE:
    case transitions.UPDATE_QUOTE:
      return {
        listingLabel: 'PROPOSAL PROVIDED',
        labelColor: COLORS.MUSTARD,
        showPayButton: true,
        payButtonText: 'Accept & Pay',
      };

    case transitions.CUSTOMER_DECLINE_QUOTE:
    case transitions.PROVIDER_DECLINE_QUOTE:
      return {
        listingLabel: 'DECLINED',
        labelColor: COLORS.DANGER_RED,
        showPayButton: false,
      };

    case transitions.CREATE_BOOKING:
      return {
        listingLabel: 'PENDING PAYMENT',
        labelColor: COLORS.MUSTARD,
        showPayButton: true,
        payButtonText: 'Accept & Pay',
      };

    case transitions.PENDING_DEPOSIT_VAT:
    case transitions.UPDATE_DEPOSIT:
      return {
        listingLabel: 'DEPOSIT PAYMENT REQUIRED',
        labelColor: COLORS.MUSTARD,
        showPayButton: true,
        payButtonText: 'Complete deposit payment',
      };

    case transitions.CONFIRM_DEPOSIT:
    case transitions.COMPLETE_DEPOSIT:
    case transitions.OPERATOR_COMPLETE_DEPOSIT:
      return {
        listingLabel: balanceDueStatus,
        labelColor: balanceDueColor,
        showPayButton: isAdditionalPaymentDue,
        payButtonText: isAdditionalPaymentDue ? 'Pay balance' : 'Booking secured',
      };

    case transitions.PENDING_PAYMENT_VAT:
    case transitions.UPDATE_PAYMENT:
      return {
        listingLabel: 'PAYMENT REQUIRED',
        labelColor: COLORS.MUSTARD,
        showPayButton: true,
        payButtonText: 'Complete payment',
      };

    case transitions.CONFIRM_PAYMENT:
      return {
        listingLabel: 'BOOKING SECURED',
        labelColor: COLORS.WORKTRIPP_GREEN,
        showPayButton: false,
      };

    case transitions.EXPIRE_DEPOSIT:
    case transitions.EXPIRE_PAYMENT:
    case transitions.CANCEL_DEPOSIT:
    case transitions.CANCEL:
      return {
        listingLabel: 'CANCELLED',
        labelColor: COLORS.DANGER_RED,
        showPayButton: false,
      };

    case transitions.COMPLETE:
    case transitions.OPERATOR_COMPLETE:
    case transitions.REVIEW_1_BY_PROVIDER:
    case transitions.REVIEW_2_BY_PROVIDER:
    case transitions.REVIEW_1_BY_CUSTOMER:
    case transitions.REVIEW_2_BY_CUSTOMER:
    case transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD:
    case transitions.EXPIRE_PROVIDER_REVIEW_PERIOD:
    case transitions.EXPIRE_REVIEW_PERIOD:
      return {
        listingLabel: 'COMPLETED',
        labelColor: COLORS.WORKTRIPP_GREEN,
        showPayButton: false,
      };

    default:
      return {
        listingLabel: 'NOT CONTACTED',
        labelColor: COLORS.BLACK,
        showPayButton: false,
      };
  }
};

export const attachAuthorAndImagesToListings = listings => {
  if (listings?.data?.length) {
    const data = [];
    listings.data.map(l => {
      l.author = listings.included.find(
        item => item.type == 'user' && item.id.uuid == l.relationships.author.data.id.uuid
      );
      l.images = l.relationships.images.data.map(imageData => {
        return listings.included.find(
          item => item.type === 'image' && item.id.uuid === imageData.id.uuid
        );
      });
      data.push(l);
    });
    return data;
  }
  return [];
};

export const showDateString = date => {
  const arr = date?.split('/') || [];
  [arr[0], arr[1]] = [arr[1], arr[0]];
  return arr.join('/');
};

export const unifiedDateString = date => {
  return new Date(date).toLocaleDateString('en-UK');
};

export const getSelectedWorktripp = worktripps => {
  const selectedWorktripp = worktripps.find(w => w.selected);
  return selectedWorktripp ? selectedWorktripp : '';
};

export const wtListingLimitReached = (allListings, listing) => {
  const cfsLimit = 5;
  const vLimit = 5;
  const eLimit = 5;
  const listingType = listing?.attributes.publicData.listingType;
  const serviceType = listing?.attributes.publicData.serviceType;

  const cfsListings = allListings.filter(l => l.attributes.publicData.serviceType == 'CFS');
  const veListings = allListings.filter(l => l.attributes.publicData.serviceType == 'VE');
  const venueListings = veListings.filter(l => l.attributes.publicData.listingType == 'venue');
  const erListings = veListings.filter(l => l.attributes.publicData.listingType !== 'venue');

  if (listingType == 'venue' && venueListings?.length >= vLimit) {
    return true;
  }
  if (listingType == 'experiences' && erListings?.length >= eLimit) {
    return true;
  }
  if (serviceType == 'CFS' && cfsListings?.length >= cfsLimit) {
    return true;
  }
  return false;
};

export const hasCorrectPermission = authLevel => {
  const pLevel = typeof window !== 'undefined' && window.localStorage.getItem('permissionLevel');
  if (!authLevel) {
    return true;
  } else if (authLevel == 'user') {
    return true;
  } else {
    if (!pLevel) {
      return false;
    } else {
      const currentUserPL = decryptData(pLevel);
      if (authLevel == 'admin') {
        return currentUserPL == 'Admin' || currentUserPL == 'SuperAdmin';
      } else {
        return currentUserPL == 'SuperAdmin';
      }
    }
  }
};

const VAT_BOOKING_PROCESS_START = 1718629200000; // 17/06/2024 14:00 BST
export const isOldTxFlowByDate = lastTransition => {
  return lastTransition && new Date(lastTransition).getTime() < VAT_BOOKING_PROCESS_START;
};

export const isOldTxFlow = (version, processName) => {
  if (processName && processName === 'worktripp') {
    return true;
  }

  if (version) {
    const supportedTxVersion = process.env.REACT_APP_MIN_TX_VERSION_SUPPORTED;
    return Number(version) < supportedTxVersion;
  } else {
    return false;
  }
};

export const calculateTotalBudget = (rate, participantCount, noOfDays = 1) => {
  const totalBudget = Number(rate) * Number(noOfDays) * Number(participantCount);

  if (isNaN(totalBudget)) {
    return 'N/A';
  } else {
    return totalBudget;
  }
};

export const calculateNoOfDaysBetweenDates = (startDate, endDate) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = new Date(startDate);
  const secondDate = new Date(endDate);
  const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
  return diffDays;
};

export const calculateExpectedPercentageOfWorktrippBudget = (estimatedCost, totalBudget) => {
  const percentageOfBudget = (estimatedCost / totalBudget) * 100;
  return typeof percentageOfBudget === 'number' && !isNaN(percentageOfBudget)
    ? `${percentageOfBudget.toFixed(0)}%`
    : 'N/A';
};

export const isEligibleForDeposit = date => {
  const days = differenceInDays(new Date(date), new Date());
  return days > MIN_DAYS_FOR_DEPOSIT;
};

export const isBalancePaymentDue = date => {
  if (!date) {
    return false;
  }
  const days = differenceInDays(new Date(date), new Date());
  return days <= MIN_DAYS_FOR_BALANCE;
};

export const getBalanceDueDate = date => {
  return subDays(new Date(date), MIN_DAYS_FOR_BALANCE);
};
