import React, { Component } from 'react';
import { array, arrayOf, func, node, number, object, string } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { FieldTextInput } from '../../components';

import { FilterPopup, FilterPlain } from '../../components';
import css from './MultiFieldFilter.module.css';

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames : queryParamNames;
};

const format = (values, queryParamName, searchMode) => {
  const { pub_maxEventCapacity, pub_maxSleepCapacity, pub_noOfUniqueBedrooms } = values;

  var finalFilterObject = {};
  if (pub_maxEventCapacity) {
    finalFilterObject.pub_maxEventCapacity = `${pub_maxEventCapacity},1000`;
  } else {
    finalFilterObject.pub_maxEventCapacity = null;
  }
  if (pub_maxSleepCapacity) {
    finalFilterObject.pub_maxSleepCapacity = `${pub_maxSleepCapacity},1000`;
  } else {
    finalFilterObject.pub_maxSleepCapacity = null;
  }
  if (pub_noOfUniqueBedrooms) {
    finalFilterObject.pub_noOfUniqueBedrooms = `${pub_noOfUniqueBedrooms},1000`;
  } else {
    finalFilterObject.pub_noOfUniqueBedrooms = null;
  }

  return finalFilterObject;
};

class MultiFieldFilter extends Component {
  constructor(props) {
    super(props);

    this.filter = null;
    this.filterContent = null;

    this.positionStyleForContent = this.positionStyleForContent.bind(this);
  }

  positionStyleForContent() {
    if (this.filter && this.filterContent) {
      const distanceToRight = window.innerWidth - this.filter.getBoundingClientRect().right;
      const labelWidth = this.filter.offsetWidth;
      const contentWidth = this.filterContent.offsetWidth;
      const contentWidthBiggerThanLabel = contentWidth - labelWidth;
      const renderToRight = distanceToRight > contentWidthBiggerThanLabel;
      const contentPlacementOffset = this.props.contentPlacementOffset;

      const offset = renderToRight
        ? { left: contentPlacementOffset }
        : { right: contentPlacementOffset };

      const minWidth = contentWidth < labelWidth ? { minWidth: labelWidth } : null;

      return { ...offset, ...minWidth };
    }
    return {};
  }

  render() {
    const {
      rootClassName,
      className,
      id,
      name,
      label,
      options,
      initialValues,
      contentPlacementOffset,
      onSubmit,
      queryParamNames,
      searchMode,
      intl,
      showAsPopup,
      ...rest
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const queryParamName = getQueryParamName(queryParamNames);
    const hasInitialValues =
      !!initialValues &&
      (initialValues.pub_maxEventCapacity ||
        initialValues.pub_maxSleepCapacity ||
        initialValues.pub_noOfUniqueBedrooms);
    const selectedOptions = initialValues;
    if (initialValues.pub_maxEventCapacity) {
      selectedOptions.pub_maxEventCapacity = initialValues.pub_maxEventCapacity.split(',')[0];
    }
    if (initialValues.pub_maxSleepCapacity) {
      selectedOptions.pub_maxSleepCapacity = initialValues.pub_maxSleepCapacity.split(',')[0];
    }
    if (initialValues.pub_noOfUniqueBedrooms) {
      selectedOptions.pub_noOfUniqueBedrooms = initialValues.pub_noOfUniqueBedrooms.split(',')[0];
    }

    const labelForPopup = hasInitialValues
      ? intl.formatMessage(
          { id: 'SelectMultipleFilter.labelSelected' },
          { labelText: label, count: Object.values(initialValues)?.filter(e => e)?.length }
        )
      : label;

    const labelForPlain = hasInitialValues
      ? intl.formatMessage(
          { id: 'SelectMultipleFilterPlainForm.labelSelected' },
          { labelText: label, count: Object.values(initialValues)?.filter(e => e)?.length }
        )
      : label;

    const contentStyle = this.positionStyleForContent();

    const handleSubmit = values => {
      const correctValues = values ? values : [];
      onSubmit(format(correctValues, queryParamName, searchMode));
    };

    return showAsPopup ? (
      <FilterPopup
        className={classes}
        rootClassName={rootClassName}
        popupClassName={css.popupSize}
        name={name}
        label={labelForPopup}
        isSelected={hasInitialValues}
        id={`${id}.popup`}
        showAsPopup
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={handleSubmit}
        initialValues={selectedOptions}
        keepDirtyOnReinitialize
        {...rest}
      >
        {options.map(option => {
          return (
            <FieldTextInput
              key={`${id}-${option.key}`}
              id={`${id}-${option.key}`}
              name={option.key}
              className={css.fieldGroup}
              type="number"
              label={option.label}
              placeholder={'123'}
            />
          );
        })}
      </FilterPopup>
    ) : (
      <FilterPlain
        className={className}
        rootClassName={rootClassName}
        label={labelForPlain}
        isSelected={hasInitialValues}
        id={`${id}.plain`}
        liveEdit
        contentPlacementOffset={contentStyle}
        onSubmit={handleSubmit}
        initialValues={selectedOptions}
        {...rest}
      >
        {options.map(option => {
          return (
            <FieldTextInput
              key={`${id}-${option.key}`}
              id={`${id}-${option.key}`}
              name={option.key}
              className={css.fieldGroup}
              type="number"
              label={option.label}
              placeholder={'123'}
            />
          );
        })}
      </FilterPlain>
    );
  }
}

MultiFieldFilter.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
};

MultiFieldFilter.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  name: string.isRequired,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSubmit: func.isRequired,
  options: array.isRequired,
  initialValues: object,
  contentPlacementOffset: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(MultiFieldFilter);
