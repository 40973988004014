import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingFeaturesForm } from '../../forms';
import { ListingLink } from '../../components';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';
import css from './EditListingFeaturesPanel.module.css';

const EditListingFeaturesPanel = props => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;
  const [toDo, setToDo] = useState([]);
  const [savedValues, setSavedValues] = useState();

  const {
    specialism,
    serviceType,
    listingType,
    facilities = [],
    worktrippLoves,
    bestFor,
    retreatProgramme,
    toDo: savedToDo,
    listingSector,
  } = publicData || {};

  const isVE = serviceType == 'VE';
  const isVenue = listingType !== 'experiences';
  const isRetreat = listingType == 'retreat';

  const checkFacilities = facilities.filter(f => f.value == true);
  const nonCheckFacilities = facilities.filter(f => f.value !== true);
  let facilitiesToInputs = {};
  nonCheckFacilities.map(nf => {
    facilitiesToInputs[nf.key] = nf.value;
  });
  const initialValues = {
    specialism,
    worktrippLoves,
    bestFor,
    retreatProgramme,
    listingSector,
    facilities: checkFacilities.map(cf => cf.key),
    ...facilitiesToInputs,
  };

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id={`EditListingFeaturesPanel.title${isVE ? 'VE' : ''}`}
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id={`EditListingFeaturesPanel.createListingTitle${isVE ? 'VE' : ''}`} />
  );
  const specialismText = (
    <FormattedMessage id={`EditListingFeaturesPanel.createListingText${isVE ? 'VE' : ''}`} />
  );

  const matchFacilitiesWithAllInputs = (facilities, inputs) => {
    let holder = [];
    facilities.map(f =>
      holder.push({
        key: f,
        value: true,
      })
    );
    Object.keys(inputs).map(i =>
      holder.push({
        key: i,
        value: inputs[i],
      })
    );
    return holder;
  };

  const listingSectorOptions = findOptionsForSelectFilter('listingSector', config.custom.filters);

  const setToDoWithoutLosingValues = (toDoValue, otherValues) => {
    setToDo(toDoValue);
    setSavedValues(otherValues);
  };

  useEffect(() => {
    if (savedToDo?.length > 0) {
      setToDo(savedToDo);
    }
  }, [savedToDo]);

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <p className={css.specialismText}>{specialismText}</p>
      <p className={css.specialismRedStarText}></p>
      <EditListingFeaturesForm
        className={css.form}
        isVE={isVE}
        isVenue={isVenue}
        isRetreat={isRetreat}
        initialValues={savedValues ? savedValues : initialValues}
        onSubmit={values => {
          const {
            specialism = [],
            facilities = [],
            worktrippLoves,
            bestFor,
            retreatProgramme,
            listingSector,
            ...rest
          } = values;

          const mutualFields = { worktrippLoves, bestFor, toDo };
          const updatedValues = {
            publicData: isVE
              ? {
                  facilities: matchFacilitiesWithAllInputs(facilities, rest),
                  alsoIncludes: [],
                  retreatProgramme,
                  ...mutualFields,
                }
              : { specialism, listingSector, ...mutualFields },
          };
          onSubmit(updatedValues);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        toDo={toDo}
        setToDo={setToDoWithoutLosingValues}
        listingSectorOptions={listingSectorOptions}
      />
    </div>
  );
};

EditListingFeaturesPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingFeaturesPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingFeaturesPanel;
