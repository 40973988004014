import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { Modal, Button, Form, FieldTextInput, FieldDateRangeInput, NamedLink } from '..';
import { required } from '../../util/validators';
import css from './PhoneNumberModal.module.css';
import { FormattedMessage } from 'react-intl';

const PhoneNumberModal = props => {
  const { isOpen, onClose, onManageDisableScrolling, closeButtonMessage } = props;

  return (
    <Modal
      id={'phoneNumberModal'}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onClose}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      <p className={css.modalTitle}>
        <FormattedMessage id="PhoneNumberModal.title" />
      </p>
      <p>
        <FormattedMessage id="PhoneNumberModal.subText" />
      </p>
      <br />
      <NamedLink name="ContactDetailsPage">
        <Button>
          <FormattedMessage id="PhoneNumberModal.buttonText" />
        </Button>
      </NamedLink>
    </Modal>
  );
};

export default PhoneNumberModal;
