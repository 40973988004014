import React from 'react';
import FieldTextInput from '../../FieldTextInput/FieldTextInput';
import { FormattedMessage } from 'react-intl';

const SignUpForm = props => {
  const { validators, css, header, subTitle } = props;

  const InputRequired = <FormattedMessage id="PayoutDetailsForm.firstNameRequired" />;

  return (
    <div>
      <div style={{ lineHeight: 'normal', margin: '20px 0px' }}>{subTitle}</div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <FieldTextInput
          className={css.field}
          type="text"
          name="firstName"
          id="firstName"
          label="First Name *"
          placeholder="E.g Stephanie"
          validate={validators.required(InputRequired)}
        />
        <FieldTextInput
          className={css.field}
          type="text"
          name="lastName"
          id="lastName"
          label="Last Name *"
          placeholder="E.g Shirley"
          validate={validators.required(InputRequired)}
        />
      </div>
      <FieldTextInput
        className={css.field}
        type="email"
        name="email"
        id="email"
        label="Email *"
        placeholder="E.g. great@teamwork.com"
        validate={validators.required(InputRequired)}
      />
      <FieldTextInput
        className={css.field}
        type="password"
        name="password"
        id="password"
        label="Password *"
        placeholder="Create password"
        validate={validators.required(InputRequired)}
      />
      <FieldTextInput
        className={css.field}
        type="text"
        name="organisationName"
        id="organisationName"
        label="Company Name *"
        placeholder="E.g Great place to work"
        validate={validators.required(InputRequired)}
      />
      <FieldTextInput
        className={css.field}
        type="text"
        name="jobtitle"
        id="jobtitle"
        label="Job title *"
        placeholder="E.g. Chief Connection Officer"
        validate={validators.required(InputRequired)}
      />
    </div>
  );
};

export default SignUpForm;
