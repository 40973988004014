import { func, string } from 'prop-types';
import React, { useState } from 'react';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import css from './ExpansionPanel.module.css';
import { Field } from 'react-final-form';

const ExpansionPanel = props => {
  const { title, children, hasExpansion, value, values, onChange } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const isChecked = values?.includes(value);

  const togglePanel = () => {
    if (hasExpansion) {
      setIsExpanded(!isExpanded);
    }
  };

  const handleCheckboxChange = () => {
    if (onChange) {
      if (isChecked) {
        onChange(values.filter(e => e !== value));
      } else {
        onChange([...values, value]);
      }
    }
  };

  return (
    <div className={css.expansion}>
      <div className={css.expansionTitle}>
        {title && (
          <div className={css.isFlex}>
            <Field
              className={css.checkboxLabel}
              name={title}
              id={title}
              component="input"
              type="checkbox"
              onClick={e => handleCheckboxChange()} // Prevent the click event from bubbling up to the panel header
              render={<input type="checkbox" value={title} />}
            />
            <label className={css.checkBoxTitle} for={title}>
              {title}
            </label>
          </div>
        )}

        {hasExpansion && (
          <div onClick={togglePanel}>
            {isExpanded ? (
              <div className={css.isFlex}>
                <div className={css.iconText}>Find out more</div>
                <MdExpandLess size={30} />
              </div>
            ) : (
              <div className={css.isFlex}>
                <div className={css.iconText}>Hide Information </div>
                <MdExpandMore size={30} />
              </div>
            )}
          </div>
        )}
      </div>

      {isExpanded && children && <div className="expansion-panel-content">{children}</div>}
    </div>
  );
};

ExpansionPanel.defaultProps = {
  title: null,
  children: null,
};

ExpansionPanel.propTypes = {
  title: string,
  onCheckboxChange: func,
};

export default ExpansionPanel;
