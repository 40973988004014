import React, { useMemo } from 'react';
import { differenceInDays } from 'date-fns';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { IoCalendarClear, IoPersonAdd, IoPeople } from 'react-icons/io5';
import { FaLocationDot, FaMoneyBills, FaMoon, FaSun } from 'react-icons/fa6';

import { unifiedDateString, calculateTotalBudget } from '../../util/misc';
import { formatMoney } from '../../util/currency';
import config from '../../config';
import { DEFAULT_LOCATIONS } from '../../default-location-searches';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

import css from './WorktrippBasicInfo.module.css';

const WorktrippBasicInfoComponent = ({
  worktripp,
  isViewOnly,
  intl,
  iconSize = 24,
  skipEstimatedBudget,
  containerStyle = {},
}) => {
  if (!worktripp || isViewOnly) {
    return null;
  }

  const { startDate, endDate, noOfParticipants, location } = worktripp;

  // TODO: this is because a WT pre or post login/signup are slightly different
  const minBudget = useMemo(
    () =>
      worktripp.minBudget
        ? worktripp.minBudget
        : worktripp.budgetPerPerson
        ? worktripp.budgetPerPerson.amount / 100
        : 1,
    [worktripp.minBudget]
  );

  // TODO: this is because a WT pre or post login/signup are slightly different
  const computedStartDate = useMemo(() => new Date(startDate?.date ?? startDate), [startDate]);
  const computedEndDate = useMemo(() => new Date(endDate?.date ?? endDate), [endDate]);

  const noOfDays = useMemo(() => {
    const difference = differenceInDays(computedEndDate, computedStartDate);
    return difference + 1;
  }, [startDate, endDate]);

  const noOfNights = useMemo(() => {
    const difference = differenceInDays(computedEndDate, computedStartDate);
    return difference;
  }, [startDate, endDate]);

  const mappedLocation = useMemo(
    () => DEFAULT_LOCATIONS.find(aLocation => aLocation.key === location),
    [location]
  );

  // assumes the budget per person is for the whole WorkTripp
  const totalBudget = useMemo(() => minBudget * noOfParticipants, [minBudget, noOfParticipants]);

  return (
    <div className={css.topInfoWrapper} style={containerStyle}>
      <div className={css.infoWrapper}>
        <div className={css.informationRow}>
          <IoCalendarClear size={iconSize} className={css.icon} />
          <span>
            {unifiedDateString(computedStartDate)} - {unifiedDateString(computedEndDate)}
          </span>
        </div>
        <div className={css.informationRow}>
          {noOfDays && (
            <div>
              <FaSun size={iconSize} className={css.icon} />
              <span>
                {noOfDays} day{noOfDays === 1 ? '' : 's'}
              </span>
            </div>
          )}

          {noOfNights && (
            <div>
              <FaMoon size={iconSize} className={css.icon} />
              <span>
                {noOfNights} night{noOfNights === 1 ? '' : 's'}
              </span>
            </div>
          )}
        </div>

        <div className={css.informationRow}>
          <IoPeople size={iconSize} className={css.icon} />
          <span>{noOfParticipants} people</span>
        </div>
        {mappedLocation && (
          <div className={css.informationRow}>
            <FaLocationDot size={iconSize} className={css.icon} />
            <span>{mappedLocation.title}</span>
          </div>
        )}
      </div>
      <div className={css.informationRow}>
        <IoPersonAdd size={iconSize} className={css.icon} />
        <span>
          Budget per person: {formatMoney(intl, new Money(minBudget * 100, config.currency), true)}
        </span>
      </div>
      {!isNaN(totalBudget) && !skipEstimatedBudget && (
        <div className={css.informationRow}>
          <FaMoneyBills size={iconSize} className={css.icon} />
          <span className={css.totalBudgetText}>
            Estimated budget:{' '}
            {formatMoney(intl, new Money(totalBudget * 100, config.currency), true)}
          </span>
        </div>
      )}
    </div>
  );
};

const WorktrippBasicInfo = injectIntl(WorktrippBasicInfoComponent);

export default WorktrippBasicInfo;
