import React from 'react';
import AddressLinkMaybe from './AddressLinkMaybe';
import PhoneLogo from '../../assets/icons/phone.png';
import css from './TransactionPanel.module.css';

const DetailCardHeadingsMaybe = props => {
  const {
    showDetailCardHeadings,
    listingTitle,
    subTitle,
    location,
    geolocation,
    showAddress,
    isCustomer,
    currentProvider,
  } = props;

  const providerPhoneNumber = currentProvider?.attributes?.profile?.publicData?.phoneNumber;

  return isCustomer ? (
    <div className={css.detailCardHeadings}>
      <h2 className={css.detailCardTitle}>{listingTitle}</h2>
      <p className={css.detailCardSubtitle}>{subTitle}</p>

      <AddressLinkMaybe location={location} geolocation={geolocation} showAddress={true} />

      {isCustomer && providerPhoneNumber ? (
        <div className={css.phoneNumber}>
          <a href={`callto:${providerPhoneNumber}`}>
            <img src={PhoneLogo} /> {providerPhoneNumber}
          </a>
        </div>
      ) : null}
    </div>
  ) : null;
};

export default DetailCardHeadingsMaybe;
