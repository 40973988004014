import React, { useState } from 'react';
import { string, object, number } from 'prop-types';
import { compose } from 'redux';
import { injectIntl } from '../../util/reactIntl';
import dropDownArrow from '../../assets/icons/Dropdown.svg';
import shutUpArrow from '../../assets/icons/Dropup.svg';
import css from './ClientTsCard.module.css';

const ClientTsCard = props => {
  const { testimonial, images = [], currentFile, handleEdit, handleDelete } = props;
  const index = testimonial.id;
  const [open, setOpen] = useState(false);
  // const imageId = testimonial.imageId;
  // const theImage = images.find(img => img.id.uuid == imageId);
  // const imageURL = theImage && theImage.attributes && theImage.attributes.variants['square-small'] ? theImage.attributes.variants['square-small'].url : '';

  return (
    <div className={css.fullWrapper}>
      <div
        className={css.closedCard}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <strong>{testimonial.clientName ? testimonial.clientName : `Customer ${index}`}</strong>
        <div className={css.withCursor}>
          <img src={open ? shutUpArrow : dropDownArrow} />
        </div>
      </div>
      {open && (
        <div key={index} className={css.clTsWrapper}>
          <div>
            {/* <img
                    className={css.clientImage}
                    src={imageURL ? imageURL : currentFile}
                    alt="Client Img"
                /> */}
            <span>
              <strong>{testimonial.clientName}</strong>
            </span>
          </div>
          <p>{testimonial.testimonialText}</p>

          <div className={css.actionsContainer}>
            <span onClick={() => handleEdit(index)}>Edit</span>
            {index !== 1 && <span onClick={() => handleDelete(index)}>Delete</span>}
          </div>
        </div>
      )}
    </div>
  );
};

ClientTsCard.defaultProps = { className: null };

ClientTsCard.propTypes = {
  className: string,
  index: number,
  testimonial: object,
};

export default compose(injectIntl)(ClientTsCard);
