import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney, formatLineItemsPercentage } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_PROVIDER_COMMISSION_VAT, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const { Money } = sdkTypes;

const LineItemProviderCommissionVatMaybe = ({ lineItems, isProvider, calcValue }) => {
  const providerCommissionVatLineItems = lineItems.filter(
    item => item.code === LINE_ITEM_PROVIDER_COMMISSION_VAT && !item.reversal
  );

  if (
    !isProvider ||
    !providerCommissionVatLineItems ||
    providerCommissionVatLineItems.length === 0
  ) {
    return null;
  }

  const percentageString = formatLineItemsPercentage(providerCommissionVatLineItems);

  return (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage
          id="BookingBreakdown.providerCommissionVat"
          values={{ percentage: percentageString }}
        />
      </span>
      <span className={css.itemValue}>{calcValue(providerCommissionVatLineItems)}</span>
    </div>
  );
};

LineItemProviderCommissionVatMaybe.propTypes = {
  // lineItems: propTypes.lineItems.isRequired,
  // isProvider: bool.isRequired,
};

export default LineItemProviderCommissionVatMaybe;
