import { bool, string } from 'prop-types';
import css from './IconButton.module.css';
import classNames from 'classnames';

const IconButton = ({
  setImage,
  title,
  description,
  setBackground,
  withoutIcon,
  value,
  singleChoice,
  values,
  setValues,
}) => {
  const handleClick = () => {
    let tempValues = values;
    if (!values?.includes(value)) {
      tempValues = singleChoice ? [value] : [...values, value];
    } else {
      tempValues = tempValues.filter(e => e != value);
    }
    setValues(tempValues);
  };

  const isSelected = values?.includes(value);

  return (
    <div
      className={classNames(
        css.setFlex,
        values?.includes(value)
          ? withoutIcon
            ? css.setSelectedBackgroundWithoutIcon
            : css.setSelectedBackground
          : setBackground
      )}
      onClick={handleClick}
    >
      {setImage !== '' && setImage ? <img className={css.setImageMargin} src={setImage} /> : null}
      <div className={css.setContent}>
        {title ? (
          <div className={classNames(css.setTitle, isSelected ? css.setSelectedTitle : null)}>
            {title}
          </div>
        ) : null}
        {description ? <div className={classNames(css.setDescription)}>{description}</div> : null}
      </div>
    </div>
  );
};

IconButton.prototype = {
  setImage: string,
  title: string,
  description: string,
  withoutIcon: bool,
  value: string,
};

IconButton.defaultProps = {
  title: null,
  setImage: null,
  description: null,
  setBackground: null,
  withoutIcon: false,
  value: null,
};

export default IconButton;
