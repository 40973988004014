import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney, formatLineItemsPercentage } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_BASE_PRICE_VAT, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const { Money } = sdkTypes;

// Validate the assumption that the vat exists and the amount
// is zero or positive.
const isValidVat = vatLineItem => {
  return vatLineItem && vatLineItem.lineTotal instanceof Money && vatLineItem.lineTotal.amount >= 0;
};

const LineItemBasePriceVatMaybe = ({ lineItems, calcValue }) => {
  const basePriceVatLineItems = lineItems?.filter(
    item => item.code === LINE_ITEM_BASE_PRICE_VAT && !item.reversal
  );

  if (!basePriceVatLineItems) {
    return null;
  }

  // TODO: vald VAT line items
  const percentageString = formatLineItemsPercentage(basePriceVatLineItems);

  return (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage
          id="BookingBreakdown.basePriceVat"
          values={{ percentage: percentageString }}
        />
      </span>
      <span className={css.itemValue}>{calcValue(basePriceVatLineItems)}</span>
    </div>
  );
};

LineItemBasePriceVatMaybe.propTypes = {
  // lineItems: propTypes.lineItems.isRequired,
};

export default LineItemBasePriceVatMaybe;
