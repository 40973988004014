import React, { useState, useRef } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';
import { Modal, Button } from '../components';
import css from './ImageCropModal.module.css';
import './ReactCrop.css';

const minCropSize = 3;

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const getCorrectCropByPx = (width, height, aspect) => {
  const aspectRatioForOrignalPhoto = width / height;

  if (aspectRatioForOrignalPhoto < aspect) {
    const cropHeight = width / aspect;
    return {
      width,
      height: cropHeight,
      unit: 'px',
      x: 0,
      y: (height - cropHeight) / 2,
    };
  } else {
    const cropWidth = height * aspect;
    return {
      width: cropWidth,
      height,
      unit: 'px',
      x: (width - cropWidth) / 2,
      y: 0,
    };
  }
};

export default function ImageCropModal(props) {
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const scale = 1;
  const rotate = 0;
  const aspect = 16 / 9;
  const {
    crop,
    setCrop,
    imgSrc,
    onImageUpload,
    tempId,
    onManageDisableScrolling,
    imageModalState,
    closeImageModal,
    onSelectFile,
  } = props;

  function onImageLoad(e) {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, aspect));
    setCompletedCrop(getCorrectCropByPx(width, height, aspect));
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate);
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  return (
    <Modal
      id="ImageCropModal"
      isOpen={imageModalState}
      onClose={closeImageModal}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <div className={css.imageCropWrapper}>
        {imgSrc && (
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => {
              const { height, width } = percentCrop;
              if (height > minCropSize && width > minCropSize) {
                setCrop(percentCrop);
              }
            }}
            onComplete={c => setCompletedCrop(c)}
            aspect={aspect}
          >
            <img
              ref={imgRef}
              alt="Crop me"
              src={imgSrc}
              style={{
                transform: `scale(${scale}) rotate(${rotate}deg)`,
              }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        )}
        <div>
          {Boolean(completedCrop) && (
            <canvas
              ref={previewCanvasRef}
              style={{
                border: '1px solid black',
                objectFit: 'contain',
                width: completedCrop.width,
                height: completedCrop.height,
                display: 'none',
              }}
            />
          )}
        </div>
        <Button
          disabled={!completedCrop}
          onClick={e => {
            e.preventDefault();
            let canvasUrl = previewCanvasRef.current.toBlob(blob => {
              let file = new File([blob], 'fileName.jpg', {
                type: 'image/jpeg',
              });
              onImageUpload({ id: tempId, file });
            }, 'image/jpeg');
            onSelectFile();
            closeImageModal();
          }}
        >
          Crop
        </Button>
      </div>
    </Modal>
  );
}
