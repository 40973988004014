import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import videoIcon from '../../../assets/icons/videoIcon.png';
import css from '../ActivityFeed.module.css';
import { getTimeZoneOffset } from '../../../util/dates';
import CalendarIcon from '../../../assets/icons/calendarDark.png';
import { createEvent } from 'ics';
import { Buffer } from 'buffer';
import { TX_TRANSITION_ACTOR_CUSTOMER } from '../../../util/transaction';

const Meeting = props => {
  const {
    meeting,
    formattedDate,
    ownMeeting,
    onUpdateMeeting,
    currentTransaction,
    ownRole,
  } = props;
  const {
    _id,
    meeting_URL,
    minutes_needed,
    start_date,
    start_time,
    timeZone,
    status = 'pending',
  } = meeting;

  // ====================== To manage timezone shifts ======================
  let year = Number(start_date.substring(0, 4));
  let month = Number(start_date.substring(5, 7));
  let day = Number(start_date.substring(8, 10));

  const splitStartTime = start_time.split(':');
  const offset = toHoursAndMinutes(getTimeZoneOffset(timeZone)?.offsets[0]);

  let hour = Number(splitStartTime[0]) + offset[0];
  let minute = Number(splitStartTime[1]) + offset[1];
  if (minute < 0) {
    minute = 60 + minute;
    hour = hour - 1;
  }
  if (minute > 59) {
    minute = minute - 60;
    hour = hour + 1;
  }
  if (hour < 0) {
    hour = 24 + hour;
    day = day - 1;
  }
  if (hour > 23) {
    hour = hour - 24;
    day = day + 1;
  }
  if (day < 1) {
    month = month - 1;
    day = daysInMonth(month, year) + day;
  }
  if (day > daysInMonth(month, year)) {
    day = day - daysInMonth(month, year);
    month = month + 1;
  }
  if (month < 1) {
    month = 12 + month;
    year = year - 1;
  }
  if (month > 12) {
    month = month - 12;
    year = year + 1;
  }
  // Output ISO date
  const isoDate = `${year}-${leftPad(month)}-${leftPad(day)}T${leftPad(hour)}:${leftPad(
    minute
  )}:00.000Z`;

  // ====================== End of Section ======================

  const timeHelper = {
    '30': '30 minutes',
    '60': '1 hour',
    '120': '2 hours',
  };

  const meetingCancelled = status == 'cancelled' || status == 'rejected';
  let statusColor = null;
  if (meetingCancelled) {
    statusColor = css.rejectOption;
  } else if (status == 'accepted') {
    statusColor = css.accepted;
  }

  const updateMeeting = status => {
    onUpdateMeeting({
      _id,
      status,
    });
  };

  function toHoursAndMinutes(totalMinutes) {
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);
    return [hours, minutes];
  }

  function daysInMonth(month, year) {
    return Number(new Date(year, month, 0).getDate());
  }

  function leftPad(number) {
    return number < 10 ? `0${number}` : `${number}`;
  }

  const splitDateToArray = date => {
    return [
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
    ];
  };

  const createICS = async () => {
    const title = `${currentTransaction?.attributes?.protectedData?.worktripp?.name} - ${
      currentTransaction[ownRole == TX_TRANSITION_ACTOR_CUSTOMER ? 'provider' : 'customer']
        .attributes?.profile?.displayName
    }`;
    const event = {
      start: splitDateToArray(new Date(isoDate)),
      duration: { minutes: minutes_needed },
      title,
      location: meeting_URL,
      url: meeting_URL,
      status: 'CONFIRMED',
    };

    const calendar = await new Promise((resolve, reject) =>
      createEvent(event, (error, value) => {
        if (error) {
          console.error(error);
          reject(error);
        }

        resolve(value);
      })
    );
    const downloadable = 'data:text/calendar;base64,' + Buffer.from(calendar).toString('base64');
    const link = document.createElement('a');
    link.style.display = 'none';
    link.setAttribute('download', title);
    link.setAttribute('href', downloadable);
    link.click();
  };

  const meetingOptions = () => {
    if (status == 'accepted' || (ownMeeting && !meetingCancelled)) {
      return (
        <>
          <div className={css.addToCalendarWrapper} onClick={createICS}>
            <img src={CalendarIcon} /> <span>Add to Calendar</span>
          </div>
          <a className={css.rejectOption} onClick={() => updateMeeting('cancelled')}>
            <FormattedMessage id="TransactionPage.ActivityFeed.cancelMeeting" />
          </a>
          <a href={meeting_URL} target="_blank">
            <FormattedMessage id="TransactionPage.ActivityFeed.joinMeeting" />
          </a>
        </>
      );
    } else if (meetingCancelled) {
      return <></>;
    } else {
      return (
        <>
          <a className={css.rejectOption} onClick={() => updateMeeting('rejected')}>
            <FormattedMessage id="TransactionPage.ActivityFeed.declineMeeting" />
          </a>
          <a onClick={() => updateMeeting('accepted')}>
            <FormattedMessage id="TransactionPage.ActivityFeed.acceptMeeting" />
          </a>
        </>
      );
    }
  };

  return (
    <div className={ownMeeting ? css.ownMessage : css.message}>
      <div>
        <div
          className={css.messageContent}
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            minWidth: '300px',
          }}
        >
          <div style={{ fontWeight: '600' }}>
            <img style={{ marginRight: '8px' }} src={videoIcon} />
            <FormattedMessage id="TransactionPage.ActivityFeed.meetingScheduled" />
          </div>

          <div style={{ color: '#808080' }}>
            <div className={statusColor}>{status.charAt(0).toUpperCase() + status.slice(1)}</div>
            <div>{new Date(isoDate).toLocaleDateString('en-UK')}</div>
            <div>
              {new Date(isoDate).toLocaleTimeString('en-GB', {
                timeZoneName: 'short',
              })}{' '}
              for {timeHelper[minutes_needed]}
            </div>
          </div>

          <div style={{ textAlign: 'right', marginTop: '12px' }}>{meetingOptions()}</div>
        </div>
        <p className={css.messageDate}>{formattedDate}</p>
      </div>
    </div>
  );
};

export default Meeting;
